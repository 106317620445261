.bluewood-color {
  color: #344563 !important; }

.web-orange-color {
  color: #FFAB00 !important; }

.gigas-color {
  color: #3A3AA4 !important; }

.web-orange-bgcolor {
  background-color: #FFAB00 !important; }

.gigas-bgcolor {
  background-color: #3A3AA4 !important; }

.Dropdown-control {
  border: none !important;
  padding: 1px 38px 8px 9px !important; }
  .Dropdown-control:hover {
    cursor: pointer; }

.Dropdown-arrow {
  top: 10px !important; }

.header {
  background-color: white;
  border: none;
  box-shadow: 0 2px 7px 0 #A5ADBA;
  margin-bottom: 0px; }
  .header .container-fluid {
    padding: 12px 0px; }
  .header .navbar-header img {
    height: 35px;
    transition: all 0.15s ease; }
    .header .navbar-header img:hover {
      transform: scale(1.1); }
  .header .navbar-nav {
    height: 35px; }
    .header .navbar-nav li > a {
      padding: 8px 22px;
      font-size: 13px;
      color: #344563;
      font-weight: bold; }
      .header .navbar-nav li > a:hover {
        color: #406AF8; }
    .header .navbar-nav li.active a {
      color: #406AF8 !important;
      background-color: transparent !important; }
    .header .navbar-nav .Dropdown-control {
      min-width: 100px;
      padding: 1px 15px 8px 9px !important; }
    .header .navbar-nav .Dropdown-menu {
      min-width: 100%;
      width: max-content; }
    .header .navbar-nav .Dropdown-root.active .Dropdown-control {
      color: #406AF8; }
    .header .navbar-nav .Dropdown-arrow {
      right: 0px; }

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000; }

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem; }

label {
  color: #7A869A;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500; }

input[type="checkbox"] {
  border: 1px solid #CECFCF;
  width: 15px;
  height: 15px !important;
  margin: 0; }

input[type="file"] {
  box-shadow: none !important;
  margin-top: 10px; }
  input[type="file"]:hover {
    box-shadow: none !important; }

input, textarea, select {
  box-shadow: 0 0 4px 0 #A5ADBA !important;
  -webkit-box-shadow: 0 0 4px 0 #A5ADBA !important;
  -moz-box-shadow: 0 0 4px 0 #A5ADBA !important;
  border-radius: 4px !important;
  height: 35px !important;
  border: none !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none !important; }
  input:hover, textarea:hover, select:hover {
    box-shadow: 0 0 10px 0 #A5ADBA !important; }
  input:focus, textarea:focus, select:focus {
    box-shadow: 0 0 10px 0 #A5ADBA !important; }

textarea {
  height: auto !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; }

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

.form-control {
  z-index: 0; }

form .gender-input-wrapper .icon-wrapper {
  padding: 40px 60px;
  background-color: white;
  box-shadow: 0 0 4px 0 #A5ADBA !important;
  border-radius: 50px;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  margin-right: 30px;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center; }
  form .gender-input-wrapper .icon-wrapper:hover {
    box-shadow: 0 0 10px 0 #A5ADBA !important; }

form .gender-input-wrapper .icon-wrapper.error {
  border: 1px solid #FF5630; }

form .gender-input-wrapper .icon-wrapper.male {
  background-image: url("../images/gender-male.svg"); }
  form .gender-input-wrapper .icon-wrapper.male:hover {
    background-image: url("../images/gender-male-smiling.svg"); }

form .gender-input-wrapper .icon-wrapper.female {
  background-image: url("../images/gender-female.svg"); }
  form .gender-input-wrapper .icon-wrapper.female:hover {
    background-image: url("../images/gender-female-smiling.svg"); }

form .gender-input-wrapper .icon-wrapper.other {
  background-image: url("../images/other-gender.png"); }

form .gender-input-wrapper .icon-wrapper.male.checked {
  background-image: url("../images/gender-male-smiling.svg"); }

form .gender-input-wrapper .icon-wrapper.female.checked {
  background-image: url("../images/gender-female-smiling.svg"); }

form .gender-input-wrapper .icon-wrapper.other.checked {
  background-image: url("../images/other-gender.png"); }

form .gender-input-wrapper .icon-wrapper.checked {
  background-color: #406AF8; }

form .platform-input-wrapper .icon-wrapper {
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 70px;
  background-color: white;
  border-radius: 8px;
  position: relative;
  border: 1px solid white;
  margin-right: 20px;
  cursor: pointer; }
  form .platform-input-wrapper .icon-wrapper img.tick {
    position: absolute;
    width: 20px;
    top: -10px;
    right: 10px;
    display: none; }

form .platform-input-wrapper .icon-wrapper.checked {
  border: 1px solid #406AF8; }
  form .platform-input-wrapper .icon-wrapper.checked img.tick {
    display: block; }

form .platform-input-wrapper .icon-wrapper.web {
  background-image: url("../images/web-icon.png"); }

form .platform-input-wrapper .icon-wrapper.android {
  background-image: url("../images/android-icon.svg"); }

form .platform-input-wrapper .icon-wrapper.ios {
  background-image: url("../images/apple-icon.png"); }

form .input-group.form-error {
  border: 1px solid #FF5630 !important; }

form .input-group.form-error input:focus + .frapp-tooltip {
  display: block; }

form .form-group.form-error {
  position: relative; }
  form .form-group.form-error input {
    border: 1px solid #FF5630 !important; }
  form .form-group.form-error input:focus {
    border: none !important; }

form .form-group.form-error:focus-within .frapp-tooltip {
  display: block; }

form .form-group.form-error .frapp-tooltip {
  right: 0%;
  top: -30%; }
  form .form-group.form-error .frapp-tooltip::after {
    border-top-color: #FF5630;
    border-bottom: 0;
    border-width: 10px;
    margin-left: calc(50% - 30px); }
  form .form-group.form-error .frapp-tooltip::before {
    border-top-color: #FF5630;
    border-bottom: 0;
    border-width: 11px;
    margin-left: calc(50% - 30px); }

form .form-error .frapp-tooltip {
  font-size: 12px;
  background-color: #FF5630;
  color: white;
  border-radius: 4px;
  box-shadow: none;
  margin-left: 0;
  width: auto;
  left: auto;
  right: 0%;
  top: -140%; }
  form .form-error .frapp-tooltip::after, form .form-error .frapp-tooltip::before {
    top: 100%; }
  form .form-error .frapp-tooltip::after {
    border-top-color: #FF5630;
    border-bottom: 0;
    border-width: 10px;
    margin-left: 101px; }
  form .form-error .frapp-tooltip::before {
    border-top-color: #FF5630;
    border-bottom: 0;
    border-width: 11px;
    margin-left: 100px; }

.frapp-modal {
  display: flex !important;
  padding: 10px 0; }

.modal.fade .modal-dialog {
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  top: 300px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  margin: auto; }

h1, h2, h3 {
  text-align: center;
  margin-top: 0; }

.modal.fade.in .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  opacity: 1; }

.modal-content {
  box-shadow: none !important; }

.close-btn {
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: 0.25s ease; }
  .close-btn:hover {
    transform: rotate(180deg); }

@media (max-width: 768px) {
  .modal-backdrop {
    opacity: 0 !important; }
  .frapp-modal {
    padding: 0 !important; }
  .modal-dialog {
    margin: 0 !important;
    width: 100vw !important;
    min-height: 100% !important; }
  .modal-content {
    margin-top: 0;
    min-height: 100vh;
    width: 100%; }
  .modal-body {
    padding: 20px 15px !important; }
    .modal-body h2, .modal-body h3, .modal-body h4 {
      text-align: left !important;
      margin-top: 0; } }

button:focus {
  outline: 0 !important; }

button {
  background-color: transparent;
  border: none; }

button.submit {
  color: white;
  padding: 5px 15px;
  background-color: #6374d3;
  text-transform: uppercase;
  border: none;
  margin-top: 10px; }
  button.submit:hover, button.submit:focus {
    background-color: #3A3AA4;
    color: white; }
  button.submit.rounded {
    border-radius: 0.9rem;
    font-weight: 600 !important; }

button.submit.secondary {
  background-color: #36B37E; }
  button.submit.secondary:hover, button.submit.secondary:focus {
    background-color: #36B37E !important;
    color: white; }

button.submit.app {
  background-color: #001B30;
  text-transform: capitalize;
  padding: 3% 0;
  white-space: normal !important;
  word-wrap: break-word !important;
  border-radius: 6px;
  font-weight: 900 !important; }
  button.submit.app:hover, button.submit.app:focus {
    background-color: #001B30 !important;
    color: white; }

button.submit.app-blue {
  background-color: #023147;
  text-transform: capitalize;
  padding: 3% 0;
  white-space: normal !important;
  word-wrap: break-word !important;
  border-radius: 6px;
  font-weight: 900 !important; }
  button.submit.app-blue:hover, button.submit.app-blue:focus {
    background-color: #023147 !important;
    color: white; }

.final_disposition {
  background-color: var(--color-red);
  text-transform: capitalize;
  padding: 3% 0;
  white-space: normal !important;
  word-wrap: break-word !important;
  border-radius: 6px;
  font-weight: 900 !important;
  margin: 10px 0;
  color: #FFFFFF; }
  .final_disposition:hover, .final_disposition:focus {
    background-color: var(--color-red) !important;
    color: #FFFFFF; }

button:disabled {
  opacity: 0.3;
  cursor: not-allowed; }
  button:disabled:hover {
    background-color: #3A3AA4 !important; }

.search-input-wrapper {
  position: relative; }
  .search-input-wrapper input {
    z-index: 0; }
    .search-input-wrapper input:focus {
      box-shadow: 0 0 4px 0 #A5ADBA !important; }
  .search-input-wrapper input:focus ~ .custom-dropdown {
    display: block; }
  .search-input-wrapper .custom-dropdown {
    display: none;
    z-index: 2;
    max-height: 200px;
    width: 100%;
    box-shadow: 0 1px 4px 0 #CECFCF;
    border-radius: 4px;
    transition: height .5s linear;
    overflow: scroll;
    background-color: white;
    position: absolute; }
    .search-input-wrapper .custom-dropdown ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      padding-left: 20px; }
      .search-input-wrapper .custom-dropdown ul li {
        margin: 10px 0px;
        text-align: left;
        cursor: pointer;
        transition: color 0.2s linear; }
        .search-input-wrapper .custom-dropdown ul li:hover {
          color: #FFAB00; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  opacity: 1;
  animation: fadeOut ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

.project_title_header {
  display: flex;
  gap: 1%;
  width: 100%;
  margin-bottom: 1%;
  align-items: center; }
  .project_title_header img {
    height: 18px; }
    .project_title_header img:hover {
      cursor: pointer; }
  .project_title_header h3 {
    font-weight: 600 !important;
    font-size: 20px;
    color: #454647;
    margin: 0; }

.outcome-section-wrapper {
  background-color: #DFF5EB;
  border-radius: 4px;
  min-height: 350px;
  padding: 2%;
  max-height: 500px;
  overflow-y: auto; }
  .outcome-section-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .outcome-section-wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .outcome-section-wrapper::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .outcome-section-wrapper .default-outcomes-wrapper {
    border: 2px solid #11D17E;
    border-radius: 3px;
    background-color: #FFFFFF;
    min-height: 100px;
    padding: 1% 0;
    margin-bottom: 2%; }
    .outcome-section-wrapper .default-outcomes-wrapper .rows {
      display: flex;
      min-height: inherit; }
      .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list {
        padding: 1% 2%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5em;
        width: 100%;
        background: linear-gradient(#B4B4B4, #B4B4B4) center/2px 100% no-repeat; }
        .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .form-group {
          margin-bottom: 0; }
          .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .form-group label {
            padding-top: 0; }
        .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem; }
          .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config {
            font-size: 12px; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__control {
              min-height: 22px;
              cursor: pointer; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__value-container {
              height: 22px;
              min-width: 55px;
              max-width: 60px; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__indicator-separator {
              display: none; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__indicators {
              height: 22px; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__input {
              width: 0px;
              padding: 0; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__dropdown-indicator {
              padding: 0 2px; }
            .outcome-section-wrapper .default-outcomes-wrapper .rows .outcome-list .outcome-title-row .outcome-config .select__clear-indicator {
              width: 15px;
              padding: 0; }
    .outcome-section-wrapper .default-outcomes-wrapper input {
      box-shadow: none !important; }
    .outcome-section-wrapper .default-outcomes-wrapper .divider {
      width: 2px;
      background: #B4B4B4; }
  .outcome-section-wrapper .heading {
    color: #454647;
    font-weight: 600;
    font-size: 14px !important;
    text-align: left; }
  .outcome-section-wrapper .custom-outcome-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem; }
    .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome {
      min-height: 125px;
      border-radius: 3px;
      background-color: #FFFFFF;
      border: 2px solid #11D17E;
      padding: 2%; }
      .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .outcome-d {
        background-color: #efefef;
        min-height: 60px; }
      .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .outcome-t {
        margin: 0 0 3% 0;
        border-bottom: 1px solid #B4B4B4 !important; }
      .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .checkbox-row .checkbox-title {
        font-weight: 500;
        font-size: 13px;
        color: #888888;
        display: flex;
        gap: 2%;
        margin-bottom: 0;
        margin-top: 3%; }
        .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .checkbox-row .checkbox-title:last-child {
          margin-top: 1%;
          padding-top: 1%; }
      .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .delete-outcome {
        display: flex;
        justify-content: flex-end; }
        .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .delete-outcome img {
          width: 15px; }
        .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .delete-outcome:hover {
          cursor: pointer; }
      .outcome-section-wrapper .custom-outcome-wrapper .custom-outcome .suggestion-list {
        padding: 0 2px;
        font-size: 14px;
        text-align: left;
        border: 1px solid #efefef;
        cursor: pointer; }
  .outcome-section-wrapper .disable-outcome-user-notice {
    font-weight: 800 !important;
    font-size: 12px;
    color: #555555;
    background-color: var(--color-yellow);
    padding: 0.8rem;
    text-align: left;
    display: flex;
    gap: 0.5rem; }
    .outcome-section-wrapper .disable-outcome-user-notice img {
      width: 26px; }
  .outcome-section-wrapper .add-outcome {
    max-width: 120px;
    border: 2px solid #17BCBC;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #17BCBC;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2%;
    padding: 1%;
    word-wrap: break-word; }
    .outcome-section-wrapper .add-outcome:hover {
      cursor: pointer; }
  .outcome-section-wrapper .red-delete-img {
    width: 15px;
    filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%); }
    .outcome-section-wrapper .red-delete-img:hover {
      cursor: pointer; }

.lead-distribution-wrapper {
  margin-bottom: 3em; }
  .lead-distribution-wrapper.heading {
    color: #454647;
    font-weight: 600;
    font-size: 14px !important;
    text-align: left; }
  .lead-distribution-wrapper .cadence-attempts-wrapper {
    margin: 0.5em 0;
    border: 2px solid var(--color-grey-80);
    border-radius: 4px;
    min-height: 150px;
    padding: 2%; }
    .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section {
      max-height: 220px;
      overflow-y: auto;
      border: 2px solid var(--color-grey-80);
      border-radius: 4px;
      padding: 2%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 2.5rem; }
      .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5; }
      .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
      .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item {
        display: flex;
        width: inherit;
        font-size: 14px; }
        .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .attempt-count {
          width: 5%;
          color: #454647;
          font-weight: 600;
          margin-bottom: 0.5em; }
        .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .attempt-input {
          display: flex;
          align-items: baseline;
          gap: 0.8em; }
          .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .attempt-input input {
            width: 55%;
            padding: 0.2em;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important; }
          .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .attempt-input .read-only-value {
            background-color: var(--color-grey-80); }
          .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .attempt-input select {
            width: 45%;
            background-color: #ddddddba;
            -webkit-appearance: auto;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border: 1px solid #AAA;
            padding: 0.2em;
            border-radius: 0px; }
        .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .delete-attempt {
          width: 15px;
          filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%); }
          .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .delete-attempt.disabled {
            pointer-events: none;
            opacity: 0.5; }
          .lead-distribution-wrapper .cadence-attempts-wrapper .attempts_section .attempt-grid-item .delete-attempt:hover {
            cursor: pointer; }
    .lead-distribution-wrapper .cadence-attempts-wrapper .add-attempts {
      max-width: 120px;
      border: 2px solid #17BCBC;
      border-radius: 3px;
      background-color: #FFFFFF;
      color: #17BCBC;
      font-weight: 600;
      font-size: 14px;
      margin-top: 5%;
      padding: 1%;
      word-wrap: break-word; }
      .lead-distribution-wrapper .cadence-attempts-wrapper .add-attempts:hover {
        cursor: pointer; }
  .lead-distribution-wrapper .onward-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 2.5rem; }
  .lead-distribution-wrapper .onward-count {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 14px;
    color: #454647;
    font-weight: 600;
    margin: 0.5em 0; }
    .lead-distribution-wrapper .onward-count img {
      width: 15px; }
      .lead-distribution-wrapper .onward-count img:hover {
        cursor: pointer; }
  .lead-distribution-wrapper .onward-input {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 0.8em; }
    .lead-distribution-wrapper .onward-input input {
      width: 55%;
      padding: 0.2em;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important; }
    .lead-distribution-wrapper .onward-input .read-only-value {
      background-color: var(--color-grey-80); }
    .lead-distribution-wrapper .onward-input select {
      width: 45%;
      background-color: #ddddddba;
      -webkit-appearance: auto;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border: 1px solid #AAA;
      padding: 0.2em;
      border-radius: 0px; }
  .lead-distribution-wrapper .input-error {
    color: var(--color-red);
    margin: 0.5em 0;
    float: left; }
  .lead-distribution-wrapper .priority-menu {
    width: 100%; }
    .lead-distribution-wrapper .priority-menu .btn-default {
      width: 100%;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      border-radius: 4px !important;
      height: 35px !important;
      box-shadow: 0 0 4px 0 #A5ADBA !important;
      text-align: left; }
      .lead-distribution-wrapper .priority-menu .btn-default:hover {
        background-color: unset;
        border-color: unset;
        color: unset; }
      .lead-distribution-wrapper .priority-menu .btn-default:focus {
        background-color: unset;
        border-color: unset;
        color: unset; }
    .lead-distribution-wrapper .priority-menu .dropdown-menu {
      left: 0;
      width: 100%; }
      .lead-distribution-wrapper .priority-menu .dropdown-menu li a {
        width: fit-content; }

#darkTrue {
  filter: invert(100%) hue-rotate(180deg); }

#darkFalse {
  filter: invert(0%) hue-rotate(0); }

.moderation {
  padding: 100px 50px;
  background-color: white;
  min-height: 100vh; }
  .moderation .darkmode {
    display: flex;
    align-items: end; }
  .moderation .total-count {
    background-color: #FF5630;
    color: white;
    display: inline-block;
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 0px 5px; }
    .moderation .total-count span:first-child {
      font-size: 50px;
      margin-right: 5px; }
  .moderation .filter {
    margin: 0 0 30px 0; }
    .moderation .filter .filter-container {
      width: 38%;
      display: flex;
      align-items: baseline;
      justify-content: space-between; }
  .moderation .deem {
    opacity: 0.4;
    transition: 200ms ease-in; }
  .moderation #moderation-list .panel-default {
    border: none !important; }
  .moderation #moderation-list .panel-heading {
    background-color: #023349;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    border: none;
    border-radius: 4px;
    cursor: pointer; }
    .moderation #moderation-list .panel-heading a {
      color: white; }
    .moderation #moderation-list .panel-heading a:focus {
      text-decoration: none; }
    .moderation #moderation-list .panel-heading a:hover {
      color: white;
      text-decoration: none; }
  .moderation #moderation-list .panel-body {
    background-color: white;
    border: none !important;
    border-radius: 4px; }
  .moderation #moderation-list .empty-review {
    text-align: center;
    font-size: 20px; }
  .moderation #moderation-list .list-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    margin: 0px -15px; }
    .moderation #moderation-list .list-wrapper a {
      width: 25%; }
    .moderation #moderation-list .list-wrapper a:hover {
      text-decoration: none; }
    .moderation #moderation-list .list-wrapper .data-card {
      position: relative;
      display: flex;
      align-items: center;
      background-color: white;
      box-shadow: 0 0 8px 0 #A5ADBA;
      transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 2%;
      margin: 10px;
      padding: 2%;
      cursor: pointer; }
      .moderation #moderation-list .list-wrapper .data-card .logo-wrapper {
        border-right: 1px solid #CECFCF;
        padding-right: 10px; }
        .moderation #moderation-list .list-wrapper .data-card .logo-wrapper img {
          width: 70px;
          border: 1px solid #CECFCF;
          border-radius: 100%; }
      .moderation #moderation-list .list-wrapper .data-card .text-wrapper {
        margin: 0px 10px;
        flex-grow: 1;
        overflow: hidden; }
        .moderation #moderation-list .list-wrapper .data-card .text-wrapper .title, .moderation #moderation-list .list-wrapper .data-card .text-wrapper .sub-title {
          color: black; }
        .moderation #moderation-list .list-wrapper .data-card .text-wrapper .sub-title {
          color: #7A869A; }
        .moderation #moderation-list .list-wrapper .data-card .text-wrapper .time-elapsed {
          color: #36B37E; }
        .moderation #moderation-list .list-wrapper .data-card .text-wrapper .stat {
          margin-top: 10px;
          color: #36B37E; }
          .moderation #moderation-list .list-wrapper .data-card .text-wrapper .stat span:first-child {
            margin-right: 5px;
            font-size: 12px; }
      .moderation #moderation-list .list-wrapper .data-card .overlay {
        opacity: 0.5;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        cursor: default;
        pointer-events: none;
        z-index: 1; }
        .moderation #moderation-list .list-wrapper .data-card .overlay img {
          width: 124px; }
    .moderation #moderation-list .list-wrapper .data-card.locked {
      cursor: default; }
    .moderation #moderation-list .list-wrapper .data-card:hover {
      box-shadow: 0 0 24px 0 #A5ADBA; }

.no-padding {
  padding: 10px 0 !important;
  background: none !important; }

.moderation-modal .modal-body {
  overflow: scroll; }
  .moderation-modal .modal-body .banner {
    font-size: 17px;
    /* border: 1px solid #0faed9; */
    background-color: #44d7ac;
    /* padding: 2%; */
    border-radius: 2px;
    width: 127%;
    opacity: 0.7;
    text-align: center;
    /* display: flex; */
    margin-bottom: 2%; }
  .moderation-modal .modal-body .edited {
    color: #ce4425;
    border: 3px solid #ce4425;
    padding: 5px;
    font-size: 18px;
    position: absolute; }
  .moderation-modal .modal-body .detail-wrapper {
    overflow: scroll;
    padding: 0px 15px;
    height: 500px;
    overflow-y: scroll;
    padding-bottom: 20px; }
  .moderation-modal .modal-body #paid-not {
    background-color: #fba189; }
  .moderation-modal .modal-body .paid-mission {
    min-width: min-content;
    height: auto;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 14%; }
    .moderation-modal .modal-body .paid-mission h3 {
      font-size: 13px;
      /* border: 1px solid #0faed9; */
      background-color: #0faed9;
      padding: 2%;
      border-radius: 2px; }
    .moderation-modal .modal-body .paid-mission img {
      width: 33%;
      opacity: 0.3; }
  .moderation-modal .modal-body .cta-wrapper {
    position: absolute;
    bottom: 0;
    box-shadow: 0 -2px 14px 0 #A5ADBA;
    left: 0;
    right: 0;
    height: 50px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease; }
    .moderation-modal .modal-body .cta-wrapper .ctas {
      display: flex;
      width: 100%;
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between; }
    .moderation-modal .modal-body .cta-wrapper .reject-feedback {
      display: flex;
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      justify-content: space-between; }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback input {
        width: 60%; }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback input[type="checkbox"] {
        -webkit-appearance: none;
        background-color: #fafafa;
        border: 1px solid #cacece;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
        width: 15px; }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback input[type="checkbox"]:active, .moderation-modal .modal-body .cta-wrapper .reject-feedback input[type="checkbox"]:checked:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1); }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback input[type="checkbox"]:checked {
        background-color: #e9ecee;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        color: #99a1a7; }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback input[type="checkbox"]:checked:after {
        content: '\2714';
        font-size: 14px;
        position: absolute;
        top: 0px;
        left: 3px;
        color: blue; }
      .moderation-modal .modal-body .cta-wrapper .reject-feedback h5 {
        margin-top: -22px;
        padding-left: 4px; }
    .moderation-modal .modal-body .cta-wrapper button {
      border: none;
      padding: 5px 20px;
      color: white;
      border-radius: 4px; }
    .moderation-modal .modal-body .cta-wrapper button:first-child {
      background-color: #36B37E; }
    .moderation-modal .modal-body .cta-wrapper button:nth-child(2) {
      background-color: #ce4425; }
    .moderation-modal .modal-body .cta-wrapper button:last-child {
      background-color: #e26464; }
  .moderation-modal .modal-body .cta-wrapper.reject-shown {
    height: 100px; }

.database {
  padding: 100px 0px;
  min-height: 100vh; }
  .database .container .limit-input-wrapper {
    display: flex;
    margin-top: 20px;
    align-items: center; }
    .database .container .limit-input-wrapper .form-control.limit {
      width: 40%; }
    .database .container .limit-input-wrapper input[type="checkbox"] {
      height: 15px !important;
      margin-left: 20px;
      padding: 8px;
      box-shadow: none !important;
      border: 1px solid #CECFCF !important; }
    .database .container .limit-input-wrapper input[type="checkbox"]:checked {
      background-image: url("../images/checked.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 11px;
      border: 1px solid #406AF8 !important;
      box-shadow: none !important; }
    .database .container .limit-input-wrapper label[for="fetchAll"], .database .container .limit-input-wrapper label[for="count"] {
      margin-left: 5px;
      margin-bottom: 0; }
  .database .container .cta-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px; }
    .database .container .cta-wrapper button {
      padding: 5px 20px;
      color: white;
      border: none;
      border-radius: 4px;
      letter-spacing: 0.5px; }
    .database .container .cta-wrapper button:first-child {
      background-color: #3A3AA4; }
    .database .container .cta-wrapper button:last-child {
      background-color: #e26464; }
  .database .container .filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .database .container .filter-wrapper .form-control {
      width: 42%; }
    .database .container .filter-wrapper span {
      font-size: 18px; }
  .database .warning {
    color: red;
    margin-top: 20px; }
  .database .table-wrapper {
    margin-top: 50px; }

@media (min-width: 1024px) {
  .database .container {
    width: 60%; } }

.MuiInputBase-input-105 {
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.bulk-checkbox {
  background-color: red; }

#bulk-status-btn {
  background-color: #01293f; }

#bulk-status-btn-aqua-blue {
  background-color: #17BCBC; }

#blue-drop-down {
  background-color: #01293f;
  color: #FFFF;
  font-weight: 600 !important;
  border-radius: 4px; }

.loader-main {
  display: flex;
  flex-flow: column;
  padding: 0%;
  align-items: center;
  justify-content: center; }
  .loader-main .text {
    font-size: 15px;
    font-weight: 900;
    padding: 2%; }

#report-modal {
  position: relative;
  padding: 15px;
  min-height: 350px; }

#loader-size img {
  width: 30%; }

#report-btn {
  margin: 3% 37% 2% 37%;
  background-color: #023349; }

.report-modal {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 2%; }
  .report-modal .project-title {
    font-weight: 800;
    font-size: 14px;
    width: 100%;
    display: flex; }
  .report-modal .headers-for-report .dropdown-menu {
    left: 0rem !important; }
    .report-modal .headers-for-report .dropdown-menu a:focus {
      outline: none; }
  .report-modal .headers-for-report .dropdown-toggle {
    width: 240px !important; }
    .report-modal .headers-for-report .dropdown-toggle:disabled:hover {
      background-color: unset !important; }
  .report-modal .react-select-container .react-select__option {
    background-color: white;
    color: black; }
    .report-modal .react-select-container .react-select__option:hover {
      background-color: skyblue; }
  .report-modal .react-select-container .react-select__input input {
    border: none !important;
    background-color: white !important;
    color: black !important;
    width: 100% !important;
    box-shadow: none !important;
    padding: 0.2em 0.5em !important;
    height: 20px !important; }
  .report-modal .react-select-container .react-select__indicators:first-child {
    margin: 0 0.2em !important; }
  .report-modal .report-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin: 3% 0; }
    .report-modal .report-type .heading {
      font-weight: 800;
      font-size: 14px; }
  .report-modal p {
    text-align: center;
    color: gray;
    font-size: 12px; }
  .report-modal .date-report {
    display: flex;
    width: 100%;
    display: flex;
    width: 100%;
    margin-top: 4%;
    justify-content: space-evenly; }
    .report-modal .date-report .info {
      padding: 8%;
      text-align: center; }
    .report-modal .date-report .SingleDatePickerInput .DateInput {
      margin-right: 0 !important; }
  .report-modal .multiselect-dropdown {
    padding: 8px; }
    .report-modal .multiselect-dropdown ._2iA8p44d0WZ-WqRBGcAuEV {
      border: none; }
      .report-modal .multiselect-dropdown ._2iA8p44d0WZ-WqRBGcAuEV input {
        margin: 3px auto;
        display: block; }
    .report-modal .multiselect-dropdown ._7ahQImyV4dj0EpcNOjnwA {
      background-color: #17bcbc;
      color: #FFF; }
    .report-modal .multiselect-dropdown .lhyQmCtWOINviMz0WG_gr {
      background: #17bcbc; }
    .report-modal .multiselect-dropdown li {
      font-size: 12px; }
      .report-modal .multiselect-dropdown li:hover {
        background: #17bcbc; }
    .report-modal .multiselect-dropdown ._32r8xHW3WLH5mygPuyDIBF i {
      display: block; }
    .report-modal .multiselect-dropdown ._1cgu0CqbPSvU9G_sHmk1wv {
      display: none; }
  .report-modal .success-banner {
    background-color: #11d17e;
    border-radius: 5px;
    padding: 1%;
    text-align: center; }
  .report-modal .pill-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }
    .report-modal .pill-wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex; }
      .report-modal .pill-wrapper .pill .del-icon img {
        height: 20px;
        transition: transform 0.2s; }
        .report-modal .pill-wrapper .pill .del-icon img:hover {
          transform: scale(1.5); }
  .report-modal .email-field {
    display: flex;
    justify-content: center;
    align-items: baseline; }
    .report-modal .email-field .plusicon {
      display: flex;
      justify-content: center;
      margin-left: -3%;
      padding: 2%; }
      .report-modal .email-field .plusicon img {
        height: 20px; }
  .report-modal .error-message {
    color: var(--color-red);
    font-weight: 600;
    text-align: center; }

.plus-icon {
  display: flex;
  justify-content: center; }
  .plus-icon:hover .tooltip {
    display: block;
    cursor: pointer; }
  .plus-icon .tooltip {
    background-color: black;
    color: white;
    opacity: 70%;
    padding: 1%;
    margin: 2%;
    width: 8%;
    display: none; }
  .plus-icon img {
    width: 23px; }
    .plus-icon img:hover {
      cursor: pointer; }

#input-credits {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between; }
  #input-credits input {
    width: 30%; }

#notif {
  display: flex;
  justify-content: center; }

#credits-control {
  margin-right: 4%; }

.tring-dashboard-wrapper {
  padding-top: 6%;
  height: 100%;
  display: flex;
  flex-flow: column; }
  .tring-dashboard-wrapper .section-wrapper {
    height: 33%;
    padding: 1% 3% 3% 3%; }
  .tring-dashboard-wrapper .card-section {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .tring-dashboard-wrapper .card-section .info-wrap {
      margin-top: 2%;
      margin-right: 3%; }
      .tring-dashboard-wrapper .card-section .info-wrap .card {
        min-width: 203px;
        max-width: 81%;
        height: 107px;
        display: flex;
        margin-top: 2%;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 3%;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        margin-right: 7%;
        text-decoration: none;
        z-index: 0;
        overflow: hidden; }
        .tring-dashboard-wrapper .card-section .info-wrap .card:hover {
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          cursor: pointer; }
        .tring-dashboard-wrapper .card-section .info-wrap .card .stat-wrapper {
          display: flex;
          width: 100%;
          justify-content: flex-start; }
          .tring-dashboard-wrapper .card-section .info-wrap .card .stat-wrapper .info-img {
            width: 19px; }
        .tring-dashboard-wrapper .card-section .info-wrap .card .stat {
          font-size: 34px;
          color: #17bcbc;
          padding-left: 4%; }
        .tring-dashboard-wrapper .card-section .info-wrap .card .card-heading {
          font-size: 13px;
          color: #5a5757;
          display: flex;
          align-items: center;
          /* align-content: space-between; */
          width: 100%;
          justify-content: space-between; }
          .tring-dashboard-wrapper .card-section .info-wrap .card .card-heading .info-img {
            width: 19px; }
        .tring-dashboard-wrapper .card-section .info-wrap .card .date {
          font-size: 12px;
          color: grey; }
    .tring-dashboard-wrapper .card-section .info {
      color: white;
      background-color: black;
      max-width: 200px;
      padding: 1%;
      font-size: 12px;
      border-radius: 5px;
      position: absolute;
      margin-left: 4%; }
    .tring-dashboard-wrapper .card-section .info-img:hover .info {
      visibility: visible;
      opacity: 1; }
  .tring-dashboard-wrapper .heading {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 1%; }

#card-line {
  border-right: 1px solid #636466;
  width: 100%;
  padding: 0% 5% 2% 0%; }

#id-column {
  width: 18%;
  font-size: 12px; }

.reactable-pagination {
  text-align: right !important; }
  .reactable-pagination a {
    padding: 1% !important; }

#tmp-moderation {
  color: #023349;
  font-size: 11px;
  border: 1px solid #023349; }

#navigation-dropdown select {
  box-shadow: none !important;
  padding: 0% 0% 5% 0%;
  color: #023349;
  font-size: 16px; }
  #navigation-dropdown select:hover {
    cursor: pointer;
    color: blue; }

.assign-caller-modal .modal-content {
  width: 47%;
  margin-left: 24%; }

.multi-tab {
  height: 41px;
  margin: 1%;
  width: 40%; }
  .multi-tab .tab {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    border: 1.4px solid #17bcbc;
    border-radius: 4px; }
    .multi-tab .tab a {
      text-decoration: none;
      width: 100%; }
  .multi-tab .each-tab {
    width: 100%;
    border-right: 1.4px solid #17bcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #17bcbc;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .multi-tab .each-tab:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      cursor: pointer; }
  .multi-tab .each-tab-active {
    width: 100%;
    border-right: 1.4px solid #17bcbc;
    background-color: #17bcbc;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .multi-tab .each-tab-active a {
      text-decoration: none;
      color: white; }
    .multi-tab .each-tab-active:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px; }

.single-tab {
  height: 41px;
  margin: 1%;
  width: 14%; }
  .single-tab .tab {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    border: 1.4px solid #e5953e !important;
    border-radius: 4px; }
    .single-tab .tab a {
      text-decoration: none;
      width: 100%; }
  .single-tab .each-tab {
    width: 100%;
    border-right: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #e5953e !important;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .single-tab .each-tab:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      cursor: pointer; }
  .single-tab .each-tab-active {
    width: 100%;
    border-right: none !important;
    background-color: #e5953e !important;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .single-tab .each-tab-active a {
      text-decoration: none;
      color: white; }
    .single-tab .each-tab-active:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px; }

#feedback-column {
  background-color: pink; }

#down-arrow {
  font-size: 26px;
  margin-top: -6%;
  opacity: 61%; }
  #down-arrow:hover {
    color: blue; }

#tring-menu {
  display: flex; }
  #tring-menu img {
    width: 20px; }

#credits-brands {
  display: flex;
  align-items: center; }

#go-back {
  background-color: white;
  text-decoration: underline;
  color: #023349;
  border: none; }

.search-input-wrapper .custom-dropdown ul li {
  font-size: 15px; }

.reactable-filterer input {
  width: 35%;
  margin-bottom: 2%; }

.quiz-modal .modal-body {
  position: relative; }

.quiz-modal .modal-dialog {
  padding: 8%; }

.bonus-payment-modal .modal-body {
  position: relative;
  padding: 7% 0% 7% 0%; }

.bonus-payment-modal .modal-dialog {
  padding: 8% 15% 5% 15%; }

.caller-audit-modal {
  width: 100%; }
  .caller-audit-modal .modal-dialog {
    padding: 0% !important; }
  .caller-audit-modal.modal-scrollbox .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    /* Scrollbar Styling */ }
    .caller-audit-modal.modal-scrollbox .modal-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-audit-modal.modal-scrollbox .modal-body::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-audit-modal.modal-scrollbox .modal-body::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4; }

.report-modal .modal-dialog {
  margin-top: 10%; }

.report-modal .modal-content {
  text-align: left;
  font-size: 18px; }

.report-modal .modal-header {
  font-size: 22px;
  margin: 0px 20px;
  border-bottom: none;
  color: #023349;
  font-weight: bold; }

.call-audit-modal .modal-dialog {
  padding: 8% 15% 5% 15%; }
  .call-audit-modal .modal-dialog .modal-content {
    overflow: auto;
    max-height: 450px; }
    .call-audit-modal .modal-dialog .modal-content::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .call-audit-modal .modal-dialog .modal-content::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .call-audit-modal .modal-dialog .modal-content::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }

.call-audit-modal .call-audit-modal-body {
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .call-audit-modal .call-audit-modal-body .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px; }
  .call-audit-modal .call-audit-modal-body .action-feedback {
    margin-top: 3%; }
  .call-audit-modal .call-audit-modal-body input {
    background-color: #efefef;
    margin: 3%;
    box-shadow: none !important; }
  .call-audit-modal .call-audit-modal-body .dropdown-call-audit {
    padding: 2% 0% 1% 0%; }
  .call-audit-modal .call-audit-modal-body .call_audit-submit-button {
    display: flex;
    justify-content: center;
    gap: 20px; }
  .call-audit-modal .call-audit-modal-body .call-audit-checkbox {
    display: flex;
    flex-flow: column;
    max-height: 120px;
    overflow-y: auto; }
    .call-audit-modal .call-audit-modal-body .call-audit-checkbox::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .call-audit-modal .call-audit-modal-body .call-audit-checkbox::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .call-audit-modal .call-audit-modal-body .call-audit-checkbox::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .call-audit-modal .call-audit-modal-body .call-audit-checkbox .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-bottom: 0px; }
      .call-audit-modal .call-audit-modal-body .call-audit-checkbox .MuiFormControlLabel-root .MuiIconButton-root {
        padding: 5px; }
    .call-audit-modal .call-audit-modal-body .call-audit-checkbox .custom-input {
      height: 30px !important;
      padding: 6px 2px; }
  .call-audit-modal .call-audit-modal-body .customer-infos {
    margin-top: 3%; }

.call-audit-modal .mock-form-modal .heading {
  font-size: 18px;
  font-weight: 900; }

.data-collection-popup .modal-dialog {
  padding: 8% 15% 5% 15%;
  min-width: max-content; }
  .data-collection-popup .modal-dialog .modal-content {
    overflow-y: auto;
    max-height: 450px;
    overflow-x: hidden; }

.data-collection-popup .data-collection-container {
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .data-collection-popup .data-collection-container .dc-heading {
    font-weight: bold; }
  .data-collection-popup .data-collection-container .questions-wrapper .question-div {
    border-bottom: 1px solid lightgray;
    margin: 10px 0px 15px; }
    .data-collection-popup .data-collection-container .questions-wrapper .question-div .question {
      font-weight: bold; }
  .data-collection-popup .data-collection-container .questions-wrapper .question-div:last-child {
    border-bottom: none; }

.call-history-container {
  width: 60vw;
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .call-history-container .ch-heading {
    font-weight: bold; }
  .call-history-container .call-history {
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px #1e1e1e71; }
    .call-history-container .call-history th {
      background-color: #E7FAF2;
      position: sticky;
      top: 0;
      z-index: 10;
      vertical-align: middle;
      padding: 12px; }
    .call-history-container .call-history td {
      border-bottom: 1px solid #ddd;
      padding: 12px !important; }
  .call-history-container .no-data {
    font-size: 14px;
    padding: 30px;
    display: flex;
    justify-content: center;
    font-size: 16px; }

.direct-assign-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .direct-assign-wrapper .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px; }
  .direct-assign-wrapper input {
    background-color: #efefef;
    height: 200px !important;
    margin: 3%;
    box-shadow: none !important; }
  .direct-assign-wrapper .assign-caller-textarea {
    padding: 2% 0% 0% 0%; }
    .direct-assign-wrapper .assign-caller-textarea textarea {
      width: 100%;
      height: 200px !important; }
  .direct-assign-wrapper .assign-submit-button {
    text-align: left; }
  .direct-assign-wrapper .info {
    display: flex;
    justify-content: space-between; }

.direct-assign-modal .modal-dialog {
  padding: 5%; }

.direct-assign-modal .direct-assign-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .direct-assign-modal .direct-assign-wrapper .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px; }
  .direct-assign-modal .direct-assign-wrapper input {
    background-color: #efefef;
    height: 200px !important;
    margin: 3%;
    box-shadow: none !important; }
  .direct-assign-modal .direct-assign-wrapper .assign-caller-textarea {
    padding: 2% 0% 0% 0%; }
    .direct-assign-modal .direct-assign-wrapper .assign-caller-textarea textarea {
      width: 100%;
      height: 200px !important; }
  .direct-assign-modal .direct-assign-wrapper .assign-submit-button {
    text-align: left; }
  .direct-assign-modal .direct-assign-wrapper .info {
    display: flex;
    justify-content: space-between; }

.tele-payment-modal .modal-dialog {
  padding: 8% 15% 5% 15%; }

.tele-payment-modal .mock-form-modal .heading {
  font-size: 18px;
  font-weight: 900; }

.tele-payment-modal .mock-form-modal .wrapper-audio {
  padding: 3%;
  display: flex;
  flex-flow: column;
  width: 100%; }
  .tele-payment-modal .mock-form-modal .wrapper-audio .question {
    margin-bottom: 3%; }
  .tele-payment-modal .mock-form-modal .wrapper-audio .fields {
    width: 100%;
    display: flex;
    flex-flow: column; }
    .tele-payment-modal .mock-form-modal .wrapper-audio .fields .each-field {
      display: flex;
      justify-content: space-between;
      margin: 4%;
      padding: 2%; }
      .tele-payment-modal .mock-form-modal .wrapper-audio .fields .each-field .label-training {
        font-size: 14px;
        font-weight: 900; }
      .tele-payment-modal .mock-form-modal .wrapper-audio .fields .each-field .grades {
        width: 51%; }
        .tele-payment-modal .mock-form-modal .wrapper-audio .fields .each-field .grades .grade-wrapper {
          display: flex;
          justify-content: space-between;
          border: 1px solid grey;
          padding: 3%; }

.tele-payment-modal .bulk-status-modal {
  display: flex;
  flex-flow: column;
  justify-content: center; }
  .tele-payment-modal .bulk-status-modal .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px; }
  .tele-payment-modal .bulk-status-modal input {
    background-color: #efefef;
    height: 200px !important;
    box-shadow: none !important; }
  .tele-payment-modal .bulk-status-modal .dropdown-bulk {
    padding: 5% 0% 4% 0%; }
  .tele-payment-modal .bulk-status-modal .bulk-submit-button {
    text-align: center; }
  .tele-payment-modal .bulk-status-modal .radio-btn-reapply .reapply-form-group label {
    font-weight: 300;
    padding-bottom: 0%;
    display: flex;
    font-size: 14px;
    color: black;
    align-items: center; }
  .tele-payment-modal .bulk-status-modal .radio-btn-reapply .reapply-form-group .reapply-radio {
    margin-bottom: 20px; }

.email-verification .modal-dialog {
  width: 500px; }
  .email-verification .modal-dialog .modal-wrapper {
    display: flex;
    align-items: center; }
    .email-verification .modal-dialog .modal-wrapper img {
      width: 30%; }
    .email-verification .modal-dialog .modal-wrapper div {
      width: 70%;
      font-size: 16px;
      font-weight: 600; }

#table-tmp .reactable-column-header {
  background-color: white !important; }

#table-tmp .reactable-filterer {
  background-color: #e7faf2 !important; }

.sentiment .positive {
  color: green; }

.sentiment .negative {
  color: red; }

.pay-modal-wrapper {
  display: flex;
  flex-flow: column; }
  .pay-modal-wrapper .pay-btn {
    background-color: #023349;
    color: white;
    min-width: 19%; }
  .pay-modal-wrapper .heading {
    font-size: 18px;
    color: #023349;
    margin-bottom: 2%; }
  .pay-modal-wrapper .label {
    color: grey;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    padding: 5% 0% 5% 0%; }
    .pay-modal-wrapper .label .brand-and-credits {
      width: 100%;
      color: #023349;
      font-size: 14px;
      display: block !important; }
      .pay-modal-wrapper .label .brand-and-credits .brand-container {
        display: flex;
        margin-left: 24%;
        /* justify-content: unset; */
        align-items: center; }
        .pay-modal-wrapper .label .brand-and-credits .brand-container .logo {
          margin-left: 20%;
          margin-right: 3%; }
          .pay-modal-wrapper .label .brand-and-credits .brand-container .logo img {
            width: 40px;
            height: 40px;
            border-radius: 50%; }
    .pay-modal-wrapper .label .amount {
      color: #023349;
      font-size: 14px; }
      .pay-modal-wrapper .label .amount .logo {
        margin-right: 18%; }
        .pay-modal-wrapper .label .amount .logo img {
          width: 40px;
          height: 40px;
          border-radius: 50%; }
  .pay-modal-wrapper .date-report {
    display: flex;
    width: 100%;
    display: flex;
    width: 100%;
    margin-top: 4%;
    justify-content: space-evenly; }
    .pay-modal-wrapper .date-report .info {
      padding: 8%;
      text-align: center; }
    .pay-modal-wrapper .date-report .DateInput_input {
      font-size: 14px; }

.reactable-column-header {
  background-color: #e7faf2 !important; }

.table-data .sms-integration {
  width: 34px; }
  .table-data .sms-integration:hover {
    cursor: pointer !important; }

.table-data .sms-not-integrated {
  opacity: 0.5;
  width: 34px; }
  .table-data .sms-not-integrated:hover {
    cursor: not-allowed; }

.table-data .action-btns span {
  display: flex;
  align-items: center;
  gap: 7px; }

.table-data .action-btns .stats-action:hover .tooltip-stats {
  display: block; }

.table-data .action-btns .stats-action:hover {
  cursor: pointer; }

.table-data .action-btns .stats-action img {
  width: 30px;
  padding: 6px; }

.table-data .action-btns .stats-edit:hover .tooltip-edit {
  display: block; }

.table-data .action-btns .stats-edit:hover {
  cursor: pointer; }

.table-data .action-btns .stats-edit img {
  width: 30px;
  padding: 6px; }

.table-data .action-btns .coach-stats:hover .tooltip-edit {
  display: block; }

.table-data .action-btns .coach-stats:hover {
  cursor: pointer; }

.table-data .action-btns .coach-stats img {
  width: 25px;
  padding: 0px; }

.table-data .action-btns .stats-report:hover .tooltip-report {
  display: block; }

.table-data .action-btns .stats-report:hover {
  cursor: pointer; }

.table-data .action-btns .stats-report img {
  width: 30px;
  padding: 6px; }

.table-data .action-btns .stats-report .btn:active {
  box-shadow: none; }

.table-data .action-btns .stats-report .btn-default {
  background-color: transparent;
  border-color: transparent;
  padding: 0; }
  .table-data .action-btns .stats-report .btn-default:hover {
    background-color: transparent;
    border-color: transparent; }
  .table-data .action-btns .stats-report .btn-default .dropdown-toggle {
    box-shadow: none; }
  .table-data .action-btns .stats-report .btn-default .caret {
    display: none; }

.table-data .action-btns .stats-report .btn-group.open .dropdown-toggle {
  box-shadow: none; }

.table-data .action-btns .stats-report .dropdown-menu {
  left: -6rem; }
  .table-data .action-btns .stats-report .dropdown-menu a:focus {
    outline: none; }

.table-data .action-btns .stats-report.disabled {
  cursor: not-allowed;
  opacity: 0.2; }

.table-data .action-btns .stats-telecallers:hover .tooltip-telecallers {
  display: block; }

.table-data .action-btns .stats-telecallers:hover {
  cursor: pointer; }

.table-data .action-btns .stats-telecallers img {
  width: 30px;
  padding: 6px; }

.table-data .action-btns .tooltip-stats {
  background-color: black;
  color: white;
  opacity: 70%;
  padding: 0% 1% 1% 1%;
  display: none; }

.table-data .action-btns .tooltip-edit {
  background-color: black;
  color: white;
  opacity: 70%;
  padding: 0% 1% 1% 1%;
  display: none; }

.table-data .action-btns .tooltip-report {
  background-color: black;
  color: white;
  opacity: 70%;
  padding: 0% 1% 1% 1%;
  display: none; }

.table-data .action-btns .tooltip-telecallers {
  background-color: black;
  color: white;
  opacity: 70%;
  padding: 0% 1% 1% 1%;
  display: none; }

.table-data .action-btns:hover {
  cursor: pointer; }

.table-data .action-btns img {
  width: 30px;
  padding: 6px; }

#option-label {
  margin-left: 2%;
  width: 57%; }

.optionContainer {
  border: 2px solid;
  color: black !important; }

#dnd-toggle {
  display: flex;
  flex-flow: column; }

#new-btn {
  background-color: #023349;
  display: flex; }

#aqua-blue-btn {
  background-color: #13cece;
  display: flex;
  font-weight: 600 !important; }

#navy-blue-btn {
  background-color: #023349;
  display: flex;
  font-weight: 600 !important; }

#transparent-btn {
  background-color: #FFFFFF;
  color: #888888;
  display: flex;
  font-weight: 600 !important; }

#new-btn-quiz {
  background-color: #023349;
  display: flex;
  margin-left: 74%; }

#new-btn-report {
  background-color: #023349;
  display: flex;
  margin-left: 43%; }

#download-report {
  background-color: #023349;
  margin-top: 0; }

#create-new {
  background-color: #023349;
  max-width: 100px;
  margin: 0% 0% 2% 1%; }

#create {
  background-color: #023349;
  max-width: 100px;
  margin: 1% 0 1% 0; }

#new-btn-2 {
  background-color: #17bcbc;
  margin-left: 20%; }

#single-btn {
  background-color: #17bcbc;
  margin-left: 11%; }

.req-button {
  border: 1px dashed #c7c4c4;
  margin-top: 4%;
  margin-left: 5%; }

.grey-button {
  color: #919191;
  padding: 5px 15px;
  background-color: inherit;
  text-transform: uppercase;
  border: none;
  /* margin-top: 10px; */
  font-size: 10px;
  font-weight: bolder; }

.dc-add-question-btn {
  color: #919191;
  padding: 5px 15px;
  background-color: #efefef;
  text-transform: uppercase;
  border: none;
  /* margin-top: 10px; */
  font-size: 10px;
  font-weight: bolder; }

.form-wrapper-additional {
  padding-left: 5%; }
  .form-wrapper-additional .type-dropdown {
    margin-top: -7%; }
  .form-wrapper-additional .form-group {
    width: 100%; }
  .form-wrapper-additional .qs-title {
    width: 201%; }

.add-tasks-wrapper {
  padding-top: 5%; }

.bonus-dropdown .Dropdown-control {
  border: 1px solid #c1bebe !important;
  margin-bottom: 5%;
  margin-left: 27%; }

.bonus-dropdown .Dropdown-menu {
  width: 50%;
  margin-left: 27%; }

.bonus-pay p {
  margin-left: 42%;
  margin-top: 2%;
  color: gray;
  font-size: 11px; }

.bonus-pay h3 {
  margin-bottom: 5%; }

.bonus-pay .Dropdown-control {
  border: 1px solid #c1bebe !important;
  width: 50%; }

#send-bonus {
  background-color: #023349;
  color: white;
  margin-left: 38%;
  margin-top: 5%;
  width: 29%; }

.job-applications {
  padding: 6% 3% 3% 3%; }
  .job-applications .project_title_header {
    display: flex;
    gap: 1%;
    margin-bottom: 1%;
    align-items: flex-start; }
    .job-applications .project_title_header img {
      height: 18px; }
      .job-applications .project_title_header img:hover {
        cursor: pointer; }
    .job-applications .project_title_header h3 {
      font-weight: 600 !important;
      font-size: 20px;
      color: #454647; }
  .job-applications .training-column img {
    width: 32%; }
  .job-applications .trainig-meeting {
    text-align: center;
    cursor: pointer; }
  .job-applications .manual-grades .each {
    display: flex;
    justify-content: space-between;
    width: 200px; }
  .job-applications .manual-grades h3 {
    font-size: 12px;
    font-weight: 600 !important; }
  .job-applications .manual-grades p {
    font-size: 10px; }
  .job-applications .select-all-jobs {
    display: flex;
    width: 98px;
    padding-top: 18%; }
    .job-applications .select-all-jobs p {
      font-size: 13px;
      margin-right: 4%;
      font-weight: 600; }
  .job-applications .bulk-status-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline; }
    .job-applications .bulk-status-row .bulk-status-btn {
      color: #13cece;
      border: none;
      font-weight: 900;
      text-decoration: underline; }
    .job-applications .bulk-status-row .section-1 {
      width: 70%; }
    .job-applications .bulk-status-row .section-2 {
      display: flex; }
    .job-applications .bulk-status-row .renew-mock-link {
      background-color: #11D17E;
      color: #FFFFFF;
      font-weight: 900 !important;
      text-transform: uppercase;
      font-size: 12px; }
    .job-applications .bulk-status-row .coach-section {
      padding: 1.5% 0;
      border: 1px solid #17BCBC;
      border-radius: 2px;
      min-width: 72%; }
      .job-applications .bulk-status-row .coach-section .label-heading {
        color: #888888;
        padding: 0 1%;
        font-weight: 600; }
      .job-applications .bulk-status-row .coach-section .coach-name {
        color: #17BCBC;
        padding: 0 1%;
        font-weight: 600; }
    .job-applications .bulk-status-row .coach-section-none {
      min-width: 72%; }
  .job-applications .multi-tab .tab {
    border: 1.4px solid #838dea; }
  .job-applications .each-tab {
    width: 100%;
    border-right: 1.4px solid #838dea;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #838dea;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .job-applications .each-tab:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      cursor: pointer; }
  .job-applications .each-tab-active {
    width: 100%;
    border-right: 1.4px solid #838dea;
    background-color: #838dea;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    height: 100%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden; }
    .job-applications .each-tab-active a {
      text-decoration: none;
      color: white; }
    .job-applications .each-tab-active:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px; }
  .job-applications .followup {
    cursor: pointer; }
  .job-applications .overdue-follow-up {
    background-color: #FFE3E3;
    color: #FF0000;
    padding: 3% 6%;
    border-radius: 6px;
    font-weight: 600;
    min-width: 75px; }
  .job-applications .followup-count {
    color: #5160E5; }
  .job-applications .navy-dropdown button {
    min-width: 120px;
    background: var(--navyblue) !important;
    color: var(--white) !important;
    font-weight: 800 !important; }
    .job-applications .navy-dropdown button .caret {
      margin-left: 3em; }

.training-meetings-wrapper {
  max-height: 60vh;
  overflow-y: auto; }
  .training-meetings-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .training-meetings-wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .training-meetings-wrapper::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.retraining-call-audited {
  padding: 0 1em; }
  .retraining-call-audited .panel_collection {
    width: 100%; }
  .retraining-call-audited .sub_heading {
    color: #023349;
    font-weight: 600;
    font-size: 0.9em; }
  .retraining-call-audited .customer_info {
    color: #555555;
    font-size: 12px;
    font-weight: 600; }
    .retraining-call-audited .customer_info .info {
      padding-bottom: 0.2em; }
  .retraining-call-audited .data_collected {
    display: flex;
    flex-direction: column;
    color: #555555;
    font-size: 12px; }
  .retraining-call-audited .qa_ans {
    margin: 0 0 1em; }
    .retraining-call-audited .qa_ans .qa {
      font-weight: bold; }
      .retraining-call-audited .qa_ans .qa p {
        margin: 0; }
  .retraining-call-audited .link {
    font-style: italic;
    color: #5160e5;
    text-decoration: underline;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer; }
  .retraining-call-audited .patition {
    display: flex;
    gap: 2%;
    width: 100%; }
  .retraining-call-audited .call_recording {
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 8px;
    width: 100%;
    padding: 2%; }
    .retraining-call-audited .call_recording .audio {
      margin: 2% 0; }
    .retraining-call-audited .call_recording p {
      margin-bottom: 0.5em; }
    .retraining-call-audited .call_recording audio {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
      min-width: 100%; }
    .retraining-call-audited .call_recording .call_recording_row {
      display: flex;
      justify-content: space-between; }
  .retraining-call-audited .page-controls {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0.8em 0; }
    .retraining-call-audited .page-controls button:disabled {
      opacity: 0.3;
      cursor: not-allowed; }
    .retraining-call-audited .page-controls .page-arrow-btn {
      padding: 0 1%; }
    .retraining-call-audited .page-controls .angle-icons {
      font-size: 15px; }

.horizontal-divider {
  height: 2px;
  margin-bottom: 3%;
  border-bottom: 2px solid #888888; }

.call-audio-info {
  padding-bottom: 1.5em; }
  .call-audio-info .info {
    display: flex;
    flex-wrap: wrap; }

.audio_copy_link {
  display: flex;
  gap: 1em;
  align-items: center; }
  .audio_copy_link img {
    width: 26px;
    padding: 0; }

.horizontal-divider-new {
  border-bottom: 2px solid #D1D1D1; }

.data-collection-wrap {
  max-height: 50vh;
  overflow-y: auto; }
  .data-collection-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .data-collection-wrap::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .data-collection-wrap::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.audio-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-size: 14px;
  gap: 2em;
  max-height: 50vh;
  overflow-y: auto; }
  .audio-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .audio-wrap::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .audio-wrap::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .audio-wrap .call-attempted {
    width: 100%; }
    .audio-wrap .call-attempted .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .audio-wrap .call-attempted .details span {
        width: 30%;
        word-wrap: break-word; }
      .audio-wrap .call-attempted .details audio {
        width: 70%; }

#crud {
  justify-content: flex-start; }

#blue-audit {
  background-color: #023349; }

#green-iqc {
  background-color: #11d17e; }

#outcome-header {
  width: 495px; }

.audit-project {
  padding: 6% 3% 3% 3%; }
  .audit-project .project-details {
    display: flex;
    flex-flow: column;
    width: 100%; }
    .audit-project .project-details .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 2% 1% 1% 0%; }
      .audit-project .project-details .head .audit-btn-project {
        color: #13cece;
        border: none;
        text-decoration: underline;
        font-weight: bolder; }
      .audit-project .project-details .head .title {
        font-size: 18px;
        font-weight: bold; }
      .audit-project .project-details .head .btn-wrapper {
        display: flex; }
  .audit-project .card-detail .card-box {
    display: flex;
    width: 100%;
    border: 1px solid #f2f8f9;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    min-height: 175px;
    border-radius: 4px; }
    .audit-project .card-detail .card-box:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white; }
    .audit-project .card-detail .card-box .section1 {
      width: 50%;
      padding: 1%;
      border-right: 1px solid #efefef; }
      .audit-project .card-detail .card-box .section1 .data {
        display: flex;
        justify-content: space-between;
        margin: 3%; }
        .audit-project .card-detail .card-box .section1 .data .title {
          font-size: 12px;
          color: #01293f;
          font-weight: 900; }
        .audit-project .card-detail .card-box .section1 .data .info {
          font-size: 12px;
          color: #424c4c;
          width: 50%; }
  .audit-project .card-detail .insights-card {
    display: flex;
    width: 100%;
    border: 1px solid #f2f8f9;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    min-height: 175px;
    border-radius: 4px; }
    .audit-project .card-detail .insights-card:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white; }
    .audit-project .card-detail .insights-card .qc-table {
      width: 100%; }
      .audit-project .card-detail .insights-card .qc-table table {
        width: 100%; }
      .audit-project .card-detail .insights-card .qc-table .header-row {
        background-color: #dff5eb; }
        .audit-project .card-detail .insights-card .qc-table .header-row td {
          width: 230px;
          padding: 1%; }
      .audit-project .card-detail .insights-card .qc-table .common-row {
        border-bottom: 1px solid #efefef; }
        .audit-project .card-detail .insights-card .qc-table .common-row td {
          padding: 1%; }
      .audit-project .card-detail .insights-card .qc-table .banner {
        background-color: #efefef;
        color: #023349;
        margin: 5%;
        padding: 2%; }
  .audit-project .bottom-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-start; }
    .audit-project .bottom-buttons .call-audit-btn {
      background-color: #13cece;
      color: white;
      margin: 2%; }

.outcome-popup {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 5%; }
  .outcome-popup .outcome-title {
    font-size: 15px;
    display: flex;
    color: #023349;
    font-weight: 900;
    margin-bottom: 1%;
    width: 440px; }
    .outcome-popup .outcome-title p {
      color: #13cece;
      font-size: 14px; }
  .outcome-popup .outcome-desc {
    font-size: 12px;
    color: grey;
    font-weight: 900; }

.script-popup .title {
  font-size: 14px;
  color: #023349;
  font-weight: 900;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #13cece;
  padding: 2%;
  border-radius: 5px;
  margin: 4%; }
  .script-popup .title h2 {
    font-size: 17px !important; }

.script-popup .url {
  font-size: 12px;
  color: grey; }

#heading-reason {
  margin-bottom: 10%; }

.tele-payment-modal .bulk-status-modal input {
  height: 30px !important; }

.modal-lg {
  width: 1000px; }

.call-audits {
  padding: 6% 3% 3% 3%; }
  .call-audits .caller-phone {
    display: flex;
    align-items: center; }
    .call-audits .caller-phone img {
      width: 24px;
      padding: 2%; }
  .call-audits .autohold {
    margin-left: 18px; }
    .call-audits .autohold img {
      width: 18px; }
  .call-audits .mht {
    color: #13cece;
    font-weight: 900;
    min-height: 104px;
    background-color: #efefef;
    margin: 2%;
    min-width: 298px;
    margin-left: 68%;
    padding: 2%; }
    .call-audits .mht .data {
      color: black;
      font-weight: 400; }
  .call-audits .mht_caller_audit {
    color: #13cece;
    font-weight: 900;
    min-height: 104px;
    background-color: #efefef;
    min-width: 298px;
    padding: 2%; }
    .call-audits .mht_caller_audit .data {
      color: black;
      font-weight: 400; }
  .call-audits .audit-arrow-caller {
    padding: 5%;
    text-align: center;
    cursor: pointer; }
    .call-audits .audit-arrow-caller:hover {
      background-color: #cecece; }
    .call-audits .audit-arrow-caller img {
      width: 8px; }
  .call-audits .audit-arrow,
  .call-audits .data-collection-icon {
    padding: 5%;
    text-align: center;
    cursor: pointer; }
    .call-audits .audit-arrow:hover,
    .call-audits .data-collection-icon:hover {
      background-color: #cecece; }
  .call-audits .audit-arrow img {
    width: 8px; }
  .call-audits .data-collection-icon img {
    width: 25px; }
  .call-audits ._header {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;
    width: 100%; }
  .call-audits .brand_details {
    display: flex;
    align-items: center; }
    .call-audits .brand_details .brand_logo {
      box-shadow: 0px 0px 3px #1e1e1ead;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-right: 1rem; }
    .call-audits .brand_details .brand_name {
      color: #023349;
      letter-spacing: 0.2rem; }
  .call-audits .loader-wrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center; }

.trigger-action-list {
  display: flex;
  flex-flow: column;
  min-height: 35vh;
  max-height: 40vh;
  overflow-y: auto;
  padding: 5% 0 0; }
  .trigger-action-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .trigger-action-list::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .trigger-action-list::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.caller-qc {
  display: flex;
  flex-flow: column;
  min-height: 70vh; }
  .caller-qc .caller_qc {
    min-height: 62vh;
    max-height: 70vh;
    margin-top: 2%;
    overflow-y: auto;
    /* Scrollbar Styling */ }
    .caller-qc .caller_qc::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .caller_qc::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .caller_qc::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
  .caller-qc .caller_qc_history {
    max-height: 70vh;
    min-height: 62vh;
    margin-top: 2%;
    overflow-y: auto;
    display: flex;
    padding: 0 2%;
    /* Scrollbar Styling */ }
    .caller-qc .caller_qc_history::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .caller_qc_history::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .caller_qc_history::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .caller-qc .caller_qc_history .qc_pass {
      color: #11D17E;
      font-weight: 800; }
    .caller-qc .caller_qc_history .qc_training {
      color: #838DEA;
      font-weight: 800; }
    .caller-qc .caller_qc_history .qc_ban {
      color: #BE5000;
      font-weight: 800; }
  .caller-qc .outcome_insight {
    max-height: 70vh;
    min-height: 62vh;
    margin-top: 2%;
    overflow-y: auto;
    /* Scrollbar Styling */ }
    .caller-qc .outcome_insight::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .outcome_insight::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .outcome_insight::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
  .caller-qc .Dropdown-menu {
    /* Scrollbar Styling */ }
    .caller-qc .Dropdown-menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .Dropdown-menu::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .Dropdown-menu::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
  .caller-qc .dc.single-tab {
    height: 26px;
    margin: 0;
    width: 10%; }
    .caller-qc .dc.single-tab a {
      text-decoration: none;
      width: 100%; }
    .caller-qc .dc.single-tab .tab {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      border: 2px solid #838dea !important;
      border-radius: 4px; }
      .caller-qc .dc.single-tab .tab a {
        text-decoration: none;
        width: 100%; }
    .caller-qc .dc.single-tab .each-tab {
      height: 30px !important;
      width: 100%;
      border: 2px solid #838dea !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #838dea !important;
      text-decoration: none;
      z-index: 0;
      overflow: hidden;
      border-radius: 4px; }
      .caller-qc .dc.single-tab .each-tab:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        cursor: pointer; }
      .caller-qc .dc.single-tab .each-tab.active {
        color: white !important;
        background-color: #838dea !important; }
        .caller-qc .dc.single-tab .each-tab.active img {
          width: 16px;
          margin-left: 4px; }
      .caller-qc .dc.single-tab .each-tab.error {
        color: red !important;
        border: 2px solid red !important; }
        .caller-qc .dc.single-tab .each-tab.error img {
          width: 16px;
          margin-left: 4px; }
    .caller-qc .dc.single-tab .each-tab-active {
      width: 100%;
      border-right: none !important;
      background-color: #838dea !important;
      display: flex;
      justify-content: center;
      color: white;
      align-items: center;
      height: 100%;
      text-decoration: none;
      z-index: 0;
      overflow: hidden; }
      .caller-qc .dc.single-tab .each-tab-active a {
        text-decoration: none;
        color: white; }
      .caller-qc .dc.single-tab .each-tab-active:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px; }
  .caller-qc .actions .dropdown {
    margin-top: 4% !important;
    margin-bottom: 5%; }
  .caller-qc .actions .caller-action-checkbox {
    display: flex;
    flex-direction: column;
    padding: 5px; }
    .caller-qc .actions .caller-action-checkbox input[type=text] {
      width: 60%;
      background-color: #EFEFEF; }
    .caller-qc .actions .caller-action-checkbox .MuiFormControlLabel-root {
      margin: 0px;
      align-items: normal; }
      .caller-qc .actions .caller-action-checkbox .MuiFormControlLabel-root .checkbox {
        margin: 0px; }
  .caller-qc .heading {
    color: #023349;
    font-size: 16px;
    font-weight: 900; }
  .caller-qc .rules {
    max-height: 150px;
    overflow-y: auto;
    /* Scrollbar Styling */ }
    .caller-qc .rules::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .rules::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .rules::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .caller-qc .rules .rules-table {
      border: 1px solid #cecece;
      width: 100%; }
      .caller-qc .rules .rules-table td {
        font-size: 12px;
        color: black; }
    .caller-qc .rules .ai-table {
      display: flex;
      flex-flow: column;
      width: 100%;
      border: 1px solid #efefef;
      margin-bottom: 2%; }
      .caller-qc .rules .ai-table .heading-table {
        display: flex;
        font-weight: 900; }
        .caller-qc .rules .ai-table .heading-table .head {
          width: 25%;
          text-align: start;
          padding: 6px;
          border: 1px solid #efefef; }
      .caller-qc .rules .ai-table .data-wrap {
        display: flex;
        flex-flow: column; }
        .caller-qc .rules .ai-table .data-wrap .each-row {
          display: flex;
          justify-content: space-around; }
          .caller-qc .rules .ai-table .data-wrap .each-row .cell {
            width: 25%;
            text-align: start;
            padding: 6px;
            display: flex;
            border: 1px solid #efefef; }
            .caller-qc .rules .ai-table .data-wrap .each-row .cell .title {
              font-size: 14px;
              font-weight: 900; }
  .caller-qc .outcomes-wrapper {
    display: flex;
    width: 100%; }
    .caller-qc .outcomes-wrapper .sectionA {
      display: flex;
      flex-flow: column;
      width: 60%;
      margin-top: 0%; }
      .caller-qc .outcomes-wrapper .sectionA .heading-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
      .caller-qc .outcomes-wrapper .sectionA .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin: 3%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .caller-qc .outcomes-wrapper .sectionA .heading .all-oc {
          color: #13cece;
          text-decoration: underline;
          font-size: 12px; }
      .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table {
        background-color: #efefef;
        width: 100%;
        height: 400px;
        border: 1px #efefef solid;
        overflow: scroll;
        display: flex;
        flex-direction: column; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTable-root .MuiButtonBase-root .MuiIcon-root {
          font-size: 15px; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTable-root .MuiTypography-root {
          font-size: 14px; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .MuiTableCell-paddingNone {
          padding: 0% !important; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .MuiTableCell-alignLeft {
          padding: 4px !important; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .call-audit-collapsable {
          background-color: rgba(247, 247, 247, 0.582);
          padding: 4%;
          width: 550px; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .call-audit-collapsable .call-audit-checkbox {
            padding: 5px; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .call-audit-collapsable .call-audit-actions {
            align-items: center; }
            .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .call-audit-collapsable .call-audit-actions button {
              float: right;
              margin: 5px 0px 0px 0px; }
            .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .MuiTableRow-root .call-audit-collapsable .call-audit-actions input {
              width: 350px; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 200px;
          overflow-x: auto; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox .MuiFormControlLabel-root {
            margin-bottom: 0px;
            margin-right: 20px; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox .MuiCheckbox-root {
            padding: 5px; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox .MuiTableRow-root .react-tabs__tab {
            color: #454647; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox .MuiTableRow-root .MuiTableCell-root {
            padding: 0px !important; }
            .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .call-audit-checkbox .MuiTableRow-root .MuiTableCell-root .MuiButtonBase-root {
              padding: 5px; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .audio-wrap {
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: 3%; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .audio-wrap .section-1 {
            width: 40%;
            display: flex; }
          .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table .audio-wrap .section-2 {
            width: 60%; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table tr {
          display: table; }
        .caller-qc .outcomes-wrapper .sectionA .outcomes .audio-inspection-table td {
          padding: 4% !important;
          font-size: 11px; }
    .caller-qc .outcomes-wrapper .actions {
      display: flex;
      flex-flow: column;
      width: 40%;
      margin: 0% 0% 0% 5%; }
      .caller-qc .outcomes-wrapper .actions .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin-top: 8%; }
      .caller-qc .outcomes-wrapper .actions .dropdown {
        margin-top: 11%; }
  .caller-qc .new-outcomes-wrapper {
    max-height: 40vh;
    min-height: 33vh;
    box-shadow: 0px 0px 6px #1E1E1E40;
    border-radius: 4px;
    overflow-y: auto;
    margin: 1% 0 0 0;
    /* Scrollbar Styling */ }
    .caller-qc .new-outcomes-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .caller-qc .new-outcomes-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .caller-qc .new-outcomes-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-heading {
      background-color: #DFF5EB;
      color: #1E1E1E;
      font-weight: 600;
      padding: 0.5% 2%;
      display: flex;
      font-size: 13px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-heading .first {
        width: 20%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-heading .second {
        width: 40%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-heading .third {
        width: 15%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-heading .fourth {
        width: 25%; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row {
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: 0.5% 2%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row.visited {
        background-color: #E5E5E5; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .first {
        width: 20%;
        font-weight: 600; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .second {
        width: 40%; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .second audio {
          height: 36px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .third {
        width: 15%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .fourth {
        width: 25%;
        font-weight: 600; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .fourth:hover {
          cursor: pointer; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .fourth .active {
          color: #023349;
          text-decoration: underline; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .table-row .fourth .inactive {
          color: #17BCBC;
          text-decoration: underline; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .row-detail-panel {
      background-color: #F8F8F8; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 200px;
      overflow-x: auto;
      /* Scrollbar Styling */
      padding: 5px;
      width: 100%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #f5f5f5; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox .MuiFormControlLabel-root {
        margin-bottom: 0px;
        margin-right: 20px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox .MuiCheckbox-root {
        padding: 5px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox .MuiTableRow-root .react-tabs__tab {
        color: #454647; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox .MuiTableRow-root .MuiTableCell-root {
        padding: 0px !important; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-checkbox .MuiTableRow-root .MuiTableCell-root .MuiButtonBase-root {
          padding: 5px; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-actions {
      align-items: center;
      padding: 2% 0; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-actions button {
        float: right;
        margin: 5px 0px 0px 0px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .call-audit-actions input {
        width: 350px; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      font-size: 12px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap div:nth-child(odd) {
        padding-right: 4px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap div:nth-child(even) {
        padding-left: 4px; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap .call-attempted {
        flex-basis: 50%; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap .call-attempted .details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%; }
          .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap .call-attempted .details span {
            width: 30%;
            word-wrap: break-word; }
          .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .audio-wrap .call-attempted .details audio {
            width: 70%; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .data-collection-question {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .data-collection-question .question-div {
        flex-basis: 50%;
        margin: 0 0 1% 0;
        font-size: 12px; }
        .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .data-collection-question .question-div p {
          margin: 0; }
    .caller-qc .new-outcomes-wrapper .custom-audio-inspection-table .customer-infos {
      width: 100%; }
    .caller-qc .new-outcomes-wrapper .actions {
      display: flex;
      flex-flow: column;
      width: 40%;
      margin: 0% 0% 0% 5%; }
      .caller-qc .new-outcomes-wrapper .actions .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900;
        margin-top: 8%; }
      .caller-qc .new-outcomes-wrapper .actions .dropdown {
        margin-top: 11%; }
  .caller-qc .actions-wrapper {
    display: flex;
    width: 100%;
    padding: 3% 0 1% 0; }
    .caller-qc .actions-wrapper .first, .caller-qc .actions-wrapper .second {
      width: 50%;
      display: flex; }
    .caller-qc .actions-wrapper .first {
      padding-right: 2%; }
      .caller-qc .actions-wrapper .first .heading {
        width: 40%; }
      .caller-qc .actions-wrapper .first .dropdown-actions {
        width: 60%; }
    .caller-qc .actions-wrapper .second {
      padding-left: 2%;
      flex-direction: column;
      align-items: flex-end; }
      .caller-qc .actions-wrapper .second button {
        width: 20%; }
    .caller-qc .actions-wrapper .actions {
      max-height: 120px;
      overflow-y: auto;
      width: 100%;
      /* Scrollbar Styling */ }
      .caller-qc .actions-wrapper .actions::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .caller-qc .actions-wrapper .actions::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5; }
      .caller-qc .actions-wrapper .actions::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
    .caller-qc .actions-wrapper .divider {
      width: 2px;
      margin: 6px 0;
      background: #E5E5E5; }
  .caller-qc .coach-feedback {
    border-top: 2px dashed grey;
    display: flex;
    padding: 2% 0 0 0; }
    .caller-qc .coach-feedback .first {
      width: 20%; }
    .caller-qc .coach-feedback .second {
      width: 70%; }
      .caller-qc .coach-feedback .second textarea {
        width: 100%; }

#win-oc {
  margin-left: 0; }

.script-dropdown {
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 0 15px; }
  .script-dropdown .Dropdown-control {
    border-bottom: 1px solid #a38f8f !important; }

#delete-response {
  margin-left: 90%;
  color: red; }

#add-qs-feedback {
  margin-bottom: -5%; }

.intro-node .heading {
  background-color: #57b19e; }

.intro-node .content {
  font-size: 8px; }

.heading-node-i {
  padding: 0 5px 0 5px;
  border-radius: 31px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center; }
  .heading-node-i.start_end {
    background-color: #8ae1ff;
    color: #023349; }
  .heading-node-i.peach {
    background-color: #FFDBC1;
    color: #023349; }
  .heading-node-i.olive_green {
    background-color: #DDED99;
    color: #023349; }
  .heading-node-i.purple {
    background-color: #B3C0F7;
    color: #023349; }
  .heading-node-i.pink {
    background-color: #FFC7FA;
    color: #023349; }
  .heading-node-i.aqua_blue {
    background-color: #8ADDDD;
    color: #023349; }
  .heading-node-i.sky_blue {
    background-color: #A7D8EF;
    color: #023349; }
  .heading-node-i.dark_pink {
    background-color: #DAB2F2;
    color: #023349; }
  .heading-node-i.orange {
    background-color: #FFE590;
    color: #023349; }
  .heading-node-i.aqua_green {
    background-color: #AEEFD1;
    color: #023349; }
  .heading-node-i.closing {
    background-color: #F6EAF3;
    color: #023349; }
  .heading-node-i.quick_pitch {
    background-color: #D4D8F8;
    color: #6374D3; }
    .heading-node-i.quick_pitch span {
      transform: skew(-14deg); }
    .heading-node-i.quick_pitch img {
      transform: skew(-14deg); }
  .heading-node-i.introduction {
    background-color: #D5E7EA;
    color: #023349; }
  .heading-node-i.main_pitch {
    background-color: #D9FFF3;
    color: #023349; }
  .heading-node-i :first-child {
    width: 80%; }
  .heading-node-i img {
    width: 12px; }

.data-collection {
  background-color: #fef0c3;
  margin-top: 5%;
  color: #ee9310;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.node-attachment-wrapper {
  margin-top: 5%;
  position: absolute;
  width: 75%;
  margin-left: 9%; }
  .node-attachment-wrapper .trigger-action {
    top: 0;
    min-height: 20px;
    background: linear-gradient(270deg, #E5953E 0%, #B46DB0 100%) 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    font-weight: 800;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 2% 0; }
    .node-attachment-wrapper .trigger-action img {
      width: 19px; }
  .node-attachment-wrapper .data-collection-box {
    top: 20px;
    min-height: 20px;
    background-color: #fef0c3;
    color: #ee9310;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }
  .node-attachment-wrapper .caller-cue-box {
    top: 40px;
    min-height: 20px;
    background-color: #dadada;
    color: #545454;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }

.data-collection-with-caller-cue {
  background-color: #fef0c3;
  margin-top: 5%;
  color: #ee9310;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.caller-cue {
  background-color: #dadada;
  color: #545454;
  margin-top: 12%;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.caller-cue-only {
  background-color: #dadada;
  color: #545454;
  margin-top: 5%;
  position: absolute;
  width: 75%;
  margin-left: 9%;
  z-index: -1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.script-header {
  box-shadow: 0px 2px 4px 2px #cecece;
  height: 46px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: baseline; }
  .script-header .script-heaing {
    position: absolute;
    left: 2%;
    width: 70%;
    display: flex;
    align-self: center; }
    .script-header .script-heaing img {
      width: 18px;
      margin-right: 2%; }
      .script-header .script-heaing img:hover {
        cursor: pointer; }
    .script-header .script-heaing span {
      align-self: center;
      padding-left: 15px;
      text-decoration: underline;
      color: darkgray; }
    .script-header .script-heaing .live-script {
      display: flex;
      align-items: center;
      margin-left: 3%; }
      .script-header .script-heaing .live-script .live {
        color: #11d17e;
        font-size: 12px; }
      .script-header .script-heaing .live-script img {
        width: 11px; }
  .script-header .publish-btn {
    border: 1px solid #023349;
    border-radius: 2px;
    margin: 1% 0.7%;
    float: right; }
    .script-header .publish-btn:hover {
      color: white;
      border-color: #17bcbc;
      background-color: #17bcbc;
      scale: 1.3; }
  .script-header .publish-btn.delete {
    border-color: #FF0000;
    color: #FF0000; }
    .script-header .publish-btn.delete:hover {
      background-color: #FF0000;
      color: white;
      scale: 1.3; }
  .script-header .save-btn {
    background-color: gainsboro;
    border-radius: 2px;
    margin: 1%; }

.faq-popup {
  padding: 3%; }
  .faq-popup .heading-faq {
    font-size: 14px;
    font-weight: 900; }
  .faq-popup .download-faq .download-btn {
    font-size: 11px;
    font-weight: 900;
    color: #023349;
    display: flex;
    align-items: baseline;
    margin-top: 2%; }
  .faq-popup .download-faq img {
    width: 13px;
    margin-left: 6px; }
  .faq-popup .grey-box {
    background-color: #efefef;
    border: 2px dotted #cecece;
    padding: 3%;
    margin: 2%; }
    .faq-popup .grey-box .upload-text {
      font-size: 11px;
      font-weight: 900;
      color: #023349; }
  .faq-popup .faq-btn {
    background-color: #023349;
    color: white;
    margin-top: 2%;
    padding: 1%;
    border-radius: 4px;
    width: 77px; }

.i-wrap {
  display: flex;
  width: 100%; }
  .i-wrap .flow {
    width: 100%; }
  .i-wrap .toolbar-script .wrapper-i {
    width: 210px;
    box-shadow: 0px 2px 4px 2px #cecece;
    background-color: aliceblue;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: start; }
    .i-wrap .toolbar-script .wrapper-i .card-wrap {
      margin: 5%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center; }
      .i-wrap .toolbar-script .wrapper-i .card-wrap .card-false {
        width: 69px;
        height: 54px;
        cursor: pointer;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 5%;
        align-items: center;
        margin: 10%; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-false .label-i {
          font-size: 12px;
          font-weight: 900; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-false img {
          padding: 8%;
          width: 65px; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-false .un-dragable {
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none; }
      .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true {
        width: 69px;
        height: 54px;
        border: 1px solid;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 5%;
        align-items: center;
        margin: 10%;
        background-color: #8ae1ff; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true .label-i {
          font-size: 12px;
          font-weight: 900; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true img {
          padding: 8%; }
      .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true {
        width: 69px;
        height: 54px;
        border: 1px solid;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 5%;
        align-items: center;
        margin: 10%;
        background-color: #8ae1ff; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true .label-i {
          font-size: 13px;
          font-weight: 900; }
        .i-wrap .toolbar-script .wrapper-i .card-wrap .card-true img {
          padding: 8%; }
  .i-wrap .toolbar-script .language-listing {
    display: flex;
    flex-flow: column;
    gap: 5px;
    width: 100%;
    border-top: 2px solid #8ADDDD;
    padding: 6% 10%; }
    .i-wrap .toolbar-script .language-listing .lang-header {
      color: #023349;
      font-weight: 600;
      font-size: 15px; }
    .i-wrap .toolbar-script .language-listing .list {
      display: flex;
      justify-content: space-between; }
      .i-wrap .toolbar-script .language-listing .list.active {
        background-color: #8ae1ff;
        padding: 2%; }
      .i-wrap .toolbar-script .language-listing .list .name {
        color: #169999;
        font-weight: 600;
        font-size: 15px; }
        .i-wrap .toolbar-script .language-listing .list .name:hover {
          cursor: pointer; }
      .i-wrap .toolbar-script .language-listing .list .default {
        font-style: italic;
        color: #888888; }
  .i-wrap .react-flow__node-input {
    border-radius: 31px; }
  .i-wrap .react-flow__node-output {
    border-radius: 31px;
    border-color: #0041d0; }
  .i-wrap .react-flow__node-output .react-flow__handle {
    background: #0041d0; }
  .i-wrap .react-flow__node {
    width: 220px; }
    .i-wrap .react-flow__node .text {
      text-align: left; }
    .i-wrap .react-flow__node .quick_pitch_node {
      border: 2px solid #6374D3;
      padding: 8px;
      transform: skew(14deg); }
      .i-wrap .react-flow__node .quick_pitch_node .text {
        transform: skew(-14deg); }
    .i-wrap .react-flow__node .closing_node {
      border: 2px solid #B7219F;
      padding: 8px; }
    .i-wrap .react-flow__node .main_pitch_node {
      border: 2px solid #008B70;
      padding: 8px; }
    .i-wrap .react-flow__node .introduction_node {
      border: 2px solid #023349;
      padding: 8px; }
    .i-wrap .react-flow__node .start_ending_node {
      border: 2px solid #24C7FF;
      padding: 8px;
      border-radius: 31px;
      background-color: #D6F1FF; }
    .i-wrap .react-flow__node .peach_node {
      border: 2px solid #BE5000;
      padding: 8px;
      background-color: #FFF0E5; }
    .i-wrap .react-flow__node .olive_green_node {
      border: 2px solid #809F00;
      padding: 8px;
      background-color: #F7FCE2; }
    .i-wrap .react-flow__node .purple_node {
      border: 2px solid #415ED8;
      padding: 8px;
      background-color: #E3E2FC; }
    .i-wrap .react-flow__node .pink_node {
      border: 2px solid #CE16BE;
      padding: 8px;
      background-color: #FFEAFD; }
    .i-wrap .react-flow__node .aqua_blue_node {
      border: 2px solid #1CADAD;
      padding: 8px;
      background-color: #E3FFFF; }
    .i-wrap .react-flow__node .sky_blue_node {
      border: 2px solid #023349;
      padding: 8px;
      background-color: #E1F1F4; }
    .i-wrap .react-flow__node .dark_pink_node {
      border: 2px solid #7B0ABE;
      padding: 8px;
      background-color: #F3E2FD; }
    .i-wrap .react-flow__node .orange_node {
      border: 2px solid #EE9310;
      padding: 8px;
      background-color: #FFF6D8; }
    .i-wrap .react-flow__node .aqua_green_node {
      border: 2px solid #1BAF7E;
      padding: 8px;
      background-color: #E6FFF4; }
  .i-wrap .react-flow__node-default, .i-wrap .react-flow__node-input, .i-wrap .react-flow__node-output {
    border-style: none;
    padding: 0; }
  .i-wrap .react-flow__node-default.selected,
  .i-wrap .react-flow__node-default.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.3); }
  .i-wrap .react-flow__node-input.selected,
  .i-wrap .react-flow__node-input.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 65, 208, 0.336); }
  .i-wrap .react-flow__node-output.selected,
  .i-wrap .react-flow__node-output.selected:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 65, 208, 0.336); }
  .i-wrap .react-flow__edge-path {
    stroke: #b1b1b7;
    stroke-width: 2; }
    .i-wrap .react-flow__edge-path:hover {
      stroke: #67676b; }
    .i-wrap .react-flow__edge-path.introduction {
      stroke: #023349; }
    .i-wrap .react-flow__edge-path.closing {
      stroke: #B7219F; }
    .i-wrap .react-flow__edge-path.quick_pitch {
      stroke: #6374D3; }
    .i-wrap .react-flow__edge-path.main_pitch {
      stroke: #008B70; }
    .i-wrap .react-flow__edge-path.peach {
      stroke: #BE5000; }
    .i-wrap .react-flow__edge-path.olive_green {
      stroke: #809F00; }
    .i-wrap .react-flow__edge-path.purple {
      stroke: #415ED8; }
    .i-wrap .react-flow__edge-path.pink {
      stroke: #CE16BE; }
    .i-wrap .react-flow__edge-path.aqua_blue {
      stroke: #1CADAD; }
    .i-wrap .react-flow__edge-path.sky_blue {
      stroke: #023349; }
    .i-wrap .react-flow__edge-path.dark_pink {
      stroke: #7B0ABE; }
    .i-wrap .react-flow__edge-path.orange {
      stroke: #EE9310; }
    .i-wrap .react-flow__edge-path.aqua_green {
      stroke: #1BAF7E; }
  .i-wrap .react-flow__edge.selected .react-flow__edge-path {
    stroke: #673ab7; }
  .i-wrap .react-flow__edge.peach_text {
    color: #BE5000; }
  .i-wrap .react-flow__edge.olive_green_text {
    color: #809F00; }
  .i-wrap .react-flow__edge.purple_text {
    color: #415ED8; }
  .i-wrap .react-flow__edge.pink_text {
    color: #CE16BE; }
  .i-wrap .react-flow__edge.aqua_blue_text {
    color: #1CADAD; }
  .i-wrap .react-flow__edge.sky_blue_text {
    color: #023349; }
  .i-wrap .react-flow__edge.dark_pink_text {
    color: #7B0ABE; }
  .i-wrap .react-flow__edge.orange_text {
    color: #EE9310; }
  .i-wrap .react-flow__edge.aqua_green_text {
    color: #1BAF7E; }
  .i-wrap .react-flow__edge.introduction_text {
    color: #023349; }
  .i-wrap .react-flow__edge.closing_text {
    color: #B7219F; }
  .i-wrap .react-flow__edge.quick_pitch_text {
    color: #6374D3; }
  .i-wrap .react-flow__edge.main_pitch_text {
    color: #008B70; }
  .i-wrap .quick-pitch {
    background-color: red; }
  .i-wrap .crud-form {
    box-shadow: 0px 2px 4px 2px #cecece;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 31%; }
    .i-wrap .crud-form .feedback-text {
      color: #ee9310;
      font-weight: 900;
      text-decoration: underline;
      margin-top: 7%; }
      .i-wrap .crud-form .feedback-text a {
        color: #ee9310 !important;
        font-size: 11px;
        margin-left: 5%; }
    .i-wrap .crud-form .in-form .panel-group {
      margin-bottom: 0; }
    .i-wrap .crud-form .in-form .panel-default {
      border: none; }
    .i-wrap .crud-form .in-form .panel,
    .i-wrap .crud-form .in-form .panel-body {
      box-shadow: none;
      border-radius: 0px; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading {
      padding: 0; }
      .i-wrap .crud-form .in-form .panel-group .panel-heading a {
        padding: 10px 0 !important; }
    .i-wrap .crud-form .in-form .panel-default > .panel-heading {
      color: #333;
      background-color: transparent;
      border-color: #ddd; }
    .i-wrap .crud-form .in-form .heading {
      font-size: 16px;
      font-weight: 900;
      display: flex;
      gap: 5px;
      width: 100%; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading a {
      display: block;
      padding: 10px;
      text-decoration: none;
      position: relative;
      font-size: 1em; }
      .i-wrap .crud-form .in-form .panel-group .panel-heading a span {
        padding: 0 5% 0 0; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading a:after {
      content: url("../images/uparrow.svg");
      font-size: 20px;
      font-weight: 600;
      width: 15px;
      height: 15px; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading a.collapsed:after {
      content: url("../images/downarrow.svg");
      font-size: 20px;
      font-weight: 600;
      width: 15px;
      height: 15px; }
    .i-wrap .crud-form .in-form .panel-default .panel-heading {
      background-color: transparent; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading + .panel-collapse > .panel-body, .i-wrap .crud-form .in-form .panel-group .panel-heading + .panel-collapse > .list-group {
      border-top: none; }
    .i-wrap .crud-form .in-form .panel-title a:hover {
      color: #333333;
      text-decoration: none; }
    .i-wrap .crud-form .in-form .panel-title a:focus {
      text-decoration: none; }
    .i-wrap .crud-form .in-form .panel-group .panel-heading a {
      display: inline-flex;
      justify-content: space-between;
      width: 100%; }
    .i-wrap .crud-form .in-form .panel-group .panel {
      border-radius: 0px; }
    .i-wrap .crud-form .in-form .panel-group .panel + .panel {
      margin-top: 0px; }
    .i-wrap .crud-form .in-form .title-cue {
      color: grey;
      padding: 9% 0% 4% 1%; }
    .i-wrap .crud-form .edge-text-practice {
      padding: 5% 0 0 0; }
      .i-wrap .crud-form .edge-text-practice textarea {
        border: 2px solid #CC86C1 !important;
        outline: none !important;
        width: 240px;
        box-shadow: none !important; }
      .i-wrap .crud-form .edge-text-practice .title {
        color: #CC86C1; }
      .i-wrap .crud-form .edge-text-practice audio {
        width: 240px !important;
        height: 38px;
        margin: 5% 0 0 0; }
    .i-wrap .crud-form .dc-section {
      margin: 0 0 5% 0;
      padding-left: 10px; }
      .i-wrap .crud-form .dc-section .dc-form-button {
        border: 2px solid #17bcbc;
        color: #17bcbc;
        font-weight: 600;
        padding: 1.5%; }
        .i-wrap .crud-form .dc-section .dc-form-button:hover {
          cursor: pointer; }
    .i-wrap .crud-form .live-action-section {
      padding-left: 10px;
      display: flex; }
      .i-wrap .crud-form .live-action-section .live-action-btn {
        background: linear-gradient(270deg, #E5953E 0%, #B46DB0 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 800 !important;
        float: left !important;
        margin: 2% 0 5% 0;
        padding: 2% 3%; }
        .i-wrap .crud-form .live-action-section .live-action-btn img {
          width: 22px; }
    .i-wrap .crud-form .node-text-practice {
      padding: 5% 0 0 0; }
      .i-wrap .crud-form .node-text-practice audio {
        width: 100%;
        height: 38px; }
    .i-wrap .crud-form .circle-loader {
      fill: transparent;
      stroke: #eee;
      stroke-width: 5; }
    .i-wrap .crud-form .circle-loader {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-left-color: #11d17e;
      animation: loader-spin 1.2s infinite linear;
      position: relative;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      width: 3em;
      height: 3em; }
    .i-wrap .crud-form .load-complete {
      -webkit-animation: none;
      animation: none;
      border-color: #11d17e;
      transition: border 500ms ease-out; }
    .i-wrap .crud-form .checkmark {
      display: none; }
      .i-wrap .crud-form .checkmark.draw:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg); }
      .i-wrap .crud-form .checkmark:after {
        opacity: 1;
        height: 1.5em;
        width: 0.75em;
        transform-origin: left top;
        border-right: 3px solid #11d17e;
        border-top: 3px solid #11d17e;
        content: '';
        left: 0.75em;
        top: 1.5em;
        position: absolute; }

@keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  20% {
    height: 0;
    width: 0.75em;
    opacity: 1; }
  40% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; }
  100% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1; } }
    .i-wrap .crud-form #bars {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      /*     background: black; */ }
    .i-wrap .crud-form .bar {
      background: #169999;
      bottom: 1px;
      height: 3px;
      width: 10px;
      margin: 0px 4px;
      border-radius: 5px;
      animation: sound 0ms -600ms linear infinite alternate; }

@keyframes sound {
  0% {
    opacity: .35;
    height: 3px; }
  100% {
    opacity: 1;
    height: 35px; } }
    .i-wrap .crud-form .bar:nth-child(1) {
      left: 1px;
      animation-duration: 474ms; }
    .i-wrap .crud-form .bar:nth-child(2) {
      left: 15px;
      animation-duration: 433ms; }
    .i-wrap .crud-form .bar:nth-child(3) {
      left: 29px;
      animation-duration: 407ms; }
    .i-wrap .crud-form .bar:nth-child(4) {
      left: 43px;
      animation-duration: 458ms; }
    .i-wrap .crud-form .bar:nth-child(5) {
      left: 57px;
      animation-duration: 400ms; }
    .i-wrap .crud-form .bar:nth-child(6) {
      left: 71px;
      animation-duration: 427ms; }
    .i-wrap .crud-form .bar:nth-child(7) {
      left: 85px;
      animation-duration: 441ms; }
    .i-wrap .crud-form .bar:nth-child(8) {
      left: 99px;
      animation-duration: 419ms; }
    .i-wrap .crud-form .bar:nth-child(9) {
      left: 113px;
      animation-duration: 487ms; }
    .i-wrap .crud-form .bar:nth-child(10) {
      left: 127px;
      animation-duration: 442ms; }
  .i-wrap .crud-form {
    padding: 0 1rem; }
    .i-wrap .crud-form #x {
      position: absolute;
      background-image: url("../images/cross.svg");
      left: 79%; }
    .i-wrap .crud-form .close-sec {
      margin: 5% 2%;
      text-decoration: underline; }
      .i-wrap .crud-form .close-sec:hover {
        cursor: pointer;
        color: blue; }
    .i-wrap .crud-form .is-edited {
      color: #DD5858;
      font-size: 14px; }
    .i-wrap .crud-form .border-outline {
      border: 2px solid #BABABA;
      border-radius: 12px;
      padding: 4%;
      margin: 6% 0;
      /* The checkbox - when checked */ }
      .i-wrap .crud-form .border-outline .is-practice-response {
        border-top: 2px solid #BABABA;
        padding: 4% 0.5%; }
        .i-wrap .crud-form .border-outline .is-practice-response .dc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          /* Rounded sliders */ }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .question {
            width: 81%; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .switch {
            position: relative;
            display: inline-block;
            width: 45px;
            height: 22px; }
            .i-wrap .crud-form .border-outline .is-practice-response .dc .switch label {
              margin: 0 !important; }
            .i-wrap .crud-form .border-outline .is-practice-response .dc .switch input {
              opacity: 0;
              width: 0;
              height: 0; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .slider:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 4px;
            bottom: 4px;
            background-color: #FFFFFF;
            -webkit-transition: .4s;
            transition: .4s; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc input:checked + .slider {
            background-color: #838DEA; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc input:focus + .slider {
            box-shadow: 0 0 1px #838DEA; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc input:checked + .slider:before {
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px); }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .slider.round {
            border-radius: 25px; }
          .i-wrap .crud-form .border-outline .is-practice-response .dc .slider.round:before {
            border-radius: 50%; }
      .i-wrap .crud-form .border-outline .checkbox > label > input {
        /*
        Remove the default appearance.
      */
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        /*
        Set the size of the checkbox.
      */
        width: 20px !important;
        height: 20px !important;
        box-shadow: 0 0 0 2px black;
        /* Outer border */
        border: 3px solid white;
        /* Inner border */ }
      .i-wrap .crud-form .border-outline .checkbox > label::after {
        content: "\2713";
        color: #FFFFFF;
        position: absolute;
        left: 4px; }
      .i-wrap .crud-form .border-outline .checkbox > label > input:checked {
        background-color: #159B72;
        /* The "check" */ }
    .i-wrap .crud-form .title {
      color: #000000;
      font-weight: 600; }
      .i-wrap .crud-form .title.px-10 {
        padding: 0 10px; }
    .i-wrap .crud-form .heading {
      color: #005598;
      font-weight: 800;
      font-size: 16px; }
    .i-wrap .crud-form .header-section {
      display: flex;
      justify-content: space-between;
      margin: 6% 0; }
      .i-wrap .crud-form .header-section .close-icon {
        height: 20px;
        color: #555555;
        cursor: pointer; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 5px 5px;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #aaa; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 180px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 5px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #f2f9fc; }

.react-flow__controls {
  margin-left: 79%; }

.additional-wrapper2 .doc-link {
  color: #17bcbc;
  font-weight: 600;
  text-decoration: underline; }

.additional-wrapper2 .tab-container {
  display: flex;
  gap: 10px;
  flex-flow: row;
  width: 100%; }

.additional-wrapper2 .tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.additional-wrapper2 .outcome-wrapper .header {
  font-size: 17px;
  font-weight: 600;
  margin-top: 2%;
  background-color: #023349;
  padding: 1%;
  color: white;
  width: 20%; }

.additional-wrapper2 .outcome-wrapper .title {
  font-size: 14px;
  color: #023349;
  font-weight: 900; }

.additional-wrapper2 .outcome-wrapper .feedback-form .switch-wrapper input:checked + .slider {
  background-color: #17bcbc; }

.additional-wrapper2 .outcome-wrapper .feedback-form .green-button {
  color: #13cece;
  margin-left: 2%; }

.additional-wrapper2 .outcome-wrapper .feedback-form .question-form {
  background-color: #efefef;
  margin-bottom: 3%;
  transition: transform 0.3s ease-in-out; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .title-section {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .re-order-section {
    font-size: 22px; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .re-order-section .disabled {
      opacity: 0.5; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .re-order-section svg {
      margin-right: 0.5em;
      cursor: pointer; }
      .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .re-order-section svg:last-child {
        margin-right: 0; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form.moving-up {
    transform: translateY(-100%); }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form.moving-down {
    transform: translateY(100%); }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form.highlighted {
    background-color: #EAFBFF; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .bottom-btns {
    margin-top: 0%;
    padding: 1% 2%;
    background-color: #e5e2e2;
    display: flex;
    justify-content: space-between; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .bottom-btns .switch-optional {
      display: flex;
      align-items: center;
      gap: 5%; }
      .additional-wrapper2 .outcome-wrapper .feedback-form .question-form .bottom-btns .switch-optional p {
        margin: 0px 0px; }

.additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback {
  padding: 2%;
  margin: 3% 0 0; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .label {
    display: block;
    padding: 0.2em 0 0.3em; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div {
      display: grid;
      grid-template-columns: 80% 20%; }
      .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .label {
        padding: 0px; }
        .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .label input {
          height: 30px !important; }
      .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon {
        margin-left: 10px; }
        .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon img {
          margin-top: 3px; }
        .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon img:hover {
          cursor: pointer; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .each-feeback .csv-preview {
    padding: 10px;
    border-radius: 5px;
    background-color: aliceblue;
    gap: 0px 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 200px;
    overflow-x: auto; }

.additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper {
  display: flex;
  gap: 4%;
  align-items: center; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .upload-csv {
    flex: 2; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .download-csv {
    width: 24px;
    cursor: pointer; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .question-type {
    display: flex;
    width: 35%;
    height: 32px; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .question-type .green-tab {
      background-color: #13cece;
      width: 50%;
      display: grid;
      place-items: center;
      color: white;
      cursor: pointer;
      border: 1px solid #023349; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .question-type .white-tab {
      background-color: white;
      width: 50%;
      display: grid;
      place-items: center;
      cursor: pointer;
      border: 1px solid #023349; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .question-type .tab {
      width: 50%; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .switch-optional {
    display: grid;
    justify-items: self-end; }
    .additional-wrapper2 .outcome-wrapper .feedback-form .tabs-wrapper .switch-optional p {
      margin: 0px 0px; }

.additional-wrapper2 .outcome-wrapper .feedback-form .datetime-dropdown {
  margin: 0px 0px; }

.additional-wrapper2 .outcome-wrapper .feedback-form .text-validation {
  display: flex; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .text-validation .form-group {
    padding-left: 5%; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .text-validation .custom-valdation.form-group {
    margin-bottom: 0 !important; }
  .additional-wrapper2 .outcome-wrapper .feedback-form .text-validation .custom-valdation .qs-title {
    width: 40%; }

.additional-wrapper2 .outcome-wrapper .feedback-form .tags-section {
  display: flex;
  align-items: baseline;
  gap: 2%;
  width: 100%; }

.additional-wrapper2 .trigger-action-dropdown {
  margin-top: 2%;
  width: 20%;
  border-bottom: 1px solid #a38f8f !important; }

.additional-wrapper2 .add-cta {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  color: #17bcbc; }
  .additional-wrapper2 .add-cta:hover {
    cursor: pointer; }
  .additional-wrapper2 .add-cta.flex-end {
    justify-content: flex-end; }

.additional-wrapper2 .name-value-section {
  margin-top: 2%; }
  .additional-wrapper2 .name-value-section .contain {
    max-height: 180px;
    min-height: 100px;
    overflow-y: auto;
    padding: 1% 0 0 0; }
    .additional-wrapper2 .name-value-section .contain::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .additional-wrapper2 .name-value-section .contain::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .additional-wrapper2 .name-value-section .contain::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }

.additional-wrapper2 .name-value-row {
  display: flex;
  gap: 5%;
  align-items: flex-start;
  position: relative;
  margin-bottom: 2%; }
  .additional-wrapper2 .name-value-row .label-value {
    display: flex;
    gap: 2%;
    align-items: center;
    width: 30% !important;
    align-items: baseline; }
    .additional-wrapper2 .name-value-row .label-value.w-70 {
      width: 70% !important; }
    .additional-wrapper2 .name-value-row .label-value .label-bold {
      font-weight: 600;
      font-size: 13px;
      width: 30%; }
      .additional-wrapper2 .name-value-row .label-value .label-bold.w-10 {
        width: 10%; }
  .additional-wrapper2 .name-value-row .delete-icon {
    width: 18px; }
    .additional-wrapper2 .name-value-row .delete-icon:hover {
      cursor: pointer; }
  .additional-wrapper2 .name-value-row .action-btn {
    padding-top: 2%; }

.calls-count {
  font-weight: 700;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end; }

.tab-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0; }
  .tab-row .first-div {
    width: 80%; }
  .tab-row button {
    margin-top: 0; }
  .tab-row .dropdown-menu {
    max-width: 160px;
    min-width: 100px;
    left: -1.7em;
    white-space: pre-wrap; }

.additional-wrapper {
  padding: 6% 3% 3% 3%; }
  .additional-wrapper .add-task-wrapper {
    display: flex;
    min-height: 100vh;
    width: 100%;
    padding: 0% 0% 3% 9%; }
    .additional-wrapper .add-task-wrapper .csv-wrapper {
      width: 50%;
      padding: 1% 5% 0% 5%; }
    .additional-wrapper .add-task-wrapper .drop-leads-wrapper {
      width: 50%;
      padding: 1% 3%; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .form-group {
        padding: 0; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper input {
        border: none !important;
        background: none !important; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:checked,
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:checked + label,
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:checked + label:before,
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:checked + label:after,
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #11D17E;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0); }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1); }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .drop-lead-heading {
        font-size: 13px;
        font-weight: 600;
        padding: 1% 0; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .drop-lead-sub-heading {
        font-size: 11px;
        color: #888888;
        font-weight: 600;
        padding: 1% 0; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .option-icon-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 6px; }
        .additional-wrapper .add-task-wrapper .drop-leads-wrapper .option-icon-wrapper img {
          width: 16px; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .reset-link {
        color: #13CECE;
        font-weight: 600;
        padding: 1% 0;
        text-decoration: underline; }
        .additional-wrapper .add-task-wrapper .drop-leads-wrapper .reset-link:hover {
          cursor: pointer; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .option-link {
        color: #13CECE;
        font-weight: 600;
        text-decoration: underline;
        padding: 1% 0; }
        .additional-wrapper .add-task-wrapper .drop-leads-wrapper .option-link:hover {
          cursor: pointer; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .drop-lead-options {
        padding: 5% 0; }
        .additional-wrapper .add-task-wrapper .drop-leads-wrapper .drop-lead-options label {
          color: #17BCBC;
          font-weight: 600; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper .drop-lead-block-all-leads {
        display: flex;
        width: 60%;
        padding: 0 0 4% 0; }
      .additional-wrapper .add-task-wrapper .drop-leads-wrapper table {
        background-color: #e9e9e9;
        border-radius: 0; }
    .additional-wrapper .add-task-wrapper .task-wrapper {
      width: 50%;
      border-right: 1px solid black; }
  .additional-wrapper .task-sec-wrapper {
    padding: 6% 3% 6% 0%; }
  .additional-wrapper .csv-headers {
    padding: 2% 3% 6% 5%; }
    .additional-wrapper .csv-headers .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline; }
      .additional-wrapper .csv-headers .title .title-in {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 8%; }
      .additional-wrapper .csv-headers .title .btn {
        display: flex;
        flex-flow: column;
        font-size: 11px; }
        .additional-wrapper .csv-headers .title .btn .tx {
          background-color: black;
          opacity: 72%;
          color: white;
          padding: 1%;
          border-radius: 3px; }
        .additional-wrapper .csv-headers .title .btn img {
          width: 20px;
          margin-left: 12px; }
    .additional-wrapper .csv-headers .duplicateNumbers {
      padding-bottom: 0;
      margin-bottom: 4px; }
    .additional-wrapper .csv-headers .alert-for-duplicate-number {
      font-size: 13px; }
    .additional-wrapper .csv-headers .pill-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 7%; }
      .additional-wrapper .csv-headers .pill-wrapper:hover {
        cursor: pointer; }
      .additional-wrapper .csv-headers .pill-wrapper .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex;
        height: 31px;
        width: max-content; }
        .additional-wrapper .csv-headers .pill-wrapper .pill .del-icon img {
          height: 20px;
          transition: transform 0.2s; }
          .additional-wrapper .csv-headers .pill-wrapper .pill .del-icon img:hover {
            transform: scale(1.5); }
      .additional-wrapper .csv-headers .pill-wrapper .email-field {
        display: flex;
        justify-content: flex-start;
        margin-top: 2%;
        margin-left: -1%; }
        .additional-wrapper .csv-headers .pill-wrapper .email-field .plusicon {
          display: flex;
          justify-content: center;
          margin-left: -3%;
          padding: 2%; }
          .additional-wrapper .csv-headers .pill-wrapper .email-field .plusicon img {
            height: 20px; }
  .additional-wrapper .plus-icon {
    display: flex;
    justify-content: center; }
    .additional-wrapper .plus-icon:hover .tooltip {
      display: block;
      cursor: pointer; }
    .additional-wrapper .plus-icon .tooltip {
      background-color: black;
      color: white;
      opacity: 70%;
      padding: 1%;
      margin: 2%;
      width: 8%;
      display: none; }
    .additional-wrapper .plus-icon img {
      width: 23px; }
      .additional-wrapper .plus-icon img:hover {
        cursor: pointer; }
  .additional-wrapper .outcome-wrapper {
    min-height: 136px;
    background-color: #f0faf5;
    margin: 4%;
    padding: 3%; }
    .additional-wrapper .outcome-wrapper .header {
      font-size: 17px;
      font-weight: 600;
      margin-top: 2%;
      background-color: #023349;
      padding: 1%;
      color: white;
      width: 20%; }
    .additional-wrapper .outcome-wrapper .title {
      font-size: 14px;
      color: #023349;
      font-weight: 900; }
    .additional-wrapper .outcome-wrapper .feedback-form {
      background-color: white;
      margin-top: 2%;
      padding: 2%; }
      .additional-wrapper .outcome-wrapper .feedback-form .green-button {
        color: #13cece;
        margin-left: 2%; }
      .additional-wrapper .outcome-wrapper .feedback-form .each-feeback {
        padding: 2%; }
        .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .bottom-btns {
          margin-top: 3%; }
        .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 49% 49%;
          grid-column-gap: 2%; }
          .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div {
            display: grid;
            grid-template-columns: 80% 20%; }
            .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .label {
              padding: 0px; }
              .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .label input {
                height: 30px !important; }
            .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon {
              margin-left: 10px; }
              .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon img {
                margin-top: 3px; }
              .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .options-form-wrapper .input-option-div .delete-icon img:hover {
                cursor: pointer; }
        .additional-wrapper .outcome-wrapper .feedback-form .each-feeback .csv-preview {
          padding: 10px;
          border-radius: 5px;
          background-color: aliceblue;
          gap: 0px 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 200px;
          overflow-x: auto; }
      .additional-wrapper .outcome-wrapper .feedback-form .tabs-wrapper {
        display: flex;
        justify-content: space-between; }
        .additional-wrapper .outcome-wrapper .feedback-form .tabs-wrapper .question-type {
          display: flex;
          width: 35%;
          height: 32px; }
          .additional-wrapper .outcome-wrapper .feedback-form .tabs-wrapper .question-type .green-tab {
            background-color: #13cece;
            width: 50%;
            display: grid;
            place-items: center;
            color: white;
            cursor: pointer;
            border: 1px solid #023349; }
          .additional-wrapper .outcome-wrapper .feedback-form .tabs-wrapper .question-type .white-tab {
            background-color: white;
            width: 50%;
            display: grid;
            place-items: center;
            cursor: pointer;
            border: 1px solid #023349; }
          .additional-wrapper .outcome-wrapper .feedback-form .tabs-wrapper .question-type .tab {
            width: 50%; }
      .additional-wrapper .outcome-wrapper .feedback-form .datetime-dropdown {
        margin: 18px 0px; }
      .additional-wrapper .outcome-wrapper .feedback-form .text-validation {
        margin-top: 15px;
        display: flex; }
  .additional-wrapper .form-group {
    padding-left: 5%; }
  .additional-wrapper .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%; }
    .additional-wrapper .arrow:hover {
      cursor: pointer; }
    .additional-wrapper .arrow .back {
      font-size: 17px; }
    .additional-wrapper .arrow img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%; }
  .additional-wrapper .btn-wrapper {
    display: flex;
    padding-left: 5%; }
  .additional-wrapper .bonus-pay-btn {
    background-color: white;
    color: #023349;
    text-decoration: underline;
    border: none;
    margin-left: 90%;
    margin-bottom: 2%; }
  .additional-wrapper .caller-stats-card {
    display: flex;
    width: 100%;
    text-decoration: none;
    margin-bottom: 2%;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08); }
    .additional-wrapper .caller-stats-card:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white; }
    .additional-wrapper .caller-stats-card .card-one {
      width: 25%;
      height: 137px;
      border-right: 1px solid #cccccc;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center; }
      .additional-wrapper .caller-stats-card .card-one .label {
        font-size: 14px;
        color: black; }
      .additional-wrapper .caller-stats-card .card-one .numbers {
        color: #13cece;
        font-size: 39px; }
      .additional-wrapper .caller-stats-card .card-one .pay-cta-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-evenly; }
      .additional-wrapper .caller-stats-card .card-one .cta {
        color: #13cece;
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
        font-weight: 600; }
      .additional-wrapper .caller-stats-card .card-one .caller-action {
        display: flex;
        gap: 1em;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600; }
        .additional-wrapper .caller-stats-card .card-one .caller-action .cta {
          color: #13cece;
          text-decoration: underline; }
        .additional-wrapper .caller-stats-card .card-one .caller-action .divider {
          width: 2px;
          background: #13cece; }
        .additional-wrapper .caller-stats-card .card-one .caller-action .ban {
          color: #DD5858;
          text-decoration: underline; }
  .additional-wrapper .upload {
    background-color: #e9e9e9;
    width: 100%;
    border: 1px dashed gray !important;
    padding: 2% 2% 3% 3%;
    display: flex;
    align-items: baseline;
    min-height: 68px; }
  .additional-wrapper .heading {
    font-size: 22px;
    padding: 1%; }
    .additional-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .additional-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%; }
  .additional-wrapper .csv {
    background-color: #f0faf5;
    padding: 2%;
    margin-bottom: 4%; }
  .additional-wrapper ._2iA8p44d0WZ-WqRBGcAuEV {
    border: none; }
    .additional-wrapper ._2iA8p44d0WZ-WqRBGcAuEV input {
      border: 1px dashed grey !important;
      width: 24%;
      background-color: #e3e3e3; }
  .additional-wrapper ._3vLmCG3bB3CM2hhAiQX1tN {
    width: 25%; }
  .additional-wrapper .compliance-table-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .additional-wrapper .audit-view-btn {
    border: none;
    color: #13cece !important;
    text-decoration: underline;
    font-weight: bolder; }
  .additional-wrapper .underline-cta-wrapper {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin: 1%; }
    .additional-wrapper .underline-cta-wrapper img {
      width: 18px; }
      .additional-wrapper .underline-cta-wrapper img:hover {
        cursor: pointer; }
    .additional-wrapper .underline-cta-wrapper .underline-cta {
      color: #13cece;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      word-spacing: 2px;
      letter-spacing: 0.5px;
      margin: 0px 10px; }

#options-in p {
  font-size: 12px;
  padding-left: 80%;
  margin: 0px 14px 10px -5px; }

.option-wrapper-2 {
  border: 1px dashed #c7c4c4;
  border-top: none;
  padding: 1%; }
  .option-wrapper-2 label {
    padding-bottom: 0;
    margin-right: 1%; }
  .option-wrapper-2 .label {
    display: flex;
    align-items: baseline; }
  .option-wrapper-2 ._32r8xHW3WLH5mygPuyDIBF {
    color: white;
    display: none; }
  .option-wrapper-2 ._2iA8p44d0WZ-WqRBGcAuEV input {
    display: none; }
  .option-wrapper-2 ._3vLmCG3bB3CM2hhAiQX1tN {
    width: auto;
    margin-left: 91%; }

.progressbar-container {
  background-color: #e0e0e0; }

#progress-card {
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9; }
  #progress-card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white; }

.progressbar-progress {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .progressbar-progress .percentage {
    display: none; }
  .progressbar-progress:hover {
    background-color: #098f55 !important; }
    .progressbar-progress:hover .percentage {
      display: block;
      color: white; }

#no-shadow {
  box-shadow: none; }

#total-card {
  height: 180px; }

#stat-card-first-row {
  margin-top: -21px; }
  #stat-card-first-row h4 {
    color: black;
    font-size: 18px; }
  #stat-card-first-row h5 {
    color: black; }

.total-calls-card {
  display: flex;
  width: 100%;
  height: 100%; }
  .total-calls-card .one-card {
    width: 34%;
    padding: 0% 4% 0% 4%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-right: 1px solid #dddddd;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9; }
    .total-calls-card .one-card:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white; }
    .total-calls-card .one-card .top-stat {
      display: flex;
      justify-content: space-between; }

.call-stats {
  display: flex;
  width: 100%;
  min-height: 264px; }
  .call-stats .stat-card {
    width: 50%;
    background-color: #e7f7f0;
    padding: 2% 2% 1% 2%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9; }
    .call-stats .stat-card:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc; }
    .call-stats .stat-card .indentation {
      margin-left: 3.5%; }
    .call-stats .stat-card .stat-card-row {
      display: flex;
      justify-content: space-between;
      margin: 1%;
      padding: 1%;
      text-transform: capitalize; }
      .call-stats .stat-card .stat-card-row h5 {
        font-size: 17px;
        color: #11d17e; }
      .call-stats .stat-card .stat-card-row h4 {
        color: #11d17e;
        display: flex;
        font-size: 15px; }
        .call-stats .stat-card .stat-card-row h4 img {
          width: 11%;
          margin-left: 5%; }
      .call-stats .stat-card .stat-card-row p {
        color: grey;
        font-size: 12px; }
  .call-stats #grey-card {
    background-color: #f0f0f0;
    margin-left: 5%; }
    .call-stats #grey-card h4 {
      color: black; }
    .call-stats #grey-card h5 {
      color: black; }

.progressbar-section {
  padding: 0% 2% 0% 2%; }
  .progressbar-section h3 {
    padding-bottom: 2%;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    padding-top: 2%; }
  .progressbar-section .progress {
    height: 42px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #e0e0e0;
    border-radius: 4px; }
  .progressbar-section .progress-bar-completed-calls {
    background-color: #159b72; }
  .progressbar-section .progress-bar-attempted-calls {
    background-color: #8adddd; }
  .progressbar-section .progress-bar-unattempted-calls {
    background-color: #e0e0e0; }
  .progressbar-section .in-progress {
    display: flex;
    width: 100%;
    margin-left: -2%; }
    .progressbar-section .in-progress .cell-box {
      display: flex;
      width: 50%;
      padding: 2%;
      line-height: 2.8rem; }
      .progressbar-section .in-progress .cell-box .text {
        display: flex;
        align-items: baseline;
        font-size: 16px;
        width: 100%; }
        .progressbar-section .in-progress .cell-box .text p {
          padding: 1% 0% 0% 2%;
          font-size: 15px; }
    .progressbar-section .in-progress .box-in {
      width: 30px;
      height: 30px;
      background-color: #159b72;
      margin-right: 3%; }

#remaining {
  background-color: #e0e0e0; }

#attempted-calls {
  background-color: #8adddd; }

.wrapper-tring-stats {
  display: flex;
  flex-flow: column;
  padding: 4%; }
  .wrapper-tring-stats .CTA {
    display: flex;
    justify-content: flex-end;
    margin: -2% 0% 0% 0%; }
    .wrapper-tring-stats .CTA .tring-btn-1 {
      padding: 10px 12px;
      border-radius: 5px;
      background-color: inherit;
      color: #023349;
      font-weight: bolder;
      padding: 10px 12px;
      pointer-events: none; }
    .wrapper-tring-stats .CTA .tring-btn-2 {
      margin: 4%;
      background-color: inherit;
      color: #13cece;
      font-weight: bolder;
      padding: 10px 12px;
      padding: 10px 12px;
      border-radius: 5px;
      pointer-events: none; }
  .wrapper-tring-stats .heading {
    display: flex;
    align-items: baseline; }
    .wrapper-tring-stats .heading img {
      height: 20px;
      margin-right: 1%; }
  .wrapper-tring-stats .section {
    min-height: 186px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    margin-bottom: 3%;
    border-radius: 6px;
    transition: all 0.3s; }
    .wrapper-tring-stats .section .caller-wrapper-1 {
      column-count: 1;
      column-gap: 40px;
      padding: 2% 4% 3% 4%;
      margin-top: 3%; }
    .wrapper-tring-stats .section .caller-wrapper {
      column-count: 2;
      column-gap: 40px;
      padding: 2% 4% 3% 4%;
      margin-top: 3%; }

.project-detail-card {
  display: flex;
  width: 100%;
  min-height: 186px;
  padding: 2%;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9; }
  .project-detail-card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white; }
  .project-detail-card .logo {
    width: 10%;
    display: flex;
    justify-content: center;
    padding: 1%; }
    .project-detail-card .logo .round-logo {
      max-height: 65px;
      max-width: 65px;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
      margin-right: 4%;
      border-radius: 50%;
      text-align: center; }
      .project-detail-card .logo .round-logo img {
        width: 100%;
        padding: 17%; }
  .project-detail-card .details {
    width: 90%;
    display: flex; }
    .project-detail-card .details .part-one {
      width: 50%;
      padding: 0% 5% 0% 0%;
      display: flex;
      flex-flow: column;
      align-items: flex-start; }
      .project-detail-card .details .part-one .heading {
        display: flex; }
      .project-detail-card .details .part-one img {
        height: 20px;
        margin-right: 1%; }
      .project-detail-card .details .part-one h3 {
        font-size: 18px; }

#darkTrueTools {
  backdrop-filter: invert(1) hue-rotate(180deg); }

#darkFalseTools {
  filter: invert(0%) hue-rotate(0); }

#create-card {
  border-style: dashed;
  box-shadow: none; }

#outcome {
  box-shadow: none !important; }

.DateInput {
  margin-right: 12px !important; }

#status-select {
  margin-left: 33%; }

.wrapper-tring-stats {
  display: flex;
  flex-flow: column;
  padding: 7% 4% 4% 4%; }
  .wrapper-tring-stats .CTA {
    display: flex;
    justify-content: flex-end;
    margin: -2% 0% 0% 0%; }
  .wrapper-tring-stats .tring-btn-1 {
    padding: 10px 12px;
    border-radius: 5px;
    background-color: inherit;
    color: #023349;
    font-weight: bolder;
    padding: 10px 12px;
    pointer-events: none; }
  .wrapper-tring-stats .tring-btn-2 {
    margin: 4%;
    background-color: inherit;
    color: #13cece;
    font-weight: bolder;
    padding: 10px 12px;
    padding: 10px 12px;
    border-radius: 5px;
    pointer-events: none; }
  .wrapper-tring-stats .heading {
    display: flex;
    align-items: baseline; }
    .wrapper-tring-stats .heading img {
      height: 20px;
      margin-right: 1%; }
  .wrapper-tring-stats .section {
    min-height: 186px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    margin-bottom: 3%;
    border-radius: 6px;
    transition: all 0.3s; }

#brandmanager {
  text-align: left; }

.Dv7FLAST-3bZceA5TIxEN {
  font-size: 17px !important; }

._3vt7_Mh4hRCFbp__dFqBCI li {
  font-size: 17px !important; }

#container-1 {
  padding-left: 5%; }

.exotel-details {
  display: flex;
  gap: 15px; }
  .exotel-details div {
    width: 50%; }

.switch-grid {
  display: grid;
  grid-template-columns: repeat(5, 17%);
  grid-template-rows: 1fr;
  justify-items: start;
  grid-gap: 0.5em; }

.notif-popup {
  display: flex;
  flex-flow: column;
  row-gap: 10px; }
  .notif-popup .heading {
    font-size: 17px;
    color: #01293f;
    font-weight: 900; }
  .notif-popup .sub-heading {
    font-size: 16px; }
  .notif-popup .text {
    font-size: 14px;
    color: #01293f;
    margin-top: 3%; }
  .notif-popup .ok-btn {
    background-color: #023349;
    color: white;
    width: 70px;
    margin-top: 5%; }
  .notif-popup .checkbox {
    box-shadow: none !important; }
  .notif-popup .delete-btn {
    background-color: #C60000 !important;
    color: white;
    font-size: 15px;
    font-weight: 800px;
    padding: 5px 15px;
    margin-top: 20px; }
    .notif-popup .delete-btn:hover {
      scale: 1.2; }

.singleLineInput {
  box-shadow: none !important;
  border-bottom: 1px dashed #000 !important;
  border-radius: 0 !important;
  width: 50%; }
  .singleLineInput:hover, .singleLineInput:focus {
    box-shadow: none !important; }

.shareModalBtnSection {
  display: flex;
  justify-content: space-around; }

.form-wrapper {
  padding: 5% 5% 1% 5%; }
  .form-wrapper .form-error-msg {
    font-size: 12px;
    color: red;
    display: flex; }
  .form-wrapper .faq-field {
    display: flex; }
    .form-wrapper .faq-field img {
      width: 20px; }
  .form-wrapper ._2iA8p44d0WZ-WqRBGcAuEV {
    border: none; }
    .form-wrapper ._2iA8p44d0WZ-WqRBGcAuEV input {
      width: 24%;
      font-size: 17px;
      display: block; }
  .form-wrapper ._3vLmCG3bB3CM2hhAiQX1tN {
    width: 25%; }
  .form-wrapper .form-group label {
    padding-bottom: 0;
    padding-top: 3%; }
  .form-wrapper .heading {
    font-size: 22px;
    padding: 1%; }
    .form-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .form-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%; }
  .form-wrapper .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%; }
    .form-wrapper .arrow:hover {
      cursor: pointer; }
    .form-wrapper .arrow .back {
      font-size: 17px; }
    .form-wrapper .arrow img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%; }
  .form-wrapper .cta-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline; }
  .form-wrapper #delete {
    color: red;
    background-color: inherit;
    border: none; }
  .form-wrapper .btn-wrapper {
    display: flex;
    justify-content: flex-end; }
    .form-wrapper .btn-wrapper .add-btn {
      border: none;
      color: #023349;
      background-color: inherit; }
  .form-wrapper .outcomes {
    background-color: #f0faf5;
    padding: 7%;
    max-height: 483px;
    overflow-y: auto; }
  .form-wrapper .faq-field {
    margin-top: 4%; }
    .form-wrapper .faq-field img {
      width: 20px;
      margin-left: 2%; }
  .form-wrapper .resources {
    background-color: #efefef;
    border: 1px dashed;
    padding: 7%;
    max-height: 483px;
    overflow-y: auto; }
    .form-wrapper .resources .script-field-wrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      width: 100%; }
      .form-wrapper .resources .script-field-wrapper .script-field {
        display: flex;
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .form-wrapper .resources .script-field-wrapper .script-field .each {
          padding-right: 2%;
          width: 50%; }
  .form-wrapper .grey-box-wrapper .script-field-wrapper {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 100%; }
    .form-wrapper .grey-box-wrapper .script-field-wrapper .script-field {
      display: flex;
      display: flex;
      width: 100%;
      justify-content: space-between; }
      .form-wrapper .grey-box-wrapper .script-field-wrapper .script-field .each {
        padding-right: 2%;
        width: 50%; }
  .form-wrapper .outcome-t {
    border-bottom: 1px solid black !important;
    margin: 5% 0% 6% 0%;
    background-color: inherit;
    border-radius: 0px !important; }
  .form-wrapper .outcome-d {
    min-height: 121px; }
  .form-wrapper .project_date_selection .DateInput {
    margin-right: 0 !important; }
  .form-wrapper .selected-brand-managers {
    margin: 1% 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em; }
  .form-wrapper .del-brand-manger {
    cursor: pointer;
    font-size: 14px;
    color: var(--color-red); }
  .form-wrapper .brand-manager {
    font-size: 16px; }
  .form-wrapper .brand-manager-row {
    display: flex;
    align-items: center;
    gap: 0.2em;
    padding: 0.2em 0; }
  .form-wrapper .nested-menu {
    display: grid;
    color: #555;
    border-radius: 4px !important;
    font-size: 14px;
    box-shadow: 0 0 4px 0 #A5ADBA !important;
    -webkit-box-shadow: 0 0 4px 0 #A5ADBA !important; }
    .form-wrapper .nested-menu .truncate-label {
      width: 218px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .form-wrapper .nested-menu .option {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between; }
  .form-wrapper .nested-menu-button {
    background-color: transparent !important;
    color: inherit;
    justify-content: flex-start !important; }

.stats-wrapper {
  padding: 10%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start; }
  .stats-wrapper .section {
    width: 50%; }
  .stats-wrapper .outcomes {
    background-color: #f0faf5;
    padding: 4% !important; }
  .stats-wrapper .each-stat {
    display: flex;
    width: 90%;
    align-items: baseline;
    flex-flow: column;
    padding: 4% 0% 0% 0%; }
    .stats-wrapper .each-stat h3 {
      font-size: 17px; }
    .stats-wrapper .each-stat h4 {
      font-size: 24px;
      color: #023349; }

.react-toggle--checked .react-toggle-track {
  background-color: #11d17e !important; }

.arrow-out {
  display: flex;
  align-items: center;
  margin-top: 6%;
  /* margin-left: -6%; */
  margin-bottom: -78px;
  margin-left: 6%; }
  .arrow-out:hover {
    cursor: pointer; }
  .arrow-out img {
    width: 28px;
    /* padding: 1%; */
    margin-right: 2%; }

.assign-wrapper {
  margin-top: 6%;
  padding: 2% 3% 0% 6%;
  margin-bottom: -4%;
  display: flex;
  width: 100%;
  align-items: baseline; }
  .assign-wrapper .assign-box {
    width: 90%; }
  .assign-wrapper .assign-btn {
    padding: 0.7rem;
    width: 10%;
    color: white;
    margin-left: -10%;
    border-radius: 5px;
    background-color: #01293f; }

#status-inprogress {
  height: 25px;
  width: 25px;
  background-color: #f0f01c;
  border-radius: 50%;
  display: inline-block; }

#status-completed {
  height: 25px;
  width: 25px;
  background-color: #11d17e;
  border-radius: 50%;
  display: inline-block; }

#status-quit {
  height: 25px;
  width: 25px;
  background-color: #e26464;
  border-radius: 50%;
  display: inline-block; }

#status-onhold {
  height: 25px;
  width: 25px;
  background-color: #838dea;
  border-radius: 50%;
  display: inline-block; }

#status-training {
  height: 25px;
  width: 25px;
  background-color: #8adddd;
  border-radius: 50%;
  display: inline-block; }

#caller-status {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0% 8%;
  cursor: pointer; }
  #caller-status .default-status-caller {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 1.5%; }
  #caller-status h4 {
    width: 80%;
    transition: 0.3s; }
    #caller-status h4:hover {
      color: #13cece; }

#callers-working {
  max-height: 350px;
  overflow-y: auto; }

#no-caller {
  min-height: max-content;
  padding: 3%; }
  #no-caller p {
    color: grey;
    font-size: 17px;
    font-weight: bolder; }

.caller-stats-wrapper {
  width: 100%; }
  .caller-stats-wrapper .wrap-in {
    display: flex; }
  .caller-stats-wrapper .section {
    width: 50%; }
  .caller-stats-wrapper .outcomes {
    background-color: #f0faf5;
    padding: 7%;
    max-height: 356px;
    overflow-y: auto; }
  .caller-stats-wrapper .each-stat {
    display: flex;
    flex-flow: column;
    /* width: 100%; */
    align-items: baseline; }
    .caller-stats-wrapper .each-stat h3 {
      font-size: 18px; }
    .caller-stats-wrapper .each-stat h4 {
      font-weight: 600 !important;
      color: #023349;
      margin-bottom: 12%; }
  .caller-stats-wrapper .caller-stats {
    justify-content: flex-start;
    display: flex; }
    .caller-stats-wrapper .caller-stats .default-status-caller {
      display: inline-block;
      margin: 0.5%; }
    .caller-stats-wrapper .caller-stats h4 {
      margin-right: 2%;
      font-weight: 600 !important;
      color: #023349;
      margin-bottom: 12%; }

.tele-wrapper {
  padding: 5%;
  display: flex;
  width: 100%;
  flex-wrap: wrap; }
  .tele-wrapper .arrow {
    display: flex;
    align-items: center; }
    .tele-wrapper .arrow:hover {
      cursor: pointer; }
    .tele-wrapper .arrow img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%; }
  .tele-wrapper .tele-project-card {
    max-height: 221px;
    min-height: 183px;
    min-width: 350px;
    max-width: 360px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 3%;
    display: flex;
    flex-flow: column;
    padding: 2%;
    justify-content: space-between; }
    .tele-wrapper .tele-project-card h3 {
      font-size: 15px; }
    .tele-wrapper .tele-project-card .row2 {
      display: flex;
      justify-content: space-between; }
    .tele-wrapper .tele-project-card .row1 {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .tele-wrapper .tele-project-card .row1 p {
        font-size: 11px;
        color: grey; }
      .tele-wrapper .tele-project-card .row1 .update {
        color: #17bcbc;
        width: 27%; }
        .tele-wrapper .tele-project-card .row1 .update img {
          width: 35px;
          padding: 6%; }
      .tele-wrapper .tele-project-card .row1 .btn1 {
        border: 1px solid black;
        padding: 2%;
        border-radius: 3px; }
      .tele-wrapper .tele-project-card .row1 .btn2 {
        padding: 2%;
        background-color: #17bcbc;
        border-radius: 3px;
        color: white; }
    .tele-wrapper .tele-project-card:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer; }

#new-btn-2-quiz {
  margin-left: 2%;
  background-color: #17bcbc; }

.link-quiz-wrapper {
  padding: 8% 0% 4% 6%; }
  .link-quiz-wrapper .arrow {
    display: flex;
    align-items: center;
    margin-right: 1%; }
    .link-quiz-wrapper .arrow:hover {
      cursor: pointer; }
    .link-quiz-wrapper .arrow .back {
      font-size: 17px; }
    .link-quiz-wrapper .arrow img {
      width: 28px;
      /* padding: 1%; */
      margin-right: 2%; }
  .link-quiz-wrapper label {
    padding-bottom: 0% !important; }
  .link-quiz-wrapper input {
    width: 46% !important; }
  .link-quiz-wrapper .quiz-search {
    margin-top: 2%; }
    .link-quiz-wrapper .quiz-search h3 {
      font-size: 19px;
      text-align: left;
      font-weight: 600 !important; }
  .link-quiz-wrapper form {
    margin-left: 5%; }
  .link-quiz-wrapper .linked {
    font-size: 14px;
    font-weight: 600;
    color: #023349;
    display: flex;
    align-items: baseline;
    background-color: #f0faf5;
    padding: 3% 0% 3% 2%;
    width: 46%;
    margin-left: 5%; }
    .link-quiz-wrapper .linked h5 {
      margin-right: 2%;
      text-decoration: UNDERLINE; }
  .link-quiz-wrapper .heading {
    font-size: 22px; }
    .link-quiz-wrapper .heading .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%; }
      .link-quiz-wrapper .heading .arrow:hover {
        cursor: pointer; }
      .link-quiz-wrapper .heading .arrow img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%; }
    .link-quiz-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .link-quiz-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%; }
  .link-quiz-wrapper .btn-wrapper {
    display: flex; }

.assign-caller-wrapper {
  padding: 5%; }
  .assign-caller-wrapper .btn-wrapper {
    display: flex;
    align-items: baseline;
    padding-left: 5%; }
    .assign-caller-wrapper .btn-wrapper .submit {
      background-color: #023349; }
  .assign-caller-wrapper .heading-new {
    font-size: 17px;
    font-weight: 900;
    padding-left: 5%; }
  .assign-caller-wrapper .note {
    color: grey;
    font-size: 12px;
    font-weight: 900;
    margin: 2% 4% 3% 0%; }
  .assign-caller-wrapper .each-form {
    padding-left: 5%; }
    .assign-caller-wrapper .each-form .heading {
      font-size: 17px;
      font-weight: 900; }
  .assign-caller-wrapper .form-group .form-control {
    margin: 0% 5% 0% 5%;
    width: -webkit-fill-available;
    height: 225px !important; }
  .assign-caller-wrapper .heading {
    font-size: 22px;
    margin-bottom: 3%; }
    .assign-caller-wrapper .heading .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%; }
      .assign-caller-wrapper .heading .arrow:hover {
        cursor: pointer; }
      .assign-caller-wrapper .heading .arrow .back {
        font-size: 17px; }
      .assign-caller-wrapper .heading .arrow img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%; }
    .assign-caller-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .assign-caller-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center; }

.qc-form-wrapper {
  padding: 5%; }
  .qc-form-wrapper .btn-wrapper {
    display: flex;
    align-items: baseline;
    padding-left: 5%; }
    .qc-form-wrapper .btn-wrapper .submit {
      background-color: #023349; }
  .qc-form-wrapper .heading-new {
    font-size: 17px;
    font-weight: 900;
    padding-left: 5%; }
  .qc-form-wrapper .note {
    color: grey;
    font-size: 12px;
    font-weight: 900;
    margin: 2% 4% 3% 0%; }
  .qc-form-wrapper #mht {
    width: fit-content;
    margin-top: 3%;
    background-color: aliceblue;
    margin: 3% 4% 2% 0%;
    padding: 2% 3% 2% 2%; }
  .qc-form-wrapper .each-form {
    padding-left: 5%; }
    .qc-form-wrapper .each-form .heading {
      font-size: 17px;
      font-weight: 900; }
  .qc-form-wrapper .pill-wrapper {
    display: flex;
    flex-flow: column;
    padding-left: 5%; }
    .qc-form-wrapper .pill-wrapper .pills {
      display: flex;
      flex-wrap: wrap; }
    .qc-form-wrapper .pill-wrapper .mht-name {
      font-size: 13px;
      margin-bottom: 6%; }
    .qc-form-wrapper .pill-wrapper .pill-empty {
      background-color: white;
      border: 1px solid #023349;
      color: #023349;
      width: fit-content;
      /* width: 40px; */
      padding: 1%;
      max-width: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      margin-right: 2%; }
      .qc-form-wrapper .pill-wrapper .pill-empty img {
        width: 20px;
        margin: 1%; }
    .qc-form-wrapper .pill-wrapper .pill {
      background-color: #023349;
      color: white;
      width: fit-content;
      /* width: 40px; */
      padding: 1%;
      max-width: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      margin-right: 2%; }
      .qc-form-wrapper .pill-wrapper .pill img {
        width: 20px;
        margin: 1%; }
  .qc-form-wrapper .heading {
    font-size: 22px;
    margin-bottom: 3%; }
    .qc-form-wrapper .heading .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%; }
      .qc-form-wrapper .heading .arrow:hover {
        cursor: pointer; }
      .qc-form-wrapper .heading .arrow .back {
        font-size: 17px; }
      .qc-form-wrapper .heading .arrow img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%; }
    .qc-form-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .qc-form-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center; }

.mock-form-wrapper {
  padding: 5%; }
  .mock-form-wrapper .each-form {
    display: flex;
    width: 100%;
    border-left: 3px solid #13cece;
    border-radius: 4px;
    align-items: center; }
    .mock-form-wrapper .each-form input {
      width: 50%; }
  .mock-form-wrapper .dropdown-wrapper {
    width: 50%;
    margin-left: 3%;
    display: flex;
    align-items: baseline; }
  .mock-form-wrapper .add-question {
    color: #13cece;
    border: none; }
  .mock-form-wrapper .delete-question {
    color: #13cece;
    border: none; }
  .mock-form-wrapper .heading {
    font-size: 22px; }
    .mock-form-wrapper .heading .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%; }
      .mock-form-wrapper .heading .arrow:hover {
        cursor: pointer; }
      .mock-form-wrapper .heading .arrow img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%; }
    .mock-form-wrapper .heading .back {
      font-size: 17px; }
    .mock-form-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .mock-form-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 1%; }
  .mock-form-wrapper .btn-wrapper {
    display: flex;
    align-items: baseline; }
    .mock-form-wrapper .btn-wrapper .submit {
      background-color: #023349; }

.sms-template-wrapper {
  padding: 5%; }
  .sms-template-wrapper .heading {
    font-size: 22px; }
    .sms-template-wrapper .heading .arrow {
      display: flex;
      align-items: center;
      margin-right: 1%; }
      .sms-template-wrapper .heading .arrow:hover {
        cursor: pointer; }
      .sms-template-wrapper .heading .arrow img {
        width: 28px;
        /* padding: 1%; */
        margin-right: 2%; }
    .sms-template-wrapper .heading .back {
      font-size: 17px; }
    .sms-template-wrapper .heading h4 {
      text-align: center;
      font-size: 19px;
      font-weight: bold !important; }
    .sms-template-wrapper .heading h5 {
      text-align: center;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 4%; }
  .sms-template-wrapper .tabs-wrapper {
    margin: 0 0 2.5% 2%; }
  .sms-template-wrapper .btn-wrapper {
    display: flex;
    align-items: baseline; }
    .sms-template-wrapper .btn-wrapper .submit {
      background-color: #023349; }
  .sms-template-wrapper .template-form-wrapper {
    display: flex;
    min-height: 82vh;
    width: 100%; }
    .sms-template-wrapper .template-form-wrapper .divider {
      width: 1px;
      background-color: #000000; }
    .sms-template-wrapper .template-form-wrapper .heading {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4%; }
    .sms-template-wrapper .template-form-wrapper .dropdown-toggle {
      width: 150px;
      display: flex !important;
      align-items: center;
      justify-content: space-between; }
    .sms-template-wrapper .template-form-wrapper .template-form {
      width: 50%;
      padding: 1% 2%;
      max-height: 80vh;
      overflow-y: auto;
      margin: 0 0.5% 0 0; }
      .sms-template-wrapper .template-form-wrapper .template-form::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .sms-template-wrapper .template-form-wrapper .template-form::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5; }
      .sms-template-wrapper .template-form-wrapper .template-form::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
      .sms-template-wrapper .template-form-wrapper .template-form .ids-section {
        display: flex;
        gap: 2rem; }
        .sms-template-wrapper .template-form-wrapper .template-form .ids-section .form-group {
          width: 50%; }
    .sms-template-wrapper .template-form-wrapper .linking-template {
      width: 50%;
      padding: 1% 2%;
      margin: 0 0 0 0.5%;
      max-height: 80vh;
      overflow-y: auto; }
      .sms-template-wrapper .template-form-wrapper .linking-template::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .sms-template-wrapper .template-form-wrapper .linking-template::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5; }
      .sms-template-wrapper .template-form-wrapper .linking-template::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
      .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row {
        display: flex;
        gap: 5px;
        margin-bottom: 2%; }
        .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row div {
          width: 50%; }
          .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row div span {
            margin-right: 5%; }
        .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .delete-icon {
          width: 18px; }
          .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .delete-icon:hover {
            cursor: pointer; }
        .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .option-selected {
          background-color: #17BCBC; }
        .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .disable-option {
          opacity: 0.5;
          pointer-events: none; }
        .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .dropdown-menu {
          max-height: 150px;
          overflow-y: auto; }
          .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .dropdown-menu::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5; }
          .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .dropdown-menu::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5; }
          .sms-template-wrapper .template-form-wrapper .linking-template .outcome-template-row .dropdown-menu::-webkit-scrollbar-thumb {
            background-color: #8e8b8b;
            border: 2px solid #b6b4b4;
            border-radius: 6px; }
    .sms-template-wrapper .template-form-wrapper .add-cta {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      color: #17BCBC; }
      .sms-template-wrapper .template-form-wrapper .add-cta.disappear {
        visibility: hidden; }
      .sms-template-wrapper .template-form-wrapper .add-cta.flex-end {
        justify-content: flex-end; }
      .sms-template-wrapper .template-form-wrapper .add-cta.f-sz-14 {
        font-size: 14px; }
      .sms-template-wrapper .template-form-wrapper .add-cta.disappear {
        visibility: hidden; }
      .sms-template-wrapper .template-form-wrapper .add-cta.underline {
        text-decoration: underline; }
      .sms-template-wrapper .template-form-wrapper .add-cta:hover {
        cursor: pointer; }
    .sms-template-wrapper .template-form-wrapper .add-variable {
      padding: 9px 0;
      text-transform: uppercase;
      border: none;
      text-decoration: underline;
      font-weight: bold !important;
      font-size: 14px;
      margin-bottom: 0.5em; }
    .sms-template-wrapper .template-form-wrapper .doc-link {
      color: var(--color-topaz); }
    .sms-template-wrapper .template-form-wrapper .notify {
      font-size: 12px;
      background-color: var(--color-yellow);
      font-weight: bold;
      padding: 0.7em;
      margin-bottom: 1em; }
  .sms-template-wrapper .templates_wrapper .template_type_select_row {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2.5rem 0; }
  .sms-template-wrapper .templates_wrapper .template_btn {
    min-width: 200px;
    padding: 0.5em;
    font-size: 16px;
    font-weight: 600 !important;
    border: 1px solid #555555; }
    .sms-template-wrapper .templates_wrapper .template_btn.default {
      color: #555555; }
    .sms-template-wrapper .templates_wrapper .template_btn.active {
      color: var(--color-white);
      background-color: var(--color-royal-blue);
      border: 1px solid var(--color-royal-blue); }
  .sms-template-wrapper .templates_wrapper .template_container {
    border: 2px solid var(--color-grey-80);
    border-radius: 12px;
    min-height: 50vh;
    width: 60vw;
    margin: 2rem auto; }
    .sms-template-wrapper .templates_wrapper .template_container .outcome_link_label {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
      align-items: center; }
      .sms-template-wrapper .templates_wrapper .template_container .outcome_link_label label {
        font-size: 14px;
        color: var(--color-eerie-black);
        font-weight: 600;
        margin-bottom: 0; }
    .sms-template-wrapper .templates_wrapper .template_container .helper_icon {
      width: 18px;
      cursor: pointer; }
    .sms-template-wrapper .templates_wrapper .template_container .terms_condition {
      display: flex;
      align-items: baseline; }
      .sms-template-wrapper .templates_wrapper .template_container .terms_condition .comment-form-cookies-consent {
        display: flex; }
        .sms-template-wrapper .templates_wrapper .template_container .terms_condition .comment-form-cookies-consent input {
          min-width: 20px !important; }
        .sms-template-wrapper .templates_wrapper .template_container .terms_condition .comment-form-cookies-consent label {
          margin-bottom: 0 !important; }
      .sms-template-wrapper .templates_wrapper .template_container .terms_condition input[type=checkbox] {
        position: relative;
        border: 2px solid var(--color-grey-80) !important;
        border-radius: 2px !important;
        background: none;
        cursor: pointer;
        line-height: 0;
        margin: 0 .6em 0 0;
        outline: 0 !important;
        padding: 0 !important;
        vertical-align: text-top;
        height: 20px !important;
        width: 20px !important;
        -webkit-appearance: none;
        opacity: .5; }
      .sms-template-wrapper .templates_wrapper .template_container .terms_condition input[type=checkbox]:hover {
        opacity: 1; }
      .sms-template-wrapper .templates_wrapper .template_container .terms_condition input[type=checkbox]:checked {
        background-color: var(--color-dark-green);
        opacity: 1; }
      .sms-template-wrapper .templates_wrapper .template_container .terms_condition input[type=checkbox]:before {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        width: 4px;
        height: 10px;
        border: solid #FFF;
        border-width: 0 2px 2px 0;
        margin: -1px -1px 0 -1px;
        transform: rotate(45deg) translate(-50%, -50%);
        z-index: 2; }
    .sms-template-wrapper .templates_wrapper .template_container .cta_headers {
      font-weight: 800;
      margin: 0 0.5em;
      cursor: pointer;
      color: var(--color-royal-blue);
      font-size: 16px; }
  .sms-template-wrapper .templates_wrapper .template_form {
    padding: 0 2em 2em; }
    .sms-template-wrapper .templates_wrapper .template_form .variables_row {
      display: flex;
      gap: 2rem;
      width: 100%; }
      .sms-template-wrapper .templates_wrapper .template_form .variables_row :global(img) {
        width: 35px;
        margin-top: 10px;
        cursor: pointer; }
      .sms-template-wrapper .templates_wrapper .template_form .variables_row .input_fields {
        flex: 1;
        display: inherit;
        gap: inherit; }
        .sms-template-wrapper .templates_wrapper .template_form .variables_row .input_fields :global(div) {
          flex: 5; }
    .sms-template-wrapper .templates_wrapper .template_form .addvar_cta {
      color: var(--color-royal-blue);
      font-weight: 800 !important;
      font-size: 14px;
      cursor: pointer;
      margin: 0.5em 0;
      letter-spacing: 0.05em; }
    .sms-template-wrapper .templates_wrapper .template_form .save_template_wrapper {
      text-align: center; }
    .sms-template-wrapper .templates_wrapper .template_form .error {
      color: var(--color-red); }
    .sms-template-wrapper .templates_wrapper .template_form .alert_message {
      background-color: var(--color-yellow);
      padding: 0.5em;
      border-radius: 6px; }
  .sms-template-wrapper .templates_wrapper .footer_cta {
    letter-spacing: 0.05em;
    background-color: var(--color-grey-80);
    width: 100%;
    padding: 1em;
    border-radius: 0 0 12px;
    text-align: center;
    font-weight: 800;
    cursor: pointer; }
  .sms-template-wrapper .templates_wrapper .action_row {
    display: flex;
    justify-content: flex-end;
    padding: 1em 1em 0; }
  .sms-template-wrapper .templates_wrapper .template-active-row {
    display: flex;
    gap: 2em;
    align-items: center;
    margin: 1.5rem 0; }
    .sms-template-wrapper .templates_wrapper .template-active-row label {
      font-size: 14px;
      color: var(--color-eerie-black);
      font-weight: 600;
      margin-bottom: 0; }
    .sms-template-wrapper .templates_wrapper .template-active-row.disabled {
      pointer-events: none;
      opacity: .3; }
  .sms-template-wrapper .templates_wrapper .loader_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vh; }
  .sms-template-wrapper .name-value-row {
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    align-items: center;
    position: relative;
    margin-bottom: 2%; }
    .sms-template-wrapper .name-value-row .label-value {
      display: flex;
      grid-gap: 3%;
      gap: 3%;
      align-items: center;
      width: 45% !important;
      align-items: baseline; }
      .sms-template-wrapper .name-value-row .label-value .label-bold {
        font-weight: 600;
        font-size: 13px;
        width: 30%; }
        .sms-template-wrapper .name-value-row .label-value .label-bold.w-10 {
          width: 10% !important; }
      .sms-template-wrapper .name-value-row .label-value.w-70 {
        width: 70% !important; }

#quiz-add-qs {
  border: 1px solid #023349;
  padding: 1%;
  color: black;
  font-weight: 900; }

#quiz-name {
  width: 50%; }

#correctoption label {
  margin-top: -8%; }

#delete-option {
  color: red;
  margin-left: 5px;
  height: 30px;
  width: 60px;
  padding: 0px; }

#add-qs-ar {
  margin-left: 2%;
  font-size: 12px; }

#del-qs-ar {
  margin-left: 87%;
  padding: 0;
  font-size: 12px;
  color: red;
  margin-top: -5%;
  text-decoration: underline; }

.delete-dc-question {
  font-size: 12px;
  color: red;
  text-decoration: underline; }

.new-quiz-wrapper {
  padding: 8%; }
  .new-quiz-wrapper .question-wrapper {
    margin-bottom: 6%; }
    .new-quiz-wrapper .question-wrapper .delete-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: baseline; }
  .new-quiz-wrapper .fields {
    width: 80%; }
  .new-quiz-wrapper .questions-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 3% 18% 3% 7%; }
    .new-quiz-wrapper .questions-wrapper .radios input {
      box-shadow: none !important; }
    .new-quiz-wrapper .questions-wrapper .radios .radio-label {
      font-size: 12px; }
    .new-quiz-wrapper .questions-wrapper .options-wrapper {
      background-color: #f0faf5;
      padding: 2% 0% 1% 5%; }
      .new-quiz-wrapper .questions-wrapper .options-wrapper .delete-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: baseline; }
      .new-quiz-wrapper .questions-wrapper .options-wrapper .option-text {
        display: flex;
        align-items: baseline;
        margin-left: -4%;
        width: 100%; }
        .new-quiz-wrapper .questions-wrapper .options-wrapper .option-text label {
          margin-right: 2%; }
    .new-quiz-wrapper .questions-wrapper .radios .question-type {
      display: flex;
      width: 35%; }
      .new-quiz-wrapper .questions-wrapper .radios .question-type .green-tab {
        background-color: #13cece;
        width: 50%;
        padding: 3% 3% 4% 4%;
        color: white;
        cursor: pointer;
        border: 1px solid #023349; }
      .new-quiz-wrapper .questions-wrapper .radios .question-type .white-tab {
        background-color: white;
        width: 50%;
        padding: 3% 3% 4% 4%;
        cursor: pointer;
        border: 1px solid #023349; }
      .new-quiz-wrapper .questions-wrapper .radios .question-type .tab {
        width: 50%; }
  .new-quiz-wrapper .category {
    display: flex;
    justify-content: space-between;
    padding: 3% 18% 3% 7%; }
    .new-quiz-wrapper .category .Dropdown-root {
      width: 50% !important;
      border: 1px solid #cccccc;
      border-radius: 4px; }
      .new-quiz-wrapper .category .Dropdown-root .Dropdown-control {
        padding: 6px 6px 6px 10px !important; }
  .new-quiz-wrapper .heading {
    font-size: 27px;
    font-weight: 900; }
  .new-quiz-wrapper .quiz-question-text {
    width: 100%;
    display: flex;
    margin-bottom: -1%;
    margin-left: -4%; }
    .new-quiz-wrapper .quiz-question-text label {
      margin-right: 2%; }

.tools {
  min-height: 100vh; }
  .tools .darkmode {
    display: flex;
    align-items: end; }
  .tools button:disabled {
    cursor: progress !important; }
  .tools .questions {
    display: flex; }
  .tools .edit-quiz-btn {
    color: #13cece;
    border: none; }
  .tools .quiz-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    width: 100%; }
    .tools .quiz-wrapper .data-card {
      padding: 15px;
      background-color: white;
      margin: 15px;
      border: 1px solid #17bcbc;
      border-radius: 6px;
      width: calc(33% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: pointer;
      position: relative; }
      .tools .quiz-wrapper .data-card:hover {
        background-color: #13cece;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }
      .tools .quiz-wrapper .data-card .name {
        font-size: 18px; }
      .tools .quiz-wrapper .data-card .stats > div {
        margin-top: 20px; }
        .tools .quiz-wrapper .data-card .stats > div span:first-child {
          font-size: 22px; }
        .tools .quiz-wrapper .data-card .stats > div span:last-child {
          color: #7A869A;
          font-size: 16px; }
      .tools .quiz-wrapper .data-card .cta {
        opacity: 0;
        transition: all 0.35s ease;
        color: white;
        position: absolute;
        font-size: 30px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(64, 106, 248, 0.9);
        border-radius: 8px; }
      .tools .quiz-wrapper .data-card:hover .cta {
        opacity: 1; }
  .tools .quiz-listing {
    padding: 6% 3% 3% 3%; }
  .tools .container .mission-report > h3 {
    text-transform: capitalize; }
  .tools .container .mission-search .inputs-wrapper {
    display: flex;
    align-items: center; }
    .tools .container .mission-search .inputs-wrapper .form-group {
      margin-bottom: 0;
      width: 100%; }
    .tools .container .mission-search .inputs-wrapper > span {
      margin: 0 20px; }
    .tools .container .mission-search .inputs-wrapper > input {
      width: 40%; }
  .tools .container .instagram-report input[type="file"] {
    margin-top: 10px;
    box-shadow: none !important; }
  .tools .container .mission-application-rejector #tools-tab .tab-content {
    padding-top: 50px; }
  .tools .container .mission-application-rejector .flag-inputs label {
    background-color: white;
    padding: 10px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid white; }
    .tools .container .mission-application-rejector .flag-inputs label img {
      width: 100px; }
  .tools .container .mission-application-rejector .flag-inputs label.checked {
    border: 1px solid #406AF8; }
  .tools .container .mission-application-rejector .list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -25px; }
    .tools .container .mission-application-rejector .list .data-card {
      padding: 15px;
      background-color: white;
      margin: 15px;
      border-radius: 8px;
      width: calc(33% - 30px);
      cursor: pointer;
      position: relative; }
      .tools .container .mission-application-rejector .list .data-card .name {
        font-size: 18px; }
      .tools .container .mission-application-rejector .list .data-card .stats > div {
        margin-top: 20px; }
        .tools .container .mission-application-rejector .list .data-card .stats > div span:first-child {
          font-size: 22px; }
        .tools .container .mission-application-rejector .list .data-card .stats > div span:last-child {
          color: #7A869A;
          font-size: 16px; }
      .tools .container .mission-application-rejector .list .data-card .cta {
        opacity: 0;
        transition: all 0.35s ease;
        color: white;
        position: absolute;
        font-size: 30px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(64, 106, 248, 0.9);
        border-radius: 8px; }
      .tools .container .mission-application-rejector .list .data-card:hover .cta {
        opacity: 1; }
  .tools .container .tips-wrapper {
    background-color: #11d17e;
    color: white;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px; }
    .tools .container .tips-wrapper h4 {
      margin-top: 0; }
  .tools .container .cta-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 22%; }
    .tools .container .cta-wrapper .submit:disabled:hover {
      background-color: #6374d3 !important; }
  .tools .container .tool-input {
    margin-bottom: 30px; }
  .tools .container .featuring-tool .payment-tab {
    border: solid 1px #b2bedc;
    width: 100%;
    background-color: #b2bedc;
    border-radius: 4px;
    padding: 20px;
    box-shadow: -2px 6px 38px -20px rgba(0, 0, 0, 0.75);
    transition: ease 0.5s;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tools .container .featuring-tool .payment-tab img {
      width: 30px;
      height: 100px;
      margin-right: 20px; }
  .tools .container .featuring-tool .inputs-wrapper {
    display: flex; }
    .tools .container .featuring-tool .inputs-wrapper > div {
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      min-width: 231px;
      height: 10em;
      justify-content: center; }
    .tools .container .featuring-tool .inputs-wrapper button {
      display: block !important; }
    .tools .container .featuring-tool .inputs-wrapper label {
      display: block;
      text-align: right;
      margin-bottom: 0px !important;
      min-width: max-content;
      color: black; }
  .tools .container .featuring-tool .search-input-wrapper {
    width: 40%; }
  .tools .container .featuring-tool .search-input-wrapper.form-group {
    align-items: center;
    width: 40%; }
  .tools .container .featuring-tool .radio {
    margin-top: 5px;
    margin-bottom: 5px; }
  .tools .container .featuring-tool input[type="radio"] {
    background-color: white;
    display: inline-block;
    width: 15px;
    height: 30px !important;
    border: 1px solid #CECFCF;
    border-radius: 100% !important;
    margin-right: 10px; }
  .tools .container .featuring-tool input[type="radio"]:checked {
    background-color: #406AF8;
    padding: 5px; }
  .tools .container .featuring-tool .submit {
    color: white;
    background-color: #6374d3;
    padding: 5px 20px;
    border-radius: 4px;
    border: none;
    font-size: 16px; }
  .tools .container .mission-targetting .category-list {
    display: flex;
    flex-wrap: wrap; }
    .tools .container .mission-targetting .category-list .disabled-color {
      opacity: 0.7;
      background-color: lightgray !important; }
    .tools .container .mission-targetting .category-list .add-category {
      min-height: 180px;
      width: 32%;
      border: 5px dotted gray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5%;
      color: gray;
      font-size: 2.5em;
      cursor: pointer; }
    .tools .container .mission-targetting .category-list .mission-category-card {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 32%;
      margin: 0.5%;
      justify-content: space-between;
      background-color: white;
      min-height: 180px;
      -webkit-box-shadow: 0px 0px 19px -10px #363336;
      -moz-box-shadow: 0px 0px 19px -10px #363336;
      box-shadow: 0px 0px 19px -10px #363336; }
      .tools .container .mission-targetting .category-list .mission-category-card .title-div {
        display: flex;
        align-items: center; }
        .tools .container .mission-targetting .category-list .mission-category-card .title-div img {
          width: 8%;
          margin-right: 10px; }
        .tools .container .mission-targetting .category-list .mission-category-card .title-div span {
          font-size: 1.1em;
          font-weight: bolder;
          color: #424c4c; }
      .tools .container .mission-targetting .category-list .mission-category-card .description {
        font-size: 0.9em;
        padding: 5px 0;
        color: #6d6d6d;
        letter-spacing: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap; }
      .tools .container .mission-targetting .category-list .mission-category-card .edit-button {
        color: blue;
        font-size: 1.1em;
        padding: 10px 10px 0 0;
        cursor: pointer; }
        .tools .container .mission-targetting .category-list .mission-category-card .edit-button:hover {
          color: darkblue; }
      .tools .container .mission-targetting .category-list .mission-category-card .platforms-div {
        display: flex;
        align-content: center;
        font-size: 0.8em;
        color: lightslategrey;
        text-transform: uppercase; }
  .tools .container .mission-targetting .privacy-check .control-label {
    color: black;
    width: 100%;
    display: block; }
  .tools .container .mission-targetting .privacy-check input {
    height: auto !important;
    margin-right: 10px;
    box-shadow: none !important; }
  .tools .container .mission-targetting .input-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap; }
    .tools .container .mission-targetting .input-list > div {
      flex: 1 0 calc(50% - 50px); }
    .tools .container .mission-targetting .input-list .table {
      border-radius: 0; }
      .tools .container .mission-targetting .input-list .table th {
        color: #6d3ca3;
        padding: 10px;
        border-bottom: 1px solid #6d3ca3; }
      .tools .container .mission-targetting .input-list .table th,
      .tools .container .mission-targetting .input-list .table td {
        padding: 10px 25px; }
    .tools .container .mission-targetting .input-list .color-blue {
      background: #793562 !important; }
    .tools .container .mission-targetting .input-list .card {
      padding: 20px;
      max-width: 50%;
      margin: 20px 20px 20px 0;
      background-color: #6d3ca3;
      -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
      transition: all 1s ease-in-out; }
      .tools .container .mission-targetting .input-list .card .control-label {
        color: white; }
      .tools .container .mission-targetting .input-list .card .status label {
        color: white; }
      .tools .container .mission-targetting .input-list .card .form-group {
        margin-bottom: 15px; }
      .tools .container .mission-targetting .input-list .card .status input {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        margin-top: -4px !important; }
        .tools .container .mission-targetting .input-list .card .status input:hover {
          box-shadow: none;
          -webkit-box-shadow: none; }
    .tools .container .mission-targetting .input-list .college-input-wrapper {
      flex: 0 1 100%; }
      .tools .container .mission-targetting .input-list .college-input-wrapper .or {
        text-align: center;
        margin-bottom: 10px; }
    .tools .container .mission-targetting .input-list .gender-input-wrapper {
      margin-right: 50px; }
    .tools .container .mission-targetting .input-list .discussion-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .tools .container .mission-targetting .input-list .discussion-container h4 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 4%; }
      .tools .container .mission-targetting .input-list .discussion-container .variable-pay {
        display: flex;
        justify-content: space-between;
        flex-flow: column;
        width: 100%; }
    .tools .container .mission-targetting .input-list .extra-margin {
      margin-bottom: 100px; }
    .tools .container .mission-targetting .input-list label.input-header {
      font-size: 24px;
      border-bottom: 1px solid #CECFCF;
      width: 100%;
      margin-bottom: 20px;
      color: black; }
  .tools .container .college-list,
  .tools .container .city-list {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 10px; }
    .tools .container .college-list .college-item,
    .tools .container .city-list .college-item {
      margin-right: 10px;
      margin-bottom: 8px;
      padding: 5px 20px;
      border-radius: 20px;
      background-color: #406af8;
      color: white;
      box-shadow: 0px 0px 14px 5px rgba(64, 106, 248, 0.22);
      display: flex;
      align-items: center; }
      .tools .container .college-list .college-item img,
      .tools .container .city-list .college-item img {
        cursor: pointer;
        width: 10px;
        margin-left: 10px; }
  .tools .wildcard-tool .list {
    display: flex;
    flex-wrap: wrap; }
    .tools .wildcard-tool .list .wildcard-wrapper {
      background-color: white;
      border-radius: 4px;
      margin-right: 20px;
      margin-bottom: 20px; }
      .tools .wildcard-tool .list .wildcard-wrapper .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-top: 1px solid #406AF8; }
        .tools .wildcard-tool .list .wildcard-wrapper .actions .edit {
          cursor: pointer; }
        .tools .wildcard-tool .list .wildcard-wrapper .actions > span {
          display: inline-block;
          height: 100%; }
    .tools .wildcard-tool .list .wildcard {
      height: 120px; }
      .tools .wildcard-tool .list .wildcard img {
        width: 345px;
        max-height: 120px; }
    .tools .wildcard-tool .list .wildcard.blur {
      opacity: 0.2;
      cursor: not-allowed; }
    .tools .wildcard-tool .list .add-button {
      border: 2px dashed #CECFCF;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      width: 345px;
      font-size: 30px;
      color: #7A869A;
      cursor: pointer; }
  .tools .mission-unflag-tool .flag-history {
    display: flex;
    flex-wrap: wrap; }
  .tools .mission-unflag-tool .flag-card {
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2); }
    .tools .mission-unflag-tool .flag-card .mission-name {
      font-size: 18px;
      margin-bottom: 10px; }
    .tools .mission-unflag-tool .flag-card .brand-name {
      font-size: 16px;
      color: #7A869A;
      margin-bottom: 10px; }
    .tools .mission-unflag-tool .flag-card .flag-reason {
      font-style: italic; }
    .tools .mission-unflag-tool .flag-card .flag-action {
      margin-top: 20px; }
      .tools .mission-unflag-tool .flag-card .flag-action .flag-icon {
        width: 90px;
        height: 40px; }
      .tools .mission-unflag-tool .flag-card .flag-action .flag-icon.red {
        background-color: red; }
      .tools .mission-unflag-tool .flag-card .flag-action .flag-icon.yellow {
        background-color: yellow; }
      .tools .mission-unflag-tool .flag-card .flag-action button {
        background-color: #406AF8;
        color: white;
        border: none;
        padding: 8px 5px; }
      .tools .mission-unflag-tool .flag-card .flag-action button.red {
        background-color: red; }
      .tools .mission-unflag-tool .flag-card .flag-action button.yellow {
        background-color: yellow;
        color: black; }

.wildcard-form-modal .modal-body form {
  padding: 20px 40px; }
  .wildcard-form-modal .modal-body form .college-list,
  .wildcard-form-modal .modal-body form .city-list {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 10px; }
    .wildcard-form-modal .modal-body form .college-list .college-item,
    .wildcard-form-modal .modal-body form .city-list .college-item {
      margin-right: 10px;
      margin-bottom: 8px;
      padding: 5px 20px;
      border-radius: 20px;
      background-color: #406af8;
      color: white;
      box-shadow: 0px 0px 14px 5px rgba(64, 106, 248, 0.22);
      display: flex;
      align-items: center; }
      .wildcard-form-modal .modal-body form .college-list .college-item img,
      .wildcard-form-modal .modal-body form .city-list .college-item img {
        cursor: pointer;
        width: 10px;
        margin-left: 10px; }

.mission-category-form-modal form {
  padding: 20px 50px; }

.bulk-moderate-success-modal .modal-body .close-btn,
.data-success-screen-modal .modal-body .close-btn {
  display: none; }

.bulk-moderate-success-modal .modal-body h1,
.data-success-screen-modal .modal-body h1 {
  padding-top: 30px; }

.bulk-moderate-success-modal .modal-body .graphic-wrapper,
.data-success-screen-modal .modal-body .graphic-wrapper {
  text-align: center;
  margin: 80px 0; }
  .bulk-moderate-success-modal .modal-body .graphic-wrapper img,
  .data-success-screen-modal .modal-body .graphic-wrapper img {
    width: 500px; }

.bulk-moderate-success-modal .modal-body .warning-box,
.data-success-screen-modal .modal-body .warning-box {
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px; }

.bulk-moderate-success-modal .modal-body .info-text,
.data-success-screen-modal .modal-body .info-text {
  text-align: center; }

.gateway-modal #choose {
  flex-flow: column;
  align-items: center; }

.gateway-modal #choose-btn {
  margin-left: 0;
  margin-bottom: 2%;
  margin: 16px; }

.gateway-modal .notif {
  display: flex;
  justify-content: center;
  padding: 2%;
  font-size: 19px; }
  .gateway-modal .notif .wrapper {
    display: flex;
    flex-flow: row;
    padding: 0; }
  .gateway-modal .notif img {
    width: 60px; }

.gateway-modal .okbtn {
  margin-left: 46%; }

.account-wrapper {
  display: flex;
  flex-flow: column; }
  .account-wrapper .search-user-role {
    padding: 2%;
    margin-bottom: 2%; }
  .account-wrapper .user-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 5rem;
    padding-top: 2%; }
    .account-wrapper .user-grid-wrapper .card {
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 4px;
      background-color: white;
      height: 125px;
      width: 330px; }
      .account-wrapper .user-grid-wrapper .card .top-section {
        display: flex;
        width: 100%; }
        .account-wrapper .user-grid-wrapper .card .top-section .name {
          width: 85%;
          font-weight: 600;
          font-size: 16px; }
        .account-wrapper .user-grid-wrapper .card .top-section .option {
          width: 15%;
          display: flex;
          gap: 5px;
          align-items: start; }
          .account-wrapper .user-grid-wrapper .card .top-section .option img {
            width: 15px; }
            .account-wrapper .user-grid-wrapper .card .top-section .option img:hover {
              cursor: pointer; }
      .account-wrapper .user-grid-wrapper .card .main-section {
        color: #888888;
        font-size: 14px;
        margin-top: 5%;
        word-break: break-all; }
      .account-wrapper .user-grid-wrapper .card .details {
        width: 88%; }
      .account-wrapper .user-grid-wrapper .card .control {
        width: 12%; }
        .account-wrapper .user-grid-wrapper .card .control img {
          width: 15px; }

.exotel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 6%; }
  .exotel-container .title {
    width: 100%;
    padding-left: 7%; }
  .exotel-container .exotel-acc-wrapper h3 {
    font-weight: 600 !important;
    font-size: 18px;
    color: #454647;
    margin: 0;
    text-align: left;
    margin-bottom: 3%; }
  .exotel-container .exotel-acc-wrapper .label-num-row {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 4%; }
    .exotel-container .exotel-acc-wrapper .label-num-row .first-name {
      width: 400px; }
    .exotel-container .exotel-acc-wrapper .label-num-row .first-phone {
      width: 20%; }
    .exotel-container .exotel-acc-wrapper .label-num-row .del-icon img {
      height: 22px;
      color: red;
      transition: transform 0.2s; }
      .exotel-container .exotel-acc-wrapper .label-num-row .del-icon img:hover {
        transform: scale(1.5);
        cursor: pointer; }
  .exotel-container .exotel-acc-wrapper .form-group .form-control:disabled {
    background-color: #ddd8d8; }
  .exotel-container .exotel-acc-wrapper .account-setting {
    width: 80vw;
    overflow-y: auto; }
    .exotel-container .exotel-acc-wrapper .account-setting::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .exotel-container .exotel-acc-wrapper .account-setting::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .exotel-container .exotel-acc-wrapper .account-setting::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
  .exotel-container .exotel-acc-wrapper .opa-0 {
    opacity: 0.5; }
  .exotel-container .exotel-acc-wrapper .opa-1 {
    opacity: 1; }
  .exotel-container .exotel-acc-wrapper .cta-section {
    display: flex;
    flex-flow: row;
    justify-content: flex-start !important;
    gap: 2%;
    align-items: baseline; }
  .exotel-container .exotel-acc-wrapper .refresh-task-count {
    width: fit-content;
    display: flex;
    gap: 1%;
    margin-bottom: 2%; }
    .exotel-container .exotel-acc-wrapper .refresh-task-count:hover {
      cursor: pointer; }
    .exotel-container .exotel-acc-wrapper .refresh-task-count img {
      width: 15px; }
  .exotel-container .exotel-acc-wrapper .crud-form {
    width: 63%; }

.exotel-linked-projects {
  max-height: 50vh;
  overflow-y: scroll; }
  .exotel-linked-projects .project-list-name {
    font-size: 16px;
    font-weight: 300; }

.delete-warning {
  font-size: 16px;
  font-weight: 600; }

.dashboard-gateway .list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px; }

.dashboard-gateway input {
  width: 100%;
  padding: 2%; }

.dashboard-gateway .checkbox-gateway {
  display: block;
  position: relative;
  padding-left: 2%;
  margin-top: 2%;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .dashboard-gateway .checkbox-gateway input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .dashboard-gateway .checkbox-gateway .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eeeeee;
    border: 1px solid #a29b9b; }
  .dashboard-gateway .checkbox-gateway input:checked ~ .checkmark {
    background-color: #2196f3; }
  .dashboard-gateway .checkbox-gateway .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .dashboard-gateway .checkbox-gateway input:checked ~ .checkmark:after {
    display: block; }
  .dashboard-gateway .checkbox-gateway .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.dashboard-gateway .wrap-card {
  display: flex;
  /* flex-flow: column; */
  flex-flow: wrap; }
  .dashboard-gateway .wrap-card .card {
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 4px;
    background-color: white;
    flex-basis: calc(33% - 25px);
    margin-right: 25px;
    margin-bottom: 25px;
    cursor: pointer; }
    .dashboard-gateway .wrap-card .card .name {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dashboard-gateway .wrap-card .card .name img {
        width: 78px;
        height: 78px;
        border-radius: 50%; }
    .dashboard-gateway .wrap-card .card .bm-details {
      margin-top: 10px; }
      .dashboard-gateway .wrap-card .card .bm-details span:first-child {
        font-size: 16px; }
      .dashboard-gateway .wrap-card .card .bm-details span:last-child {
        color: #7A869A;
        margin-left: 2px; }
    .dashboard-gateway .wrap-card .card .moderation {
      background-color: white;
      width: 100%;
      min-height: fit-content;
      padding: 5px;
      font-size: smaller;
      color: #616065;
      margin-top: 2%;
      justify-content: center;
      display: flex; }

.festival-tool .list {
  display: flex; }
  .festival-tool .list .item {
    position: relative;
    flex-basis: calc(33% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 8px;
    padding: 15px; }
    .festival-tool .list .item .title {
      font-size: 18px; }
    .festival-tool .list .item .description {
      font-size: 16px;
      color: #7A869A; }
    .festival-tool .list .item img {
      position: absolute;
      cursor: pointer;
      right: 10px; }
    .festival-tool .list .item img.delete {
      top: 10px; }
    .festival-tool .list .item img.edit {
      bottom: 10px; }
    .festival-tool .list .item #delete {
      display: block;
      text-align: right;
      /* padding-right: 11rem; */
      margin-right: 47%;
      margin-bottom: 0px !important;
      font-size: 1.4rem; }

.warning-box {
  color: #FF5630; }

.scriptPreview {
  height: 80vh;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  background-color: #023147;
  margin: auto;
  color: #f0f0f0;
  font-size: 15px;
  padding: 1% 1%;
  font-weight: 600;
  box-shadow: 0px 8px 19px -2px black;
  border-radius: 6px;
  opacity: 1; }
  .scriptPreview .content {
    flex: 1 1;
    position: relative; }
  .scriptPreview .live-action-wrapper {
    display: flex;
    flex-direction: column; }
  .scriptPreview .live-action-btn {
    background: linear-gradient(270deg, #E5953E 0%, #B46DB0 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 3px #F5ECF480, 2px 2px 2px #000D17;
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 800 !important; }
    .scriptPreview .live-action-btn img {
      width: 22px; }
  .scriptPreview .divider {
    margin: 3% 0;
    border-top: 2px solid #196486;
    width: 100%; }
  .scriptPreview .navigation-footer {
    font-size: 14px;
    color: #17bcbc; }
    .scriptPreview .navigation-footer:hover {
      cursor: pointer; }
  .scriptPreview .header {
    background-color: #001b30;
    padding: 3%;
    display: inline-flex;
    vertical-align: middle;
    gap: 1rem;
    box-shadow: none; }
    .scriptPreview .header .back {
      width: 5%; }
    .scriptPreview .header .title {
      width: 75%; }
      .scriptPreview .header .title.center {
        text-align: center; }
    .scriptPreview .header .options {
      width: 20%;
      display: inline-flex;
      gap: 5px;
      justify-content: flex-end; }
  .scriptPreview .para {
    padding: 5% 8%;
    overflow-y: auto;
    flex: 1;
    text-align: left;
    max-height: 180px;
    min-height: 180px;
    margin-bottom: 7%; }
    .scriptPreview .para::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .scriptPreview .para::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .scriptPreview .para::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .scriptPreview .para .highlight {
      color: var(--color-american-orange);
      font-weight: 800; }
    .scriptPreview .para p {
      white-space: pre-wrap; }
    .scriptPreview .para .callerCue {
      overflow: hidden;
      text-align: center;
      color: #17bcbc;
      font-style: italic;
      font-weight: 600; }
      .scriptPreview .para .callerCue::before, .scriptPreview .para .callerCue::after {
        background-color: #17bcbc;
        content: "";
        display: inline-block;
        height: 3px;
        position: relative;
        vertical-align: middle;
        width: 50%; }
      .scriptPreview .para .callerCue::before {
        right: 0.5em;
        margin-left: -50%; }
      .scriptPreview .para .callerCue::after {
        left: 0.5em;
        margin-right: -50%; }
  .scriptPreview .faq-search {
    color: #FFFF;
    width: 100%;
    border-radius: 6px;
    padding: 1% 2%;
    background-color: #001B30;
    font-size: 14px;
    border: none;
    margin-bottom: 1.5em; }
  .scriptPreview .clear-icon {
    position: absolute;
    top: 1.8em;
    right: 1.2em;
    width: 10px;
    cursor: pointer; }
  .scriptPreview .faq-wrapper {
    padding: 5% 2%;
    overflow-y: auto;
    max-height: 60vh;
    flex: 1 1;
    margin-bottom: 7%;
    font-weight: 600; }
    .scriptPreview .faq-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .scriptPreview .faq-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .scriptPreview .faq-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .scriptPreview .faq-wrapper #answer {
      white-space: pre-line; }
    .scriptPreview .faq-wrapper .panel-group .panel-body {
      border: none;
      background-color: #001b30; }
    .scriptPreview .faq-wrapper .panel-group .panel + .panel {
      margin-top: 2px; }
    .scriptPreview .faq-wrapper .panel {
      background-color: none;
      border: none; }
    .scriptPreview .faq-wrapper .panel-default .panel-heading {
      background-color: #023749;
      color: #FFFFFF; }
      .scriptPreview .faq-wrapper .panel-default .panel-heading .panel-title a:hover {
        color: #FFFFFF;
        text-decoration: none; }
      .scriptPreview .faq-wrapper .panel-default .panel-heading .panel-title a:focus {
        text-decoration: none;
        outline: none; }
  .scriptPreview .formQuestions {
    padding-bottom: 1em; }
    .scriptPreview .formQuestions .multi-select-option {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      max-height: 200px;
      overflow-y: auto; }
      .scriptPreview .formQuestions .multi-select-option .option {
        background-color: #001B30;
        color: white;
        padding: 0.5em;
        border-radius: 6px; }
        .scriptPreview .formQuestions .multi-select-option .option label {
          padding: 0 0 0 3.5%;
          display: flex;
          align-items: center;
          margin-bottom: 0; }
          .scriptPreview .formQuestions .multi-select-option .option label input[type='checkbox'] {
            margin-right: 4%; }
  .scriptPreview .outcomes-wrapper {
    padding: 8% 2% 0 2%;
    overflow-y: auto;
    flex: 1;
    max-height: 200px;
    min-height: 160px; }
    .scriptPreview .outcomes-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .scriptPreview .outcomes-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .scriptPreview .outcomes-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .scriptPreview .outcomes-wrapper .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around; }
  .scriptPreview .navigator-wrapper {
    padding: 2% 3%;
    overflow-y: auto;
    flex: 1 1;
    max-height: 405px;
    min-height: 405px; }
    .scriptPreview .navigator-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .scriptPreview .navigator-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .scriptPreview .navigator-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .scriptPreview .navigator-wrapper .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around; }
  .scriptPreview .navigator-wrapper-disconnect {
    padding: 2% 3%;
    overflow-y: auto;
    flex: 1 1;
    max-height: 150px;
    min-height: 150px; }
    .scriptPreview .navigator-wrapper-disconnect::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .scriptPreview .navigator-wrapper-disconnect::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .scriptPreview .navigator-wrapper-disconnect::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .scriptPreview .navigator-wrapper-disconnect .outcome {
      display: flex;
      flex-direction: column;
      justify-content: space-around; }
  .scriptPreview .call-disconnected-option {
    position: absolute;
    width: 100%;
    display: block;
    z-index: 999;
    top: 70%;
    height: 30%;
    background-color: #001b30;
    padding: 1% 1%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; }
  .scriptPreview .call-disconnected {
    padding: 2% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 2%; }
    .scriptPreview .call-disconnected .submit {
      margin-top: 5px; }
    .scriptPreview .call-disconnected span {
      text-align: center;
      padding-top: 2%; }
    .scriptPreview .call-disconnected .disconnected-option-button {
      background-color: #023147 !important; }
    .scriptPreview .call-disconnected .grey-text {
      color: #88a2b7;
      font-size: 12px; }
    .scriptPreview .call-disconnected .red-button {
      color: var(--color-red);
      margin-top: 5px; }
      .scriptPreview .call-disconnected .red-button:hover, .scriptPreview .call-disconnected .red-button:focus {
        color: var(--color-red); }
  .scriptPreview .filter__control {
    border-radius: 5px;
    box-shadow: 0px 0px 3px #888888; }
  .scriptPreview .filter__placeholder {
    color: #888888;
    font-size: 14px; }
  .scriptPreview .filter__menu {
    margin: 0.125rem auto;
    color: rgba(51, 51, 51, 0.8); }
  .scriptPreview .filter__option {
    background-color: white;
    padding-top: 1%; }
    .scriptPreview .filter__option--is-focused {
      background-color: #f2f9fc;
      color: #333; }
  .scriptPreview .filter__group {
    padding: 0; }
  .scriptPreview .filter__menu-portal {
    border: 1px solid darkblue; }
  .scriptPreview .optional {
    font-style: italic;
    color: #17BCBC; }

.audio_control_visited {
  opacity: 0.6; }

.react-switch {
  vertical-align: middle;
  margin-left: 4px; }

.icons_info {
  width: 19px;
  margin-left: 6px;
  cursor: pointer; }

.mytooltip > .tooltip-inner {
  max-width: 80%;
  white-space: normal; }
  @media (max-width: 1300px) {
    .mytooltip > .tooltip-inner {
      max-width: 67%; } }
  @media (max-width: 1100px) {
    .mytooltip > .tooltip-inner {
      max-width: 57%; } }

.audit-view-btn {
  border: none;
  color: #13cece !important;
  text-decoration: underline;
  font-weight: bolder; }

.loader-dots {
  color: red;
  letter-spacing: 1px; }
  .loader-dots:after {
    content: "....";
    width: 0;
    position: absolute;
    overflow: hidden;
    animation: loader-dots-animation 1s infinite; }

@keyframes loader-dots-animation {
  0% {
    width: 0em; }
  50% {
    width: 1.2em; }
  100% {
    width: 0em; } }

ul {
  list-style: none;
  counter-reset: css-counter 0; }

.events li {
  display: flex;
  color: #ffffff; }

.events.qc_history {
  padding: 0; }
  .events.qc_history time::after {
    right: -7rem; }
    .events.qc_history time::after img {
      width: 20px; }
  .events.qc_history .table {
    border: 2px solid #CCCCCC !important; }

.events time {
  position: relative;
  counter-increment: css-counter 1; }

.events time::after {
  content: attr(data-after-content);
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  color: #454647;
  background: #ffffff;
  font-size: 15px;
  text-align: center;
  padding: 8%;
  width: 200px;
  height: 1.5em; }

.events span {
  padding: 2em 1.5em 1.5em 3em;
  position: relative; }

.events li:last-child {
  padding: 0; }
  .events li:last-child span:before {
    display: none; }

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 2px #cccccc solid; }

.events span::after {
  bottom: 0; }

.events strong {
  display: block;
  font-weight: 900;
  font-size: 18px;
  color: #838dea; }

.events,
.events *::before,
.events *::after {
  box-sizing: border-box; }

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  padding: 7% 0;
  bottom: 45px; }

.events .title {
  font-size: 1.3em;
  font-weight: 800;
  color: #838dea; }

.events .desc-qc-history {
  color: #454647;
  font-size: 1em; }
  .events .desc-qc-history table {
    border: 2px dashed #CCCCCC; }
    .events .desc-qc-history table td {
      border: 2px dashed #CCCCCC;
      min-width: 200px;
      padding: 1% 3%; }
      .events .desc-qc-history table td:first-child {
        background-color: #E7FAF2; }

.events .desc {
  color: #454647;
  font-size: 1em;
  font-weight: 600;
  width: 150px;
  padding: 5%;
  border: 1px #888888 dashed;
  background-color: #dff5eb;
  text-align: center; }
  .events .desc.red-flag {
    background-color: #ffdbdb; }

.caller-history {
  display: flex;
  justify-content: center;
  align-items: center; }

.retrain-feedbacks {
  display: flex;
  flex-direction: column;
  padding: 2% 5%; }
  .retrain-feedbacks .sub-heading {
    font-size: 20px;
    border-bottom: none;
    color: #023349;
    font-weight: bold;
    margin-bottom: 3%; }
  .retrain-feedbacks .divider {
    margin: 3% 0;
    border-top: 2px solid #E5E5E5;
    width: 100%; }
  .retrain-feedbacks .feedback-for-callers {
    padding: 0% 1%; }
    .retrain-feedbacks .feedback-for-callers ul {
      padding: 0; }
  .retrain-feedbacks .feedback-for-coaches {
    padding: 0% 1%; }

.call-history-modal {
  /* Add scrollbar to modal body */ }
  .call-history-modal .modal-dialog {
    overflow-y: initial !important; }
  .call-history-modal .modal-body {
    height: 72vh;
    overflow-y: auto;
    padding: 0;
    /* Scrollbar Styling */ }
    .call-history-modal .modal-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .call-history-modal .modal-body::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .call-history-modal .modal-body::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4; }
  .call-history-modal .heading {
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    color: #023349;
    font-weight: bold; }

.script-link-wrapper {
  display: flex;
  justify-content: center;
  gap: 5em;
  border: 1px solid #17bcbc;
  max-width: 425px;
  background-color: #ffff;
  padding: 2% 3%;
  border-radius: 6px;
  margin: 0 auto; }
  .script-link-wrapper a {
    color: #406af8;
    text-decoration: none;
    font-weight: 600; }

.btn-footer {
  padding: 5% 5% 1%; }

.custom-tab-container {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  margin: 1% 0;
  gap: 10px;
  color: #17bcbc;
  font-weight: 600; }
  .custom-tab-container.aqua-green {
    color: #17bcbc; }
  .custom-tab-container.navy-blue {
    color: #888888;
    font-size: 18px;
    border-bottom: 1px solid #888888;
    gap: 2em; }
  .custom-tab-container.royal-blue {
    color: #888888; }
  .custom-tab-container.tab-space {
    gap: 5rem; }
  .custom-tab-container.big-font-size {
    font-size: 18px; }
  .custom-tab-container .custom-tab {
    min-width: 120px;
    background-color: transparent;
    text-align: center; }
    .custom-tab-container .custom-tab.navy-blue {
      min-width: 0; }
    .custom-tab-container .custom-tab.active {
      border-bottom: 3px solid #17bcbc; }
      .custom-tab-container .custom-tab.active.aqua-green {
        border-bottom: 3px solid #17bcbc; }
      .custom-tab-container .custom-tab.active.navy-blue {
        color: #023349;
        border-bottom: 3px solid #023349; }
      .custom-tab-container .custom-tab.active.royal-blue {
        color: var(--color-royal-blue);
        border-bottom: 3px solid var(--color-royal-blue); }
    .custom-tab-container .custom-tab:hover {
      cursor: pointer; }

.call_outcome_winning_outcome {
  display: flex;
  width: 100%;
  align-items: baseline; }

.call_outcome_winning_outcome_star {
  height: 14px;
  width: 15px;
  margin: 3%; }

.call-outcome {
  font-weight: 900;
  width: 100px !important;
  text-align: center; }
  .call-outcome img {
    width: 22px; }
  .call-outcome .deviation-wrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px; }
  .call-outcome.not-suspect {
    color: #11d17e; }
  .call-outcome.suspect {
    color: #dd5858; }

.exisiting-job-container {
  display: flex;
  flex-flow: column;
  padding: 5%;
  width: 100%; }

.existing-jobs-wrapper {
  display: flex;
  align-items: center;
  padding: 2% 0%;
  width: 100%;
  font-size: 15px; }
  .existing-jobs-wrapper .title {
    width: 70%;
    overflow-wrap: break-word;
    color: #454647;
    text-align: left; }
  .existing-jobs-wrapper .status {
    width: 30%;
    text-align: right;
    font-weight: 600; }
    .existing-jobs-wrapper .status.shortlisted {
      color: #838dea; }
    .existing-jobs-wrapper .status.selected {
      color: #e5953e; }

.existing-jobs-modal {
  /* Add scrollbar to modal body */ }
  .existing-jobs-modal .modal-dialog {
    overflow-y: initial !important; }
  .existing-jobs-modal .modal-body {
    height: 40vh;
    overflow-y: auto;
    padding: 0;
    /* Scrollbar Styling */ }
    .existing-jobs-modal .modal-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .existing-jobs-modal .modal-body::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .existing-jobs-modal .modal-body::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4; }
  .existing-jobs-modal .heading {
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    color: #023349;
    font-weight: bold; }

.retrain-feedback-modal .modal-body {
  overflow-y: auto;
  padding: 0; }

.feedback-modal {
  overflow-y: auto;
  padding: 0; }
  .feedback-modal .heading {
    font-size: 20px;
    margin: 0px 20px;
    border-bottom: none;
    padding: 3% 0 0;
    color: #023349;
    font-weight: bold; }
  .feedback-modal .modal-body {
    overflow-y: auto;
    padding: 0; }

body {
  overflow-y: auto !important;
  padding-right: 0px; }

.task-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding: 5% 2%;
  width: 70px;
  gap: 2px;
  border-radius: 25px;
  font-weight: 600;
  color: #ffff; }
  .task-type-wrapper img {
    width: 20px; }
  .task-type-wrapper span {
    padding-top: 3%; }
  .task-type-wrapper.normal {
    background-color: #838dea;
    box-shadow: 0px 8px 15px #0000001a;
    cursor: pointer; }
  .task-type-wrapper.apienabled {
    background-color: #e5953e;
    box-shadow: 0px 8px 15px #0000001a;
    cursor: pointer; }

.language-wrapper .lang-list {
  box-shadow: 0 0 4px 0 #a5adba !important;
  -webkit-box-shadow: 0 0 4px 0 #a5adba !important;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 125px;
  font-size: 15px;
  border: 1px solid #ccc; }

.language-wrapper .add-lang {
  display: flex;
  padding: 4% 0; }
  .language-wrapper .add-lang .form-group {
    width: 80%; }

.language-wrapper .pill-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap; }
  .language-wrapper .pill-wrapper:hover {
    cursor: pointer; }
  .language-wrapper .pill-wrapper .pill {
    background-color: #17bcbc;
    color: white;
    border-radius: 22px;
    width: max-content;
    padding: 2%;
    margin: 1%;
    display: flex;
    height: 31px;
    width: max-content;
    align-items: center;
    justify-content: center;
    gap: 6px; }
    .language-wrapper .pill-wrapper .pill .del-icon img {
      height: 13px;
      transition: transform 0.2s; }
      .language-wrapper .pill-wrapper .pill .del-icon img:hover {
        transform: scale(1.5); }
  .language-wrapper .pill-wrapper .plusicon {
    display: flex;
    justify-content: center;
    margin-left: -3%;
    padding: 2%; }
    .language-wrapper .pill-wrapper .plusicon img {
      height: 20px; }
  .language-wrapper .pill-wrapper .email-field {
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    margin-left: -1%; }

.language-field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; }
  .language-field .language-wrapper {
    display: flex;
    width: 100%;
    align-items: center; }
  .language-field .each {
    padding-right: 2%;
    width: 50%; }

.duplicate-si-input-wrapper {
  display: flex;
  width: 100%;
  padding: 0 2%;
  align-items: center;
  gap: 25px; }
  .duplicate-si-input-wrapper .label {
    width: 5%; }
  .duplicate-si-input-wrapper .field {
    width: 95%; }
    .duplicate-si-input-wrapper .field input[readonly] {
      background-color: #F8F8F8; }
  .duplicate-si-input-wrapper .label-custom {
    width: 15%;
    padding: 2% 0; }
  .duplicate-si-input-wrapper .checkbox-group {
    width: 85%;
    padding: 2% 0; }
  .duplicate-si-input-wrapper .checkboxes label {
    display: inline-block;
    padding-right: 20px;
    white-space: nowrap;
    margin-bottom: 0; }
  .duplicate-si-input-wrapper .checkboxes input {
    vertical-align: middle; }
  .duplicate-si-input-wrapper .checkboxes label span {
    vertical-align: middle; }
  .duplicate-si-input-wrapper #duplicate-script-heading {
    color: #023349;
    font-weight: 600; }

.duplicate-si-result {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 2%;
  font-size: 14px;
  background-color: #efefef;
  gap: 5px; }
  .duplicate-si-result .error {
    color: #DD5858; }
  .duplicate-si-result .success {
    color: #11D17E; }

.script-language-modal .modal-body .close-btn {
  width: 12px; }

.script-language-modal .choose-lang-btn {
  background-color: #023349;
  color: #fff;
  min-width: 19%;
  border-radius: 25px; }

.script-language-modal .script-language-modal-wrapper {
  display: flex;
  flex-flow: column;
  gap: 8px;
  max-height: 240px;
  overflow-y: auto;
  word-wrap: break-word; }
  .script-language-modal .script-language-modal-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .script-language-modal .script-language-modal-wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .script-language-modal .script-language-modal-wrapper::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .script-language-modal .script-language-modal-wrapper .heading {
    font-size: 14px;
    color: #023349;
    margin-bottom: 2%; }
  .script-language-modal .script-language-modal-wrapper .option-icon-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    max-height: 100px;
    overflow-y: auto;
    margin: 0 0 1px; }
    .script-language-modal .script-language-modal-wrapper .option-icon-wrapper img {
      width: 16px; }
  .script-language-modal .script-language-modal-wrapper input {
    border: none !important;
    background: none !important; }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:checked,
  .script-language-modal .script-language-modal-wrapper [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px; }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:checked + label,
  .script-language-modal .script-language-modal-wrapper [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #023349;
    margin: 0 0 1px; }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:checked + label:before,
  .script-language-modal .script-language-modal-wrapper [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff; }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:checked + label:after,
  .script-language-modal .script-language-modal-wrapper [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #023349;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0); }
  .script-language-modal .script-language-modal-wrapper [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  .script-language-modal .script-language-modal-wrapper .footer-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 2% 0 0 0; }
  .script-language-modal .script-language-modal-wrapper .leads-details {
    margin-top: 0.2em; }

.mock-meeting-link-modal .modal-sm {
  width: 500px; }

.mock-meeting-link-modal .DateInput_input {
  font-size: 14px; }

.mock-meeting-link-modal .rc-time-picker .rc-time-picker-panel {
  position: fixed !important; }

.mock-meeting-link-modal .rc-time-picker .rc-time-picker-panel-inner {
  position: fixed !important; }

.mock-meeting-link-modal .reset_form {
  color: #13cece;
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px; }
  .mock-meeting-link-modal .reset_form:hover {
    cursor: pointer; }

.mock-selection-modal .modal-sm {
  width: 600px; }
  .mock-selection-modal .modal-sm .bolder-text {
    color: #454647;
    font-weight: 600; }
  .mock-selection-modal .modal-sm .sub-heading {
    font-size: 16px;
    color: #01293f;
    font-weight: 900;
    padding-bottom: 5px; }
  .mock-selection-modal .modal-sm .iqc-form-wrapper {
    display: grid;
    grid-template-columns: 100%; }
    .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container {
      border: 1px solid #1E1E1E40;
      box-shadow: 0px 0px 3px #1E1E1E40;
      border-radius: 3px; }
      .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container p {
        padding: 10px 10px 0px 10px; }
      .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container h5 {
        padding-left: 10px; }
      .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse; }
        .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container table .td_text {
          width: 60%;
          padding-left: 10px;
          color: #454647;
          font-weight: 600;
          background-color: #DFF5EB; }
        .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container table td fieldset div .MuiFormControlLabel-root {
          margin: 0px;
          padding-right: 10px; }
        .mock-selection-modal .modal-sm .iqc-form-wrapper .form-container table td .Dropdown-control {
          border: 1px solid #E5E5E5 !important; }
    .mock-selection-modal .modal-sm .iqc-form-wrapper .feedback-form {
      display: flex;
      flex-direction: column;
      padding: 5px;
      height: 250px;
      overflow-y: scroll; }
      .mock-selection-modal .modal-sm .iqc-form-wrapper .feedback-form input, .mock-selection-modal .modal-sm .iqc-form-wrapper .feedback-form p {
        margin-left: 7px; }
      .mock-selection-modal .modal-sm .iqc-form-wrapper .feedback-form .MuiFormControlLabel-root {
        margin: 0px; }
        .mock-selection-modal .modal-sm .iqc-form-wrapper .feedback-form .MuiFormControlLabel-root .MuiCheckbox-root {
          padding: 0px 5px !important; }
  .mock-selection-modal .modal-sm .hiring-section {
    border: 2px solid #E5E5E5;
    padding: 1.5%;
    border-radius: 3px; }
    .mock-selection-modal .modal-sm .hiring-section .d-flex {
      display: flex;
      justify-content: space-between;
      align-items: baseline; }
    .mock-selection-modal .modal-sm .hiring-section .Dropdown-control {
      border: 1px solid #E5E5E5 !important; }
  .mock-selection-modal .modal-sm .feedback-group {
    display: flex;
    flex-direction: column; }
    .mock-selection-modal .modal-sm .feedback-group label {
      font-size: 14px;
      margin-bottom: 5px;
      display: flex;
      gap: 6px;
      align-items: center; }

.mock-selection-modal audio {
  height: 36px; }

.mock-selection-modal.modal-scrollbox .modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  /* Scrollbar Styling */ }
  .mock-selection-modal.modal-scrollbox .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .mock-selection-modal.modal-scrollbox .modal-body::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .mock-selection-modal.modal-scrollbox .modal-body::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4; }

.mock-selection-modal .divider {
  margin: 3% 0;
  border-top: 2px solid #E5E5E5;
  width: 100%; }

.mock-selection-modal .coach-feedback {
  padding: 2% 0; }

.mock-overdue {
  font-weight: 600;
  color: #BE5000; }
  .mock-overdue:hover {
    cursor: pointer; }

.mock_status {
  font-weight: 600; }
  .mock_status.overdue {
    color: #BE5000; }
  .mock_status.due {
    color: #17BCBC; }
  .mock_status.pending {
    color: #838DEA; }
  .mock_status.done {
    color: #11D17E; }

.mock-call-button:hover, .mock-record-url:hover, .icon-btn:hover {
  cursor: pointer; }

.material-table-title {
  font-weight: 600;
  color: #454647; }
  .material-table-title:hover {
    cursor: pointer; }
  .material-table-title .sub-tabs {
    display: inline-flex;
    gap: 12px; }
    .material-table-title .sub-tabs span.working.active {
      border-bottom: 3px solid #11D17E; }
    .material-table-title .sub-tabs span.looking_to_work.active {
      border-bottom: 3px solid #838DEA; }
    .material-table-title .sub-tabs span.on_a_break.active {
      border-bottom: 3px solid #023349; }
    .material-table-title .sub-tabs .filter-tag {
      color: #7A869A;
      font-size: 12px; }
  .material-table-title .divider {
    width: 1.8px;
    background: #454647; }

.heading-text {
  display: flex;
  gap: 12px;
  padding: 1% 0; }
  .heading-text img {
    width: 18px;
    height: 18px; }
    .heading-text img:hover {
      cursor: pointer; }
  .heading-text h3 {
    color: #454647;
    font-weight: 600 !important;
    font-size: 18px; }

.tele-task-wrapper > .upload-csv {
  margin: 0 2em;
  flex: 1; }

.tele-task-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  .tele-task-wrapper .dotted-border {
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }
    .tele-task-wrapper .dotted-border.bg-white {
      background-color: #FFFFFF; }
  .tele-task-wrapper .task-props {
    width: 33.33%; }
    .tele-task-wrapper .task-props .duplicate-numbers {
      margin: 2% 0;
      display: flex;
      gap: 4%;
      align-items: flex-end; }
      .tele-task-wrapper .task-props .duplicate-numbers .form-group {
        margin-bottom: 0;
        width: 80%; }
        .tele-task-wrapper .task-props .duplicate-numbers .form-group label {
          padding-bottom: 0; }
    .tele-task-wrapper .task-props .alert {
      padding: 8px 35px 8px 8px; }
    .tele-task-wrapper .task-props .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline; }
      .tele-task-wrapper .task-props .title .title-in {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 8%; }
      .tele-task-wrapper .task-props .title .btn {
        display: flex;
        flex-flow: column;
        font-size: 11px; }
        .tele-task-wrapper .task-props .title .btn .tx {
          background-color: black;
          opacity: 72%;
          color: white;
          padding: 1%;
          border-radius: 3px; }
        .tele-task-wrapper .task-props .title .btn img {
          width: 20px;
          margin-left: 12px; }
    .tele-task-wrapper .task-props .pill-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 7%; }
      .tele-task-wrapper .task-props .pill-wrapper:hover {
        cursor: pointer; }
      .tele-task-wrapper .task-props .pill-wrapper .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex;
        height: 31px;
        width: max-content; }
        .tele-task-wrapper .task-props .pill-wrapper .pill .del-icon img {
          height: 20px;
          transition: transform 0.2s; }
          .tele-task-wrapper .task-props .pill-wrapper .pill .del-icon img:hover {
            transform: scale(1.5); }
      .tele-task-wrapper .task-props .pill-wrapper .email-field {
        display: flex;
        justify-content: flex-start;
        margin-top: 2%;
        margin-left: -1%;
        padding-left: 2%; }
        .tele-task-wrapper .task-props .pill-wrapper .email-field .plusicon {
          display: flex;
          justify-content: center;
          margin-left: -3%;
          padding: 2%; }
          .tele-task-wrapper .task-props .pill-wrapper .email-field .plusicon img {
            height: 20px; }
    .tele-task-wrapper .task-props .plus-icon {
      display: flex;
      justify-content: center; }
      .tele-task-wrapper .task-props .plus-icon:hover .tooltip {
        display: block;
        cursor: pointer; }
      .tele-task-wrapper .task-props .plus-icon .tooltip {
        background-color: black;
        color: white;
        opacity: 70%;
        padding: 1%;
        margin: 2%;
        width: 8%;
        display: none; }
      .tele-task-wrapper .task-props .plus-icon img {
        width: 23px; }
        .tele-task-wrapper .task-props .plus-icon img:hover {
          cursor: pointer; }
    .tele-task-wrapper .task-props .success-msg {
      padding: 2%;
      color: #3c763d;
      border-color: #d6e9c6;
      background-color: #dff0d8;
      font-weight: 600;
      margin-top: 3%; }
    .tele-task-wrapper .task-props .failure-msg {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      padding: 2%;
      font-weight: 600;
      margin-top: 3%; }
  .tele-task-wrapper .upload-csv {
    width: 33.33%; }
    .tele-task-wrapper .upload-csv .temp-msg {
      color: #01293f;
      padding: 2%; }
      .tele-task-wrapper .upload-csv .temp-msg img {
        width: 15px;
        margin-right: 4px; }
    .tele-task-wrapper .upload-csv .teletask-csv-upload-wrapper {
      margin-bottom: 2%; }
      .tele-task-wrapper .upload-csv .teletask-csv-upload-wrapper .teletask-csv-row {
        display: flex;
        width: 100%;
        gap: 4%; }
        .tele-task-wrapper .upload-csv .teletask-csv-upload-wrapper .teletask-csv-row .dotted-border {
          width: 100%; }
    .tele-task-wrapper .upload-csv .csv-heading {
      font-weight: 600;
      padding: 1% 0; }
    .tele-task-wrapper .upload-csv .attempts-section {
      margin-bottom: 3%; }
      .tele-task-wrapper .upload-csv .attempts-section .error {
        color: red;
        font-size: 12px; }
      .tele-task-wrapper .upload-csv .attempts-section .attempts {
        display: flex;
        gap: 5%;
        align-items: center;
        margin-bottom: 0.5em; }
        .tele-task-wrapper .upload-csv .attempts-section .attempts span {
          font-weight: 600; }
        .tele-task-wrapper .upload-csv .attempts-section .attempts input {
          max-width: 25%; }
    .tele-task-wrapper .upload-csv .csv {
      background-color: #FFF8E0;
      padding: 2%;
      font-size: 13px;
      color: #000;
      margin-top: 3%;
      border-radius: 12px;
      width: 100%; }
      .tele-task-wrapper .upload-csv .csv .note {
        font-weight: 700; }
      .tele-task-wrapper .upload-csv .csv .circle {
        background-color: #FF8D8D;
        border-radius: 50%; }
      .tele-task-wrapper .upload-csv .csv .download-btn {
        width: 20px; }
      .tele-task-wrapper .upload-csv .csv .headers-table {
        display: block;
        overflow-x: auto;
        background-color: inherit; }
      .tele-task-wrapper .upload-csv .csv .headers-list {
        background-color: #FFF8E0;
        border: 1px solid #888888;
        min-width: 75px;
        width: auto;
        text-align: center; }
    .tele-task-wrapper .upload-csv .success-msg {
      padding: 2%;
      color: #3c763d;
      border-color: #d6e9c6;
      background-color: #dff0d8;
      font-weight: 600;
      margin-top: 3%; }
    .tele-task-wrapper .upload-csv .failure-msg {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      padding: 2%;
      font-weight: 600;
      margin-top: 3%; }
    .tele-task-wrapper .upload-csv .previous-records .MuiTable-root .MuiButtonBase-root .MuiIcon-root {
      font-size: 15px; }
    .tele-task-wrapper .upload-csv .previous-records .MuiTable-root .MuiTypography-root {
      font-size: 14px; }
    .tele-task-wrapper .upload-csv .previous-records .MTableHeader-header-13 {
      font-size: 14px !important; }
    .tele-task-wrapper .upload-csv .previous-records .MuiTableCell-root {
      padding: 8px; }
    .tele-task-wrapper .upload-csv .previous-records .refresh-task-count {
      display: flex;
      justify-content: flex-end;
      gap: 1%;
      margin-bottom: 2%; }
      .tele-task-wrapper .upload-csv .previous-records .refresh-task-count:hover {
        cursor: pointer; }
      .tele-task-wrapper .upload-csv .previous-records .refresh-task-count img {
        width: 15px; }
    .tele-task-wrapper .upload-csv .upload-success {
      color: green; }
    .tele-task-wrapper .upload-csv .upload-fail {
      color: #F0565A; }
      .tele-task-wrapper .upload-csv .upload-fail img {
        width: 30px;
        margin-right: 0px; }
    .tele-task-wrapper .upload-csv .upload-btn-wrapper {
      margin-bottom: 4%; }
  .tele-task-wrapper .drop-lead {
    width: 33.33%; }
    .tele-task-wrapper .drop-lead .form-group {
      padding: 0;
      margin-bottom: 0; }
    .tele-task-wrapper .drop-lead input {
      border: none !important;
      background: none !important; }
    .tele-task-wrapper .drop-lead [type="radio"]:checked,
    .tele-task-wrapper .drop-lead [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px; }
    .tele-task-wrapper .drop-lead [type="radio"]:checked + label,
    .tele-task-wrapper .drop-lead [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block; }
    .tele-task-wrapper .drop-lead [type="radio"]:checked + label:before,
    .tele-task-wrapper .drop-lead [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff; }
    .tele-task-wrapper .drop-lead [type="radio"]:checked + label:after,
    .tele-task-wrapper .drop-lead [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #11D17E;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
    .tele-task-wrapper .drop-lead [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0); }
    .tele-task-wrapper .drop-lead [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1); }
    .tele-task-wrapper .drop-lead .drop-lead-heading {
      font-weight: 600;
      padding: 1% 0; }
    .tele-task-wrapper .drop-lead .drop-lead-sub-heading {
      font-size: 11px;
      color: #888888;
      font-weight: 600;
      padding: 1% 0; }
    .tele-task-wrapper .drop-lead .option-icon-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 6px; }
      .tele-task-wrapper .drop-lead .option-icon-wrapper img {
        width: 16px; }
    .tele-task-wrapper .drop-lead .reset-link {
      color: #13CECE;
      font-weight: 600;
      padding: 1% 0;
      text-decoration: underline; }
      .tele-task-wrapper .drop-lead .reset-link:hover {
        cursor: pointer; }
    .tele-task-wrapper .drop-lead .option-link {
      color: #13CECE;
      font-weight: 600;
      text-decoration: underline;
      padding: 1% 0; }
      .tele-task-wrapper .drop-lead .option-link:hover {
        cursor: pointer; }
    .tele-task-wrapper .drop-lead .drop-lead-options {
      padding: 5% 0; }
      .tele-task-wrapper .drop-lead .drop-lead-options label {
        color: #17BCBC;
        font-weight: 600; }
    .tele-task-wrapper .drop-lead .drop-lead-block-all-leads {
      display: flex;
      width: 60%;
      padding: 0 0 4% 0; }
    .tele-task-wrapper .drop-lead table {
      background-color: #e9e9e9;
      border-radius: 0; }
  .tele-task-wrapper .divider {
    width: 1.5px;
    background-color: #000000; }
  .tele-task-wrapper .header-csv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 800; }

.api-wrapper .api-popup {
  padding: 25px;
  padding-top: 0; }

.api-wrapper .first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.api-wrapper .active-switch {
  display: flex;
  flex-direction: column; }

.api-wrapper .brand-label {
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px; }

.api-wrapper .brand-subscription label,
.api-wrapper .brand-subscription input {
  padding: 0px 10px; }

.api-wrapper .brand-textarea {
  width: 100%;
  font-size: 15px;
  box-shadow: none !important;
  border: 0.6px solid #888888 !important; }

.api-wrapper .secret-key-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 25px; }
  .api-wrapper .secret-key-section .secret-key {
    font-weight: 700;
    width: 100%;
    box-shadow: none !important;
    border: 0.6px solid #888888 !important; }
  .api-wrapper .secret-key-section .generate-button {
    background: #838DEA;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600 !important;
    width: 20%;
    margin-left: 10px;
    font-size: 15px; }

.api-wrapper .success-message {
  color: green;
  margin-bottom: 15px;
  font-weight: 500; }

.api-wrapper .failed-message {
  color: #F0565A;
  margin-bottom: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start; }
  .api-wrapper .failed-message img {
    width: 30px;
    margin-right: 0px; }

.api-wrapper .submit-button {
  background: #023349;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: 600 !important;
  font-size: 16px;
  width: auto; }

.api-wrapper .edit-button {
  background: #17BCBC;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: 600 !important;
  width: auto;
  font-size: 16px; }

.api-wrapper .add-header {
  color: #FFFFFF;
  padding: 5px 15px;
  background-color: #17BCBC;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 900 !important;
  margin: 2rem 0; }

.api-wrapper .name-value-section {
  margin-top: 2%; }
  .api-wrapper .name-value-section .contain {
    max-height: 180px;
    overflow-y: auto;
    padding: 1% 0 0 0; }
    .api-wrapper .name-value-section .contain::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .api-wrapper .name-value-section .contain::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .api-wrapper .name-value-section .contain::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }

.api-wrapper .name-value-row {
  display: flex;
  gap: 5%;
  align-items: flex-start;
  position: relative;
  margin-bottom: 2%; }
  .api-wrapper .name-value-row .label-value {
    display: flex;
    gap: 2%;
    align-items: center;
    width: 30% !important;
    align-items: baseline; }
    .api-wrapper .name-value-row .label-value.w-70 {
      width: 70% !important; }
    .api-wrapper .name-value-row .label-value .label-bold {
      font-weight: 600;
      font-size: 13px;
      width: 30%; }
      .api-wrapper .name-value-row .label-value .label-bold.w-10 {
        width: 10%; }
  .api-wrapper .name-value-row .delete-icon {
    width: 18px; }
    .api-wrapper .name-value-row .delete-icon:hover {
      cursor: pointer; }
    .api-wrapper .name-value-row .delete-icon.disabled {
      opacity: 0.5;
      cursor: not-allowed; }
  .api-wrapper .name-value-row .action-btn {
    padding-top: 2%; }

.api-wrapper .dotted-border {
  padding: 1.5%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }
  .api-wrapper .dotted-border.bg-white {
    background-color: #FFFFFF; }
  .api-wrapper .dotted-border.p-4 {
    padding: 4%; }

.sms-stats-popup-section {
  display: flex;
  flex-flow: column;
  color: #636466; }
  .sms-stats-popup-section .linked-outcomes {
    font-size: 16px;
    display: flex;
    margin-bottom: 0.5em;
    font-weight: 600; }
    .sms-stats-popup-section .linked-outcomes .outcome-name {
      width: 80%; }
    .sms-stats-popup-section .linked-outcomes .outcome-value {
      width: 20%;
      text-align: right; }
  .sms-stats-popup-section .sms-stats {
    border: 1.5px solid #888888;
    border-radius: 4px;
    min-height: 50px;
    padding: 8px;
    font-weight: 600; }
    .sms-stats-popup-section .sms-stats .details {
      display: flex;
      margin-bottom: 0.5em; }
      .sms-stats-popup-section .sms-stats .details:last-child {
        margin-bottom: 0; }
      .sms-stats-popup-section .sms-stats .details .stats-name {
        width: 80%; }
      .sms-stats-popup-section .sms-stats .details .stats-value {
        width: 20%;
        text-align: right; }
  .sms-stats-popup-section .email-section {
    padding: 10px 0;
    min-height: 50px; }
    .sms-stats-popup-section .email-section .pill-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap; }
      .sms-stats-popup-section .email-section .pill-wrapper .pill {
        background-color: #17bcbc;
        color: white;
        border-radius: 22px;
        width: max-content;
        padding: 1%;
        margin: 1%;
        display: flex; }
        .sms-stats-popup-section .email-section .pill-wrapper .pill .del-icon img {
          height: 20px;
          transition: transform 0.2s; }
          .sms-stats-popup-section .email-section .pill-wrapper .pill .del-icon img:hover {
            transform: scale(1.5); }
    .sms-stats-popup-section .email-section .email-field {
      margin-top: 8px; }
      .sms-stats-popup-section .email-section .email-field .email-field-row {
        display: flex;
        align-items: baseline; }
        .sms-stats-popup-section .email-section .email-field .email-field-row .email-text {
          width: 50%; }
  .sms-stats-popup-section .optional-field {
    font-style: italic;
    font-size: 12px;
    padding: 1% 0; }
  .sms-stats-popup-section .date-report {
    display: flex;
    width: 100%; }
    .sms-stats-popup-section .date-report .info {
      font-weight: 600; }

.grey-box-wrapper {
  background-color: #efefef;
  padding: 2% 3%;
  margin-bottom: 3%; }
  .grey-box-wrapper.p-0 {
    padding: 0; }
  .grey-box-wrapper.w75 {
    width: 75vw; }
  .grey-box-wrapper .label {
    padding: 0;
    font-size: inherit; }
  .grey-box-wrapper .form-group label {
    padding-bottom: 0; }
  .grey-box-wrapper textarea {
    width: 100%; }
  .grey-box-wrapper .text-content {
    display: flex;
    align-items: flex-start;
    gap: 2%; }
    .grey-box-wrapper .text-content .form-data {
      width: 100%; }
      .grey-box-wrapper .text-content .form-data .group-label-input {
        display: flex;
        gap: 2%;
        align-items: center;
        margin-bottom: 1%; }
        .grey-box-wrapper .text-content .form-data .group-label-input .label-bold {
          font-weight: 600;
          font-size: 13px;
          width: 3%; }
  .grey-box-wrapper .dotted-border {
    padding: 1.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }
    .grey-box-wrapper .dotted-border.bg-white {
      background-color: #FFFFFF; }
    .grey-box-wrapper .dotted-border.p-4 {
      padding: 4%; }
  .grey-box-wrapper .checkbox-row label {
    margin-bottom: 0;
    color: #333333;
    font-size: 14px;
    display: flex;
    gap: 4px; }
    .grey-box-wrapper .checkbox-row label input[type="checkbox"] {
      margin-top: 1px; }
  .grey-box-wrapper .checkbox-row.disabled {
    pointer-events: none;
    opacity: 0.3; }
  .grey-box-wrapper .form-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 2%; }
    .grey-box-wrapper .form-footer .error-section {
      color: #DD5858;
      font-weight: 600; }
  .grey-box-wrapper .delete-icon {
    width: 18px; }
    .grey-box-wrapper .delete-icon:hover {
      cursor: pointer; }
  .grey-box-wrapper .transparent-btn {
    color: #023349;
    font-weight: 800;
    font-size: 16px; }
    .grey-box-wrapper .transparent-btn:hover {
      cursor: pointer; }
    .grey-box-wrapper .transparent-btn.disabled {
      opacity: 0.5;
      pointer-events: none; }
      .grey-box-wrapper .transparent-btn.disabled:hover {
        cursor: not-allowed; }
  .grey-box-wrapper .pill-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 6px; }
    .grey-box-wrapper .pill-wrapper:hover {
      cursor: pointer; }
    .grey-box-wrapper .pill-wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 2%;
      display: flex;
      height: 31px;
      width: max-content;
      align-items: center;
      justify-content: center;
      gap: 6px; }
      .grey-box-wrapper .pill-wrapper .pill .del-icon img {
        height: 13px;
        transition: transform 0.2s; }
        .grey-box-wrapper .pill-wrapper .pill .del-icon img:hover {
          transform: scale(1.5); }
    .grey-box-wrapper .pill-wrapper .plusicon {
      display: flex;
      justify-content: center;
      margin-left: -3%;
      padding: 2%; }
      .grey-box-wrapper .pill-wrapper .plusicon img {
        height: 20px; }
    .grey-box-wrapper .pill-wrapper .email-field {
      display: flex;
      justify-content: flex-start;
      margin-top: 2%;
      margin-left: -1%; }
  .grey-box-wrapper .underline-cta-blue {
    text-decoration: underline;
    color: #13cece;
    font-weight: 600;
    width: max-content; }
    .grey-box-wrapper .underline-cta-blue:hover {
      cursor: pointer; }
    .grey-box-wrapper .underline-cta-blue div[disabled] {
      pointer-events: none;
      opacity: 0.7; }
  .grey-box-wrapper .projectsCount {
    width: 150px; }
  .grey-box-wrapper .underline-cta-navyblue {
    text-decoration: underline;
    color: #023349;
    font-weight: 600;
    width: max-content; }
    .grey-box-wrapper .underline-cta-navyblue:hover {
      cursor: pointer; }

.crud-form {
  width: 55%; }
  .crud-form .form-data {
    width: 100%;
    padding-top: 3%; }
    .crud-form .form-data .group-label-input {
      display: flex;
      width: 100%;
      gap: 4%;
      align-items: center;
      margin-bottom: 4%; }
      .crud-form .form-data .group-label-input .label-bold {
        font-weight: 600;
        font-size: 14px;
        width: 30%;
        color: #454647; }
      .crud-form .form-data .group-label-input .input-value {
        width: 70%; }
    .crud-form .form-data .Dropdown-control {
      box-shadow: 0 0 4px 0 #a5adba !important;
      border: none !important;
      padding: 6px 12px !important; }
    .crud-form .form-data .default-placeholder .Dropdown-placeholder {
      color: #999; }
    .crud-form .form-data .Dropdown-arrow {
      margin-top: 1%; }
    .crud-form .form-data .Dropdown-menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .crud-form .form-data .Dropdown-menu::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .crud-form .form-data .Dropdown-menu::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .crud-form .form-data .Dropdown-option {
      padding: 1px 10px; }
    .crud-form .form-data .dropdown-toggle {
      width: 315px;
      display: flex !important;
      align-items: center;
      justify-content: space-between; }
    .crud-form .form-data .dropdown-item {
      width: max-content !important; }
    .crud-form .form-data .dropdown-menu {
      max-height: max-content;
      max-width: max-content; }
    .crud-form .form-data .checkbox-row label {
      margin-bottom: 0;
      color: #333333;
      font-size: 14px;
      display: flex;
      gap: 4px; }
      .crud-form .form-data .checkbox-row label input[type="checkbox"] {
        margin-top: 1px; }
    .crud-form .form-data .checkbox-row.disabled {
      pointer-events: none;
      opacity: 0.3; }
  .crud-form .cta-section {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    gap: 2%;
    align-items: baseline; }

.success-msg {
  padding: 2%;
  color: #11d17e;
  font-weight: 600; }

.failure-msg {
  color: #dd5858;
  padding: 2%;
  font-weight: 600; }

#skip-btn {
  background-color: #17bcbc; }

.caller {
  font-size: 13px;
  font-weight: 800; }
  .caller.looking_to_work {
    color: #838DEA; }
  .caller.working {
    color: #11D17E; }
  .caller.on_a_break {
    color: #023349; }

.red-delete-img {
  width: 15px;
  filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%); }
  .red-delete-img:hover {
    cursor: pointer; }

.working-hours-wrapper {
  display: flex;
  gap: 15px; }
  .working-hours-wrapper:first-child {
    margin-bottom: 2%; }
  .working-hours-wrapper .w-98 {
    width: 98%;
    display: flex; }
  .working-hours-wrapper .set-timer {
    display: flex;
    width: 100%;
    gap: 4%; }

.disabled-switch .react-switch-bg:hover, .disabled-switch .react-switch-handle:hover {
  cursor: not-allowed !important; }

.direct-certify, .direct-ban {
  padding: 2% 0; }
  .direct-certify .mobile-number-section, .direct-ban .mobile-number-section {
    display: flex;
    align-items: baseline; }
  .direct-certify .form-group, .direct-ban .form-group {
    width: 85%;
    margin-bottom: 1%; }
  .direct-certify .plus-icon, .direct-ban .plus-icon {
    width: 15%;
    justify-content: flex-end; }
  .direct-certify .modal-btn-footer, .direct-ban .modal-btn-footer {
    justify-content: center; }
  .direct-certify .error-msg, .direct-ban .error-msg {
    font-size: 12px;
    color: red; }
  .direct-certify .pill-wrapper, .direct-ban .pill-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 7%;
    max-height: 150px;
    overflow-y: auto; }
    .direct-certify .pill-wrapper::-webkit-scrollbar-track, .direct-ban .pill-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .direct-certify .pill-wrapper::-webkit-scrollbar, .direct-ban .pill-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .direct-certify .pill-wrapper::-webkit-scrollbar-thumb, .direct-ban .pill-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .direct-certify .pill-wrapper:hover, .direct-ban .pill-wrapper:hover {
      cursor: pointer; }
    .direct-certify .pill-wrapper .pill, .direct-ban .pill-wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex;
      height: 31px;
      width: max-content;
      align-items: center;
      padding: 3%; }
      .direct-certify .pill-wrapper .pill .del-icon img, .direct-ban .pill-wrapper .pill .del-icon img {
        height: 20px;
        transition: transform 0.2s; }
        .direct-certify .pill-wrapper .pill .del-icon img:hover, .direct-ban .pill-wrapper .pill .del-icon img:hover {
          transform: scale(1.5); }
    .direct-certify .pill-wrapper .email-field, .direct-ban .pill-wrapper .email-field {
      display: flex;
      justify-content: flex-start;
      margin-top: 2%;
      margin-left: -1%;
      padding-left: 2%; }
      .direct-certify .pill-wrapper .email-field .plusicon, .direct-ban .pill-wrapper .email-field .plusicon {
        display: flex;
        justify-content: center;
        margin-left: -3%;
        padding: 2%; }
        .direct-certify .pill-wrapper .email-field .plusicon img, .direct-ban .pill-wrapper .email-field .plusicon img {
          height: 20px; }

.direct-ban .form-group {
  width: 100% !important; }

.direct-ban .ban-reason {
  margin-top: 1em !important; }
  .direct-ban .ban-reason .Dropdown-control {
    border-bottom: 2px solid #ccc !important;
    padding: 1px 38px 2px 9px !important; }

#project-table .custom-toolbar {
  display: flex; }
  #project-table .custom-toolbar .pill-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 7%;
    max-height: 150px;
    overflow-y: auto; }
    #project-table .custom-toolbar .pill-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    #project-table .custom-toolbar .pill-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    #project-table .custom-toolbar .pill-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    #project-table .custom-toolbar .pill-wrapper:hover {
      cursor: pointer; }
    #project-table .custom-toolbar .pill-wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex;
      height: 31px;
      width: max-content;
      align-items: center;
      padding: 3%; }
      #project-table .custom-toolbar .pill-wrapper .pill .del-icon img {
        height: 20px;
        transition: transform 0.2s; }
        #project-table .custom-toolbar .pill-wrapper .pill .del-icon img:hover {
          transform: scale(1.5); }
    #project-table .custom-toolbar .pill-wrapper .email-field {
      display: flex;
      justify-content: flex-start;
      margin-top: 2%;
      margin-left: -1%;
      padding-left: 2%; }
      #project-table .custom-toolbar .pill-wrapper .email-field .plusicon {
        display: flex;
        justify-content: center;
        margin-left: -3%;
        padding: 2%; }
        #project-table .custom-toolbar .pill-wrapper .email-field .plusicon img {
          height: 20px; }
  #project-table .custom-toolbar .pill-wrapper {
    overflow: initial;
    margin-bottom: 0;
    margin-right: 3%; }
    #project-table .custom-toolbar .pill-wrapper .pill {
      padding: 13%;
      gap: 0.3em; }
  #project-table .custom-toolbar .first {
    width: 60%; }
  #project-table .custom-toolbar .second {
    width: 40%;
    display: flex;
    justify-content: flex-end; }
  #project-table .custom-toolbar.w-120 .toolbar {
    min-width: 120px; }
  #project-table .custom-toolbar.w-215 .toolbar {
    min-width: 215px; }

#custom-table .custom-toolbar {
  display: flex; }
  #custom-table .custom-toolbar .pill-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 7%;
    max-height: 150px;
    overflow-y: auto; }
    #custom-table .custom-toolbar .pill-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    #custom-table .custom-toolbar .pill-wrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    #custom-table .custom-toolbar .pill-wrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    #custom-table .custom-toolbar .pill-wrapper:hover {
      cursor: pointer; }
    #custom-table .custom-toolbar .pill-wrapper .pill {
      background-color: #17bcbc;
      color: white;
      border-radius: 22px;
      width: max-content;
      padding: 1%;
      margin: 1%;
      display: flex;
      height: 31px;
      width: max-content;
      align-items: center;
      padding: 3%; }
      #custom-table .custom-toolbar .pill-wrapper .pill .del-icon img {
        height: 20px;
        transition: transform 0.2s; }
        #custom-table .custom-toolbar .pill-wrapper .pill .del-icon img:hover {
          transform: scale(1.5); }
    #custom-table .custom-toolbar .pill-wrapper .email-field {
      display: flex;
      justify-content: flex-start;
      margin-top: 2%;
      margin-left: -1%;
      padding-left: 2%; }
      #custom-table .custom-toolbar .pill-wrapper .email-field .plusicon {
        display: flex;
        justify-content: center;
        margin-left: -3%;
        padding: 2%; }
        #custom-table .custom-toolbar .pill-wrapper .email-field .plusicon img {
          height: 20px; }
  #custom-table .custom-toolbar .pill-wrapper {
    overflow: initial;
    margin-bottom: 0;
    margin-right: 3%; }
    #custom-table .custom-toolbar .pill-wrapper .pill {
      padding: 13%;
      gap: 0.3em; }
  #custom-table .custom-toolbar .first {
    width: 60%; }
  #custom-table .custom-toolbar .second {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end; }
  #custom-table .custom-toolbar .dropdown {
    width: 50%;
    margin: 15px;
    text-decoration: solid;
    font-weight: 800; }

.client-custom-table .MuiToolbar-regular {
  display: none; }

.complianceHistory {
  padding: 2% 1%;
  max-height: 70vh;
  min-height: 62vh;
  overflow-y: scroll; }
  .complianceHistory::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .complianceHistory::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .complianceHistory::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

caller-details {
  font-weight: 800;
  font-size: 150%; }

.compliance-data-collection-popup {
  padding: 20px; }
  .compliance-data-collection-popup .compliance-data-collection-header {
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-bottom: 10px; }
  .compliance-data-collection-popup .outcome-box {
    background-color: #F1F1F1;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #555555;
    height: 50px; }
    .compliance-data-collection-popup .outcome-box .outcome-header {
      color: #000000;
      font-weight: 600;
      font-size: 16px; }
  .compliance-data-collection-popup .dc-subheading {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 10px 0; }
  .compliance-data-collection-popup .compliance-data-collection-responses {
    background-color: #F1F1F1;
    padding: 15px;
    max-height: 250px;
    height: fit-content;
    color: #555555;
    overflow-y: auto; }
    .compliance-data-collection-popup .compliance-data-collection-responses .q-and-a {
      margin: 8px 0;
      padding-left: 20px; }
    .compliance-data-collection-popup .compliance-data-collection-responses .data-collection-content {
      padding-left: 0px !important; }
  .compliance-data-collection-popup .customer-info-header {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 10px 0; }
  .compliance-data-collection-popup .customer-info {
    background-color: #F1F1F1;
    padding: 15px;
    overflow-y: auto;
    color: #555555;
    max-height: 100px;
    height: fit-content; }
    .compliance-data-collection-popup .customer-info .customer-info-content {
      padding-left: 0px !important; }
    .compliance-data-collection-popup .customer-info .customer-info-line {
      display: flex;
      flex-direction: row;
      line-height: 23px; }
    .compliance-data-collection-popup .customer-info .customer-field {
      font-weight: 600; }

.callActionPopup {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  min-height: 62vh;
  overflow-y: scroll;
  padding: 0 1%; }
  .callActionPopup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .callActionPopup::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .callActionPopup::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }
  .callActionPopup .MuiAccordionSummary-root {
    max-height: 42px;
    min-height: 42px; }
  .callActionPopup .MuiAccordionSummary-root.Mui-expanded {
    max-height: 42px;
    min-height: 42px; }
  .callActionPopup .MuiAccordionDetails-root {
    padding: 17px 0; }
  .callActionPopup .callActionHeader {
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 1%; }
  .callActionPopup .feedbackHeader {
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 1%; }
  .callActionPopup .feedbackWrapper {
    display: flex;
    flex-flow: column;
    max-height: 270px;
    overflow-y: auto;
    padding: 1.5%;
    margin-bottom: 0.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }
    .callActionPopup .feedbackWrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .callActionPopup .feedbackWrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .callActionPopup .feedbackWrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .callActionPopup .feedbackWrapper label {
      font-size: 15px; }
    .callActionPopup .feedbackWrapper .option span {
      margin-left: 0.5%; }
    .callActionPopup .feedbackWrapper input {
      background-color: #efefef;
      box-shadow: none !important; }
  .callActionPopup .actionWrapper {
    margin-bottom: 0.5%; }
  .callActionPopup .callActionDropdown {
    width: 20%;
    font-size: 15px;
    color: #636466;
    padding: 5px 0;
    margin-bottom: 12px;
    border-bottom: 1px solid #888888; }
    .callActionPopup .callActionDropdown .Dropdown-control {
      padding: 0px !important; }
  .callActionPopup .callActionOptionsList {
    padding-bottom: 0; }
  .callActionPopup .callActionOptionsListAdditional {
    display: block; }
  .callActionPopup .callActionOptions {
    color: #636466;
    box-shadow: none !important; }
  .callActionPopup .additionalInfo {
    margin: 10px 0;
    color: #636466;
    padding: 5px 20px;
    padding-left: 10px;
    width: 100%;
    border: 2px solid black; }
  .callActionPopup .consequenceAccordion {
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: none !important; }
  .callActionPopup .consequenceBar {
    background-color: #023349;
    color: #ffffff;
    font-size: 16px !important;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: none !important; }
  .callActionPopup .callActionButton {
    background-color: #023349;
    border-radius: 8px;
    font-size: 18px;
    color: #ffffff;
    padding: 5px 15px;
    font-weight: 700;
    width: fit-content;
    align-self: flex-end; }
  .callActionPopup .MuiAccordion-root:before {
    background-color: none !important; }
  .callActionPopup .cmm-warning-action {
    background-color: #FFF8E0;
    font-weight: 600;
    border-radius: 6px;
    padding: 1%;
    margin: 2% 0;
    display: flex;
    align-items: center; }
    .callActionPopup .cmm-warning-action img {
      width: 26px;
      margin-right: 8px; }

.consequence-popup {
  display: flex;
  flex-direction: column; }
  .consequence-popup .consequence_heading {
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 1%; }
  .consequence-popup .consequenceOptions {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    padding: 20px 0; }
  .consequence-popup .consequenceDropdown {
    width: 45%;
    font-size: 15px;
    color: #636466;
    padding: 5px 0;
    margin-bottom: 12px;
    border-bottom: 1px solid #888888; }
    .consequence-popup .consequenceDropdown .submit {
      background-color: #023349;
      margin-right: 30px; }
    .consequence-popup .consequenceDropdown .Dropdown-control {
      padding: 0px !important; }
  .consequence-popup .ban_message {
    background: #FFE2E2;
    border-radius: 8px;
    height: 55px;
    padding-left: 20px;
    font-weight: 600;
    display: flex;
    align-items: center; }
  .consequence-popup .feedbackHeader {
    font-size: 18px;
    color: #023349;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 1%; }
  .consequence-popup .feedbackWrapper {
    display: flex;
    flex-flow: column;
    max-height: 270px;
    overflow-y: auto;
    padding: 1.5%;
    margin-bottom: 0.5%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='4' stroke-dasharray='3%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }
    .consequence-popup .feedbackWrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .consequence-popup .feedbackWrapper::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5; }
    .consequence-popup .feedbackWrapper::-webkit-scrollbar-thumb {
      background-color: #8e8b8b;
      border: 2px solid #b6b4b4;
      border-radius: 6px; }
    .consequence-popup .feedbackWrapper label {
      font-size: 15px; }
    .consequence-popup .feedbackWrapper .option span {
      margin-left: 0.5%; }
    .consequence-popup .feedbackWrapper input {
      background-color: #efefef;
      box-shadow: none !important; }
  .consequence-popup .additionalInfo {
    margin: 10px 0;
    color: #636466;
    padding: 5px 20px;
    padding-left: 10px;
    width: 100%;
    border: 2px solid black; }

.defineLeads .duplicateNumbers {
  font-weight: 500;
  font-size: 20px;
  color: #555555;
  margin-right: 5%; }

.defineLeads .horizontalDivider {
  height: 2px;
  margin-bottom: 3%;
  background: #EAEAEA; }

.defineLeads .del-icon {
  height: 20px;
  transition: transform 0.2s; }
  .defineLeads .del-icon:hover {
    transform: scale(1.5); }

.defineLeads .optionalCheck {
  width: 20px;
  margin-right: 10px;
  box-shadow: none !important;
  border: 1px solid #888888 !important; }

.defineLeads .headerControls {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .defineLeads .headerControls .checkboxes {
    display: flex;
    gap: 0.5em;
    align-items: flex-end; }
    .defineLeads .headerControls .checkboxes label {
      display: inline-block;
      padding-right: 20px;
      white-space: nowrap;
      margin-bottom: 0; }
      .defineLeads .headerControls .checkboxes label span {
        vertical-align: middle; }
    .defineLeads .headerControls .checkboxes input[type='checkbox'] {
      vertical-align: middle;
      margin-right: 0.5em;
      width: 15px; }
  .defineLeads .headerControls .checkbox-group {
    width: 85%;
    padding: 2% 0; }
    .defineLeads .headerControls .checkbox-group .disabled {
      opacity: 0.5; }

.react-select-custom {
  border: none; }

.DateInput_input__disabled {
  font-style: normal; }

.auto-suggest {
  position: relative; }
  .auto-suggest .auto-suggest-textarea {
    height: 100px;
    padding: 10px;
    width: 100%; }
  .auto-suggest .auto-suggest-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    max-height: 120px;
    min-height: 50px;
    overflow-y: auto;
    z-index: 1000; }
  .auto-suggest .auto-suggest-item {
    padding: 8px 12px;
    cursor: pointer; }
  .auto-suggest .auto-suggest-item:hover {
    background-color: #ddd; }

.headers_list {
  max-height: 70vh;
  overflow-y: auto; }
  .headers_list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .headers_list::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .headers_list::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.faq_wrapper {
  min-height: 60vh;
  padding: 0 2em 2em; }
  .faq_wrapper .result_message {
    font-size: 14px;
    margin: 1em 0;
    font-weight: 800;
    text-align: center; }
    .faq_wrapper .result_message.success {
      color: var(--color-dark-green); }
    .faq_wrapper .result_message.error {
      color: var(--color-red); }
  .faq_wrapper .button_row {
    text-align: center; }
  .faq_wrapper .faq_management .footer_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 2s linear; }
  .faq_wrapper .faq_management .action_trigger {
    font-size: 16px;
    font-weight: 800; }
    .faq_wrapper .faq_management .action_trigger span {
      margin: 0 0.5em;
      font-size: 18px;
      cursor: pointer;
      letter-spacing: 0.05em; }
    .faq_wrapper .faq_management .action_trigger .accept {
      color: var(--color-dark-green);
      text-decoration: underline; }
    .faq_wrapper .faq_management .action_trigger .decline {
      color: var(--color-red);
      text-decoration: underline; }
  .faq_wrapper .upload_file {
    padding: 5em;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .faq_wrapper .upload_file .button_row {
      margin: 0 auto; }
  .faq_wrapper .container_wrapper {
    border: 3px solid var(--color-grey-80);
    border-radius: 6px;
    margin: 2rem auto;
    transition: transform 0.3s ease-in-out;
    background-color: #efefef; }
    .faq_wrapper .container_wrapper:first-child {
      margin-top: 0.5rem; }
    .faq_wrapper .container_wrapper .helper_icon {
      width: 18px;
      cursor: pointer; }
    .faq_wrapper .container_wrapper.moving-up {
      transform: translateY(-100%); }
    .faq_wrapper .container_wrapper.moving-down {
      transform: translateY(100%); }
    .faq_wrapper .container_wrapper.highlighted {
      background-color: #EAFBFF; }
  .faq_wrapper .footer_cta {
    letter-spacing: 0.05em;
    background-color: var(--color-grey-80);
    width: 100%;
    padding: 1em;
    border-radius: 0 0 4px;
    display: flex;
    justify-content: space-between;
    font-weight: 800; }
    .faq_wrapper .footer_cta .delete_cta {
      color: var(--color-red);
      cursor: pointer; }
    .faq_wrapper .footer_cta .add_question {
      cursor: pointer; }
  .faq_wrapper .template_form {
    padding: 0.5em 2em 2em; }
    .faq_wrapper .template_form .question-row {
      display: flex;
      justify-content: flex-end;
      align-items: baseline; }
      .faq_wrapper .template_form .question-row .re-order-section {
        font-size: 22px; }
        .faq_wrapper .template_form .question-row .re-order-section .disabled {
          opacity: 0.5; }
        .faq_wrapper .template_form .question-row .re-order-section svg {
          margin-right: 0.5em;
          cursor: pointer; }
          .faq_wrapper .template_form .question-row .re-order-section svg:last-child {
            margin-right: 0; }
  .faq_wrapper .fallback-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 35vh; }
    .faq_wrapper .fallback-wrapper img {
      width: 80px; }
    .faq_wrapper .fallback-wrapper .fallback_text {
      font-size: 16px;
      font-weight: 800; }

.basic-single .select__menu {
  z-index: 10 !important; }

.support {
  min-height: 100vh;
  padding: 150px 0; }
  .support .total-card {
    width: 90%;
    /* height: 100px; */
    margin: 80px auto;
    background-color: #11d17e;
    border: 1px solid #b8bbca;
    border-radius: 10px;
    padding: 1%;
    margin-top: 0px;
    margin-left: 5%; }
  .support .filter {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #406AF8;
    color: white;
    top: 50px;
    padding: 10px 0;
    z-index: 1; }
    .support .filter .filter-dropdown {
      width: 50%;
      border-right: 1px solid white;
      display: flex;
      justify-content: center; }
      .support .filter .filter-dropdown button {
        background-color: #406AF8;
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center; }
        .support .filter .filter-dropdown button:focus {
          background-color: #406AF8;
          box-shadow: none; }
        .support .filter .filter-dropdown button img {
          width: 20px;
          margin-left: 20px; }
      .support .filter .filter-dropdown .dropdown-menu {
        width: 100%;
        border: none;
        width: calc(100% - 20px);
        margin: 0 10px;
        display: block;
        transform: translateY(-2em);
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; }
        .support .filter .filter-dropdown .dropdown-menu li > a {
          font-weight: 100 !important; }
        .support .filter .filter-dropdown .dropdown-menu li.active > a {
          background-color: rgba(64, 106, 248, 0.1);
          color: black;
          font-weight: 100 !important; }
      .support .filter .filter-dropdown .dropdown.open .dropdown-menu {
        visibility: visible;
        /* shows sub-menu */
        transform: translateY(0%);
        opacity: 1;
        transition-delay: 0s, 0s, 0.3s; }
      .support .filter .filter-dropdown #duration-filter {
        margin: 0 10px; }
      .support .filter .filter-dropdown #duration-filter ~ .dropdown-menu {
        min-width: 100px; }
  .support .filter-query {
    display: flex;
    width: 40%;
    flex-flow: column;
    margin: auto;
    margin-bottom: 5%;
    margin-top: -4%; }
  .support .query-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .support .query-list .query-card {
      margin-bottom: 30px;
      width: 30%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 20px;
      height: 0%;
      padding-bottom: 10px;
      opacity: 0.7;
      transition: all 0.15s ease; }
      .support .query-list .query-card img {
        width: 25px; }
      .support .query-list .query-card .message {
        font-size: 18px;
        margin-left: 25px;
        margin-top: 0px; }
      .support .query-list .query-card hr {
        width: 30%;
        border-color: #CECFCF;
        margin-top: 50px; }
      .support .query-list .query-card .name span:first-child {
        font-size: 20px;
        text-transform: capitalize; }
      .support .query-list .query-card .name span:last-child {
        color: #7A869A; }
      .support .query-list .query-card .contact {
        margin-top: 20px; }
        .support .query-list .query-card .contact > div {
          display: flex;
          align-items: center;
          margin-bottom: 10px; }
          .support .query-list .query-card .contact > div img {
            margin-right: 10px; }
        .support .query-list .query-card .contact img {
          width: 23px; }
      .support .query-list .query-card:hover {
        opacity: 1; }

.reply-outer .reply-btn {
  width: 100%; }
  .reply-outer .reply-btn img {
    height: 20px; }

#mission {
  align-items: stretch; }

.wrapper {
  border-radius: 12px;
  padding: 8%;
  display: flex;
  flex-flow: column;
  align-items: center; }
  .wrapper #mission {
    align-items: stretch; }
  .wrapper .mission-btn {
    margin-top: 3%;
    width: 17em;
    height: 3em; }
  .wrapper .img img {
    width: 54px; }
  .wrapper .mission-details {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    padding: 14px; }
    .wrapper .mission-details #tasks {
      display: flex;
      flex-flow: column;
      padding: 5%;
      padding-top: 2%; }
    .wrapper .mission-details .wrap-in {
      display: flex;
      justify-content: space-around; }
      .wrapper .mission-details .wrap-in #task {
        justify-content: space-between;
        align-items: center; }
      .wrapper .mission-details .wrap-in .info {
        display: flex; }
        .wrapper .mission-details .wrap-in .info p {
          padding: 3px;
          font-size: 15px;
          text-transform: capitalize; }
    .wrapper .mission-details .heading {
      align-items: center;
      font-size: 23px;
      display: flex;
      justify-content: center;
      margin-bottom: 3%; }

.query-list-apps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  .query-list-apps .category-support {
    background-color: #838dea;
    font-weight: 600;
    color: black; }
  .query-list-apps .query-card-apps {
    margin-bottom: 30px;
    width: 30%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    padding: 20px;
    height: 0%;
    padding-bottom: 10px;
    opacity: 0.7;
    transition: all 0.15s ease;
    /* Rounded sliders */ }
    .query-list-apps .query-card-apps img {
      width: 25px; }
    .query-list-apps .query-card-apps .message {
      font-size: 18px;
      margin-left: 25px;
      margin-top: 0px; }
    .query-list-apps .query-card-apps hr {
      width: 30%;
      border-color: #CECFCF;
      margin-top: 50px; }
    .query-list-apps .query-card-apps .name span:first-child {
      font-size: 20px;
      text-transform: capitalize; }
      .query-list-apps .query-card-apps .name span:first-child .certified {
        font-size: 16px;
        font-style: italic;
        color: #17BCBC; }
    .query-list-apps .query-card-apps .contact {
      margin-top: 20px; }
      .query-list-apps .query-card-apps .contact > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .query-list-apps .query-card-apps .contact > div img {
          margin-right: 10px; }
      .query-list-apps .query-card-apps .contact img {
        width: 23px; }
    .query-list-apps .query-card-apps:hover {
      opacity: 1; }
    .query-list-apps .query-card-apps .reply-btn {
      border: none; }
      .query-list-apps .query-card-apps .reply-btn:hover h5 {
        color: blue; }
    .query-list-apps .query-card-apps .switch {
      position: relative;
      display: inline-block;
      width: 47px;
      height: 19px; }
    .query-list-apps .query-card-apps input:checked + .slider {
      background-color: #2196f3; }
    .query-list-apps .query-card-apps input:focus + .slider {
      box-shadow: 0 0 1px #2196f3; }
    .query-list-apps .query-card-apps input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px); }
    .query-list-apps .query-card-apps .slider.round {
      border-radius: 34px; }
    .query-list-apps .query-card-apps .slider.round:before {
      border-radius: 50%; }
    .query-list-apps .query-card-apps .resolve {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .query-list-apps .query-card-apps .resolve h5 {
        margin-right: 10px; }
    .query-list-apps .query-card-apps .switch input {
      opacity: 0;
      width: 0;
      height: 0; }
    .query-list-apps .query-card-apps .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .query-list-apps .query-card-apps .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 14px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s; }

.mission-payments {
  padding: 100px 0;
  min-height: 100vh; }
  .mission-payments .tab-content {
    padding-top: 20px; }
  .mission-payments .total-money-owed {
    background-color: #e26464;
    padding: 20px;
    border-radius: 4px;
    color: white;
    margin-bottom: 30px;
    font-size: 18px; }
    .mission-payments .total-money-owed span {
      color: #FFAB00;
      font-size: 24px; }
  .mission-payments .payment-tab {
    border: solid 1px #b2bedc;
    width: 100%;
    background-color: #b2bedc;
    border-radius: 4px;
    padding: 20px;
    -webkit-box-shadow: -2px 6px 38px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 6px 38px -20px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 6px 38px -20px rgba(0, 0, 0, 0.75);
    transition: ease 0.5s; }
    .mission-payments .payment-tab h5 {
      color: #091e42 !important; }
    .mission-payments .payment-tab span {
      color: #091e42;
      margin-top: 15px; }
    .mission-payments .payment-tab input {
      border: solid 1px #091e42 !important;
      padding: 10px;
      border-radius: 4px !important;
      margin-top: 10px !important; }
    .mission-payments .payment-tab img {
      display: block;
      margin: 0 auto; }
  .mission-payments .p-10 {
    padding: 12px; }
  .mission-payments .payment-tab-inactive {
    border: solid 1px  white;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    transition: ease 0.5s; }
    .mission-payments .payment-tab-inactive h5 {
      color: grey !important; }
    .mission-payments .payment-tab-inactive span {
      color: grey;
      margin-top: 15px; }
    .mission-payments .payment-tab-inactive input {
      border: solid 1px white !important;
      padding: 10px;
      border-radius: 4px !important;
      margin-top: 10px !important; }
    .mission-payments .payment-tab-inactive img {
      display: block;
      margin: 0 auto; }
  .mission-payments .payment-marker table td {
    padding: 10px 0; }
    .mission-payments .payment-marker table td button {
      background-color: #406AF8;
      color: white;
      border: none; }
    .mission-payments .payment-marker table td .marked {
      background-color: #36B37E;
      color: white;
      padding: 5px 20px;
      border-radius: 4px;
      display: inline-block; }
  .mission-payments .amazon-payments .or {
    margin: 20px 0; }
  .mission-payments .upcoming-payments {
    display: flex;
    flex-wrap: wrap; }
    .mission-payments .upcoming-payments .upcoming-payment-card {
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
      padding: 15px;
      border-radius: 15px;
      background-color: white;
      margin-right: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-basis: calc(33% - 15px); }
      .mission-payments .upcoming-payments .upcoming-payment-card .logo-wrapper {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 1px solid #e7e7e7;
        flex-shrink: 0; }
        .mission-payments .upcoming-payments .upcoming-payment-card .logo-wrapper img {
          max-width: 100%;
          border-radius: 100%; }
      .mission-payments .upcoming-payments .upcoming-payment-card .content-wrapper {
        margin-left: 15px; }
        .mission-payments .upcoming-payments .upcoming-payment-card .content-wrapper .days-passed span:first-child {
          font-size: 20px; }
        .mission-payments .upcoming-payments .upcoming-payment-card .content-wrapper .days-passed span:last-child {
          color: #7A869A;
          margin-left: 5px; }
      .mission-payments .upcoming-payments .upcoming-payment-card .name {
        font-size: 16px; }
    .mission-payments .upcoming-payments .upcoming-payment-card.red {
      background-color: red; }

.wrapper-tring-dashboard {
  background: none !important;
  display: flex;
  flex-flow: column;
  margin: 10.5rem 6.8rem 5rem; }
  @media (max-width: 600px) {
    .wrapper-tring-dashboard {
      margin: 80px 25px; } }
  .wrapper-tring-dashboard .details_page__container {
    padding: 0 0 3%; }
    @media (min-width: 900px) {
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar {
        display: grid;
        grid-template-columns: auto 250px; } }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_text {
      width: 100%; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_text img {
        height: 18px;
        margin: 1% 1% 2% 0%; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_text img:hover {
          cursor: pointer; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_text h3 {
        display: inline;
        color: #454647;
        font-weight: 600 !important;
        font-size: 20px; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_btn {
      width: 100%;
      align-self: center; }
      @media (max-width: 600px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_btn {
          margin-top: 10px; } }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_btn .download__btn {
        padding: 8px 15px;
        font-size: 12px; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_btn button {
        display: block;
        margin-left: auto;
        margin-right: 0;
        margin-top: 0; }
      @media (min-width: 900px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .heading_bar .heading_btn .btn-toolbar {
          display: flex;
          justify-content: flex-end; } }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header {
      margin: 30px 0px 10px 0px;
      justify-items: stretch;
      width: 100%;
      justify-content: space-between;
      grid-template-columns: 30% 30% 30%;
      box-shadow: 0px 0px 3px #1e1e1e76;
      border-radius: 8px; }
      @media (max-width: 1200px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header {
          width: 100%; } }
      @media (min-width: 992px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header {
          display: grid; } }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .flex {
        display: flex; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .h-60 {
        height: 60px; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box {
        gap: 20px;
        padding: 5px 20px;
        margin: 0px 0px 0px 0px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .mt-8 {
          margin-top: 8px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .mb-8 {
          margin-bottom: 8px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__heading {
          align-self: center; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__heading img {
            height: 22px;
            margin-right: 4px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__heading h4 {
            display: inline;
            color: #023349;
            font-weight: 600 !important;
            font-size: 16px;
            vertical-align: middle; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__numbers {
          align-self: center; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__numbers h4 {
            color: #17BCBC;
            font-size: 32px;
            font-weight: 600 !important; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .stats__numbers p {
            color: #17BCBC;
            font-size: 14px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__header .stats__box .add__agents {
          align-self: center;
          cursor: pointer; }
    @media (min-width: 1200px) {
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 {
        display: flex;
        justify-content: space-between; } }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights {
      margin: 30px 0px 30px 0px;
      box-shadow: 0px 0px 3px #1e1e1e76;
      border-radius: 8px;
      width: 68%; }
      @media (max-width: 1200px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights {
          width: 100%; } }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #EFEFEF;
        padding: 15px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__header img {
          height: 22px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__header h4 {
          display: inline;
          color: #023349;
          font-size: 18px;
          vertical-align: middle;
          font-weight: 600 !important; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__header .chart__dropdown {
          float: right;
          display: flex;
          gap: 25px;
          align-items: center; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div {
        padding: 15px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div .load-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div .load-btn-wrapper .load-graph {
            padding: 9px;
            min-width: 150px;
            text-transform: uppercase;
            color: #FFFF;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.5); }
            .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div .load-btn-wrapper .load-graph:hover {
              cursor: pointer; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div.default {
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0.4; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div.tooltip {
          position: relative;
          border-bottom: 1px dotted black; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div.tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            /* Position the tooltip */
            position: absolute;
            z-index: 1; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .line__calls_insights .chart__div.tooltip:hover .tooltiptext {
            visibility: visible; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper {
      margin: 30px 0px 30px 0px;
      box-shadow: 0px 0px 3px #1e1e1e76;
      border-radius: 8px;
      width: 28%; }
      @media (max-width: 1200px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper {
          width: 100%; } }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .chart__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #EFEFEF;
        padding: 15px;
        margin-bottom: 15px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .chart__header img {
          height: 22px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .chart__header h4 {
          display: inline;
          color: #023349;
          font-size: 18px;
          vertical-align: middle;
          font-weight: 600 !important; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .chart__header .chart__dropdown {
          float: right;
          display: flex;
          gap: 25px;
          align-items: center; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .flex {
        display: flex;
        justify-content: space-between; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .h-60 {
        height: 60px; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box {
        box-shadow: 0px 0px 3px #1e1e1e76;
        border-radius: 8px;
        padding: 5px 20px;
        margin: 0px 10px 20px 10px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box:last-child {
          padding-right: 5px 20px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box:last-child .stats__numbers {
            width: 100% !important;
            text-align: right; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .mt-8 {
          margin-top: 8px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .mb-8 {
          margin-bottom: 8px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__heading {
          align-self: center; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__heading img {
            height: 22px;
            margin-right: 4px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__heading h4 {
            display: inline;
            color: #023349;
            font-weight: 600 !important;
            font-size: 16px;
            vertical-align: middle; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__numbers {
          align-self: center; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__numbers h4 {
            color: #17BCBC;
            font-size: 32px;
            font-weight: 600 !important; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .stats__numbers p {
            color: #17BCBC;
            font-size: 14px;
            font-weight: 600 !important; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .stats__box .add__agents {
          align-self: center;
          cursor: pointer; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .winning_outcomes_wrapper {
        display: flex; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .winning_outcomes_wrapper div {
          width: 100%; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .winning_outcomes_wrapper p {
          margin-bottom: 1%;
          padding: 0; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .load-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .load-btn-wrapper .load-graph {
          padding: 9px;
          min-width: 150px;
          color: #FFFF;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          border-radius: 20px;
          background-color: rgba(0, 0, 0, 0.5); }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_1 .stats_right__wrapper .load-btn-wrapper .load-graph:hover {
            cursor: pointer; }
    @media (min-width: 900px) {
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 {
        display: flex;
        justify-content: space-between;
        column-gap: 20px; } }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls,
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls {
      box-shadow: 0px 0px 3px #1e1e1e76;
      border-radius: 8px;
      width: 100%; }
      @media (max-width: 900px) {
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls {
          margin-bottom: 20px; } }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .green__back,
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .green__back {
        background-color: #DFF5EB; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .green__back h4,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .green__back h4 {
          color: #169999;
          font-weight: 600 !important; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .red__back,
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .red__back {
        background-color: #FFDBDB; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .red__back h4,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .red__back h4 {
          color: #DD5858;
          font-weight: 600 !important; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__header,
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 15px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__header h4,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__header h4 {
          font-weight: lighter;
          font-size: 18px;
          vertical-align: top; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div2 .qa_stats_wapper,
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div2 .qa_stats_wapper {
        height: 200px;
        display: flex;
        margin: 1em;
        justify-content: center;
        align-items: center; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div2 .qa_stats_wapper .qa_list,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div2 .qa_stats_wapper .qa_list {
          width: 100%;
          background-color: unset; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_row,
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_row {
            border: none; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_column,
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_column {
            padding: 0.5em; }
            .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_column .view_parameter_btn,
            .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div2 .qa_stats_wapper .qa_list .qa_column .view_parameter_btn {
              color: #17BCBC;
              font-weight: 800;
              cursor: pointer; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div,
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div {
        padding: 15px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div .load-btn-wrapper,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div .load-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div .load-btn-wrapper .load-graph,
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div .load-btn-wrapper .load-graph {
            padding: 9px;
            min-width: 150px;
            color: #FFFF;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.5); }
            .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div .load-btn-wrapper .load-graph:hover,
            .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div .load-btn-wrapper .load-graph:hover {
              cursor: pointer; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div .hint_ptag,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div .hint_ptag {
          color: #373d3f;
          font-size: 12px;
          font-weight: 400;
          font-family: Helvetica, Arial, sans-serif; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div.default,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div.default {
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0.5; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div.tooltip,
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div.tooltip {
          position: relative;
          border-bottom: 1px dotted black; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div.tooltip .tooltiptext,
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div.tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            /* Position the tooltip */
            position: absolute;
            z-index: 1; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__connected_calls .doughnut__div.tooltip:hover .tooltiptext,
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .doughnut__failed_calls .doughnut__div.tooltip:hover .tooltiptext {
            visibility: visible; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .action-row {
      display: flex;
      gap: 2em;
      align-items: baseline; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .chart__cont_2 .lead_label {
      appearance: auto;
      -webkit-appearance: auto;
      -moz-appearance: auto;
      outline: 0;
      padding: 0.5rem;
      border-radius: 9px;
      object-fit: contain; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont {
      display: flex;
      padding: 40px 0px; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card {
        box-shadow: 0px 0px 3px #1e1e1e76;
        border-radius: 5px;
        height: 60px;
        background-color: #EFEFEF;
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        margin: 0px 20px 0px 0px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card .stats__heading {
          align-self: center;
          margin-right: 30px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card .stats__heading img {
            height: 22px;
            margin-right: 4px; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card .stats__heading h4 {
            display: inline;
            color: #023349;
            font-weight: lighter;
            font-size: 16px;
            vertical-align: middle; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card .stats__numbers {
          align-self: center; }
          .wrapper-tring-dashboard .details_page__container .details_page__wrapper .stats__cont .stats__card .stats__numbers h4 {
            color: #17BCBC;
            font-size: 32px; }
    .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta {
      margin-top: 30px; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta .agent_dashboard_btn {
        background: #838DEA 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 5px #023349BE;
        border-radius: 6px;
        opacity: 1;
        padding: 15px 20px;
        max-width: fit-content;
        align-self: center;
        margin-right: 30px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta .agent_dashboard_btn img {
          height: 22px;
          margin-right: 4px; }
        .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta .agent_dashboard_btn h4 {
          display: inline;
          font-weight: lighter;
          color: white;
          font-size: 16px;
          vertical-align: middle; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta .download__btn {
        margin-right: 15px;
        padding: 12px 15px;
        font-size: 12px;
        text-transform: uppercase;
        transition: all 0.5s;
        border: none;
        background-color: #023349;
        color: #FFFFFF;
        font-weight: 900 !important;
        letter-spacing: 2px;
        border-radius: 6px;
        box-shadow: 0px 0px 5px #023349be; }
      .wrapper-tring-dashboard .details_page__container .details_page__wrapper .dashboard_bottom_cta .preview_script__btn {
        padding: 12px 15px;
        font-size: 12px;
        background-color: #17BCBC;
        text-transform: uppercase;
        transition: all 0.5s;
        border: none;
        color: #FFFFFF;
        font-weight: 900 !important;
        letter-spacing: 2px;
        border-radius: 6px;
        box-shadow: 0px 0px 5px #17BCBCbe; }
  .wrapper-tring-dashboard button:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
    .wrapper-tring-dashboard button:disabled:hover {
      background-color: #17BCBC !important; }

.faq-popup {
  /* Important part */ }
  .faq-popup .modal:before {
    opacity: 0.001 !important; }
  .faq-popup .modal-dialog {
    display: flex;
    justify-content: center; }
  .faq-popup .modal-content {
    margin-top: 0; }
  .faq-popup .modal-body {
    padding: 0 0 2%; }
    .faq-popup .modal-body .close-btn {
      display: none; }
  .faq-popup .modal-header {
    color: #023349;
    font-weight: bold;
    padding: 1% 4% 0;
    border-bottom: none; }
    .faq-popup .modal-header .close {
      font-size: 30px;
      transition: 0.25s ease; }
      .faq-popup .modal-header .close:hover {
        transform: rotate(180deg); }

.report-modal .modal-dialog {
  margin-top: 10%; }

.report-modal .modal-content {
  text-align: left;
  font-size: 18px; }

.report-modal .modal-header {
  margin: 0px 20px;
  border-bottom: none;
  padding: 25px 15px 0px 15px;
  color: #023349;
  font-weight: bold; }
  .report-modal .modal-header .close {
    font-size: 30px;
    transition: 0.25s ease; }
    .report-modal .modal-header .close:hover {
      transform: rotate(180deg); }

.report-modal .modal-body {
  margin: 0px 20px; }
  .report-modal .modal-body .close-btn {
    display: none; }
  .report-modal .modal-body .fm-report-download p {
    font-size: 16px;
    color: #636466;
    text-align: left; }
  .report-modal .modal-body .fm-report-download .frapp-btn {
    padding: 10px 20px; }
  .report-modal .modal-body .fm-report-download .download__btn {
    background-color: #023349;
    color: #FFFF;
    letter-spacing: 2px;
    font-weight: 900 !important;
    border-radius: 6px;
    box-shadow: 0px 0px 5px #023349be; }
  .report-modal .modal-body .fm-report-download .fm-custom-button {
    display: flex;
    justify-content: flex-end; }
    .report-modal .modal-body .fm-report-download .fm-custom-button p {
      font-size: 14px;
      margin: 0px;
      color: #636466;
      font-weight: lighter;
      cursor: pointer; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .heading {
    display: flex; }
    .report-modal .modal-body .fm-report-download .fm-custom-details .heading h4 {
      color: #1E1E1E;
      font-weight: 600; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .date-picker {
    display: flex; }
    .report-modal .modal-body .fm-report-download .fm-custom-details .date-picker p {
      font-size: 16px;
      margin: 5px 10px 1px 10px;
      font-weight: lighter;
      color: #636466; }
    .report-modal .modal-body .fm-report-download .fm-custom-details .date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #454647;
      font-size: 14px;
      color: #636466; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .basic-multi-select .select__menu .select__menu-list .select__option {
    font-size: 16px;
    text-align: left; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .css-1wa3eu0-placeholder {
    color: #888888;
    font-size: 15px; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .css-yk16xz-control {
    border-radius: 5px;
    box-shadow: 0px 0px 3px #888888; }
  .report-modal .modal-body .fm-report-download .fm-custom-details .css-2b097c-container {
    width: inherit; }
  .report-modal .modal-body .fm-report-download hr {
    margin: 12px 0 12px 0; }

.script_preview__modal {
  margin-top: 2%; }
  .script_preview__modal .modal-content {
    width: 38em;
    vertical-align: middle;
    margin-left: 0%; }

.refresh-icon {
  width: 15px; }
  .refresh-icon:hover {
    cursor: pointer; }

.api-enabled-stats-modal .modal-dialog {
  padding: 8% 8% 5% 8%; }
  .api-enabled-stats-modal .modal-dialog .modal-content {
    overflow: auto;
    max-height: 450px; }

.api-enabled-stats-modal .api-enabled-stats-modal-body {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 10px; }
  .api-enabled-stats-modal .api-enabled-stats-modal-body .heading {
    color: #023349;
    font-weight: 900;
    font-size: 18px; }
  .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between; }
    .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .green {
      background-color: #DFF5EB; }
    .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .red {
      background-color: #FFDBDB; }
    .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container {
      border-radius: 5px;
      padding: 5px 15px; }
      .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container .stats-item {
        display: flex;
        justify-content: space-between; }
        .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container .stats-item p {
          margin: 5px 0px;
          font-size: 16px;
          font-weight: 800; }
        .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container .stats-item .red-font {
          color: #DD5858; }
        .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container .stats-item .green-font {
          color: #11D17E; }
      .api-enabled-stats-modal .api-enabled-stats-modal-body .api-stats-wrapper .stats-container hr {
        margin: 10px 0px 10px 0px;
        border: 0;
        border-top: 1px solid #333; }

.stats-note {
  background-color: lightgrey;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 15px; }
  .stats-note p {
    margin: 0px; }

.m-100 {
  margin: 100px; }

.text-lg {
  font-size: large; }

.container {
  padding: 6% 3% 3% 3%; }
  .container .caller-phone {
    display: flex;
    align-items: center; }
    .container .caller-phone img {
      width: 24px;
      padding: 2%; }
  .container .autohold {
    margin-left: 18px; }
    .container .autohold img {
      width: 18px; }
  .container .mht {
    color: #13cece;
    font-weight: 900;
    min-height: 104px;
    background-color: #efefef;
    margin: 2%;
    min-width: 298px;
    margin-left: 68%;
    padding: 2%; }
    .container .mht .data {
      color: black;
      font-weight: 400; }
  .container .mht_caller_audit {
    color: #13cece;
    font-weight: 900;
    min-height: 104px;
    background-color: #efefef;
    min-width: 298px;
    padding: 2%; }
    .container .mht_caller_audit .data {
      color: black;
      font-weight: 400; }
  .container .audit-arrow-caller {
    padding: 5%;
    text-align: center;
    cursor: pointer; }
    .container .audit-arrow-caller:hover {
      background-color: #cecece; }
    .container .audit-arrow-caller img {
      width: 8px; }
  .container .audit-arrow {
    padding: 5%;
    text-align: center;
    cursor: pointer; }
    .container .audit-arrow:hover {
      background-color: #cecece; }
    .container .audit-arrow img {
      width: 44%; }
  .container ._header {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;
    width: 100%; }
  .container .brand_details {
    display: flex;
    align-items: center; }
    .container .brand_details .brand_logo {
      box-shadow: 0px 0px 3px #1e1e1ead;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-right: 1rem; }
    .container .brand_details .brand_name {
      color: #023349;
      letter-spacing: 0.2rem; }

.caller-iqc-modal .modal-dialog {
  padding: 0% 5% 0% 5%; }
  .caller-iqc-modal .modal-dialog .modal-content {
    overflow: auto;
    max-height: 700px;
    min-height: 500px; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .heading-wrapper {
      display: flex; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .heading-wrapper .heading {
        color: #023349;
        font-size: 16px;
        font-weight: 900; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .heading-wrapper .attemptIcon {
        background-color: cornflowerblue;
        border-radius: 20px;
        padding: 2px 10px 0px;
        margin: 0px 20px;
        color: white; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header {
      padding-top: 10px; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header .user-wrappper {
        display: grid;
        grid-template-columns: 50% 50%; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header .user-wrappper .audio-container p {
          font-weight: bold;
          margin: 5px 0px 0px 0px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header .user-wrappper .audio-container .disconnect-div {
          font-size: 13px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header .user-wrappper .audio-container audio {
          height: 40px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .audio-header .user-wrappper .user-infos .user-infos-div {
          background-color: whitesmoke;
          border: 1px dashed gray;
          border-radius: 5px;
          padding: 10px; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .loading-div {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .fade {
      visibility: hidden; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper {
      display: grid;
      grid-template-columns: 60% 40%; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container {
        border: 1px solid #EFEFEF; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container p {
          padding: 10px 10px 0px 10px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container h5 {
          padding-left: 10px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container table tbody tr:nth-of-type(odd) {
            background-color: #DFF5EB; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container table tbody tr {
            border-radius: 4px; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container table .td_text {
            width: 60%;
            padding-left: 10px; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .form-container table td fieldset div .MuiFormControlLabel-root {
            margin: 0px;
            padding-right: 10px; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .feedback-form {
        display: flex;
        flex-direction: column;
        padding: 5px;
        height: 250px;
        overflow-y: scroll; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .feedback-form input, .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .feedback-form p {
          margin-left: 7px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .feedback-form .MuiFormControlLabel-root {
          margin: 0px; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .iqc-form-wrapper .feedback-form .MuiFormControlLabel-root .MuiCheckbox-root {
            padding: 0px 5px !important; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .bottom-div {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .bottom-div .iqc-btn {
        background-color: #023349;
        color: white; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .bottom-div .iqc-btn:hover {
          color: white; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .bottom-div .page-nav {
        margin: 0px 10px;
        align-self: center;
        font-size: 14px;
        font-weight: bold;
        color: gray; }
    .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen {
      padding-top: 10px; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__div {
        border: 1.8px solid rgba(211, 211, 211, 0.507);
        border-radius: 5px;
        padding: 5px 20px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__div hr {
          margin: 5px 0px; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__row {
        display: flex;
        justify-content: space-between; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__row .Dropdown-placeholder {
          float: right; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__row .red {
          color: red; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__row .green {
          color: green; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .stats__container .stats__row .gray {
          color: #9e9e9e; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .feedback__div {
        margin: 20px 0px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 200px;
        overflow-x: auto; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .feedback__div .MuiFormControlLabel-root {
          margin: 0px;
          padding-right: 10px; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .feedback__div .MuiFormControlLabel-root .MuiCheckbox-root {
            padding: 5px !important; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .feedback__div .feedback__textarea {
          width: 100%;
          height: 70px !important; }
      .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .bottom-div {
        display: flex;
        justify-content: space-between;
        padding-top: 10px; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .bottom-div input {
          width: 60%; }
        .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .bottom-div .iqc-btn {
          background-color: #023349;
          color: white; }
          .caller-iqc-modal .modal-dialog .modal-content .caller-iqc .final__screen .bottom-div .iqc-btn:hover {
            color: white; }
    .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body h3 {
      text-align: left; }
    .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .note {
      padding: 10px;
      background-color: #d5d7f8;
      margin: 10px 0px; }
    .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table {
      max-height: 400px;
      overflow-y: auto; }
      .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5; }
      .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table::-webkit-scrollbar-thumb {
        background-color: #8e8b8b;
        border: 2px solid #b6b4b4;
        border-radius: 6px; }
      .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table .attempt-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 10px 20px; }
        .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table .attempt-row p {
          align-self: center;
          margin: 0px;
          color: #5e5b5b; }
        .caller-iqc-modal .modal-dialog .modal-content .iqc-attempt-body .iqc-attempt-container .attempts-table .attempt-row audio {
          height: 40px; }

.caller-iqc-modal.modal-scrollbox .modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  /* Scrollbar Styling */ }
  .caller-iqc-modal.modal-scrollbox .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .caller-iqc-modal.modal-scrollbox .modal-body::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .caller-iqc-modal.modal-scrollbox .modal-body::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4; }

.font-size-16 {
  font-size: 16px; }

.data-collection-collapseable {
  margin: 20px 0px; }
  .data-collection-collapseable .MuiAccordion-root {
    background-color: whitesmoke; }
  .data-collection-collapseable .MuiTypography-root {
    font-size: 16px;
    font-weight: 500; }
  .data-collection-collapseable .questionContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100px;
    max-height: 400px;
    overflow-x: auto;
    width: 100%; }
    .data-collection-collapseable .questionContainer .quesAnsDiv {
      margin-right: 20px; }
      .data-collection-collapseable .questionContainer .quesAnsDiv .question {
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 3px; }
      .data-collection-collapseable .questionContainer .quesAnsDiv .answer {
        margin: 0px; }
  .data-collection-collapseable .MuiAccordionDetails-root {
    background-color: white; }

#heading {
  font-size: 16px;
  font-weight: 600; }

.font-weight-light {
  font-weight: lighter !important; }

.customer-infos .customer-infos-div {
  background-color: whitesmoke;
  border: 1px dashed gray;
  border-radius: 5px;
  padding: 10px; }

.dashed-gray-line {
  border-top: 2px dashed gray; }

.disconnectedBy {
  font-weight: 600;
  font-size: 13px; }
  .disconnectedBy.caller {
    color: #DB5858; }
  .disconnectedBy.customer {
    color: #17BCBC; }
  .disconnectedBy.operator {
    color: #EE9310; }
  .disconnectedBy.exotel {
    color: #0166AB; }

.pay-modal-wrapper .form-group label {
  font-weight: 600;
  padding-bottom: 0px !important;
  display: flex;
  font-size: 11px;
  color: black;
  align-items: center; }

.brands-credits-header-btn {
  display: flex;
  justify-content: space-between; }
  .brands-credits-header-btn .btn-group {
    align-self: center; }

.add-new-credits {
  border: none;
  background-color: #13cece;
  font-size: 15px;
  color: white;
  font-weight: 600px;
  margin: 0px 12px; }

.download-credits-report {
  border: none;
  background-color: #023349;
  font-size: 15px;
  color: white;
  font-weight: 600px;
  margin: 0px 12px; }

.campaign-application-end-date-input {
  text-align: left !important;
  align-items: baseline; }
  .campaign-application-end-date-input .DateInput_input__disabled {
    font-style: normal !important; }

.date-picker-wrapper {
  display: flex;
  justify-content: space-around; }

.mock-call-stats-wrapper {
  background: none !important;
  display: flex;
  flex-flow: column;
  margin: 10.5rem 6.8rem 5rem; }
  @media (max-width: 600px) {
    .mock-call-stats-wrapper {
      margin: 80px 25px; } }
  .mock-call-stats-wrapper .stats-section .bar-graph {
    margin-bottom: 3em; }
    .mock-call-stats-wrapper .stats-section .bar-graph .apexcharts-legend-text {
      font-size: 14px !important;
      font-weight: 600 !important; }
    @media (min-width: 1200px) {
      .mock-call-stats-wrapper .stats-section .bar-graph {
        display: flex;
        justify-content: space-between; } }
    .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted {
      box-shadow: 0px 0px 3px #1e1e1e76;
      border-radius: 8px;
      width: 68%; }
      @media (max-width: 1200px) {
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted {
          width: 100%; } }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__header {
        padding: 15px 15px 0 15px; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__header img {
          height: 22px; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__header h4 {
          display: inline;
          color: #023349;
          font-size: 18px;
          vertical-align: middle;
          font-weight: 600 !important; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__header .chart__dropdown {
          float: right;
          display: flex;
          gap: 12px;
          align-items: center; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__header .chart__dropdown .dropdown-menu {
            min-width: 85px; }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div {
        padding: 15px; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div .apexcharts-legend-marker {
          border-radius: 50% !important; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div .load-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div .load-btn-wrapper .load-graph {
            padding: 9px;
            min-width: 150px;
            text-transform: uppercase;
            color: #FFFF;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.5); }
            .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div .load-btn-wrapper .load-graph:hover {
              cursor: pointer; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div.default {
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0.4; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div.tooltip {
          position: relative;
          border-bottom: 1px dotted black; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div.tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            /* Position the tooltip */
            position: absolute;
            z-index: 1; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock-call-conducted .chart__div.tooltip:hover .tooltiptext {
            visibility: visible; }
    .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper {
      width: 28%;
      flex-direction: column;
      gap: 2em;
      display: flex; }
      @media (max-width: 1200px) {
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper {
          width: 100%;
          margin: 2% 0 2% 0; } }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .flex {
        display: flex;
        justify-content: space-between; }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .h-60 {
        height: 60px; }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box {
        box-shadow: 0px 0px 3px #1e1e1e76;
        border-radius: 8px;
        padding: 5px 20px;
        display: flex; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box.highlighted {
          background-color: #E6F7FF; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .mt-8 {
          margin-top: 8px; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .mb-8 {
          margin-bottom: 8px; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__heading {
          width: 70%;
          align-self: center; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__heading img {
            height: 22px;
            margin-right: 4px; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__heading h4 {
            display: inline;
            color: #023349;
            font-weight: 600 !important;
            font-size: 16px;
            vertical-align: middle; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__numbers {
          width: 30%;
          text-align: right;
          align-self: center; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__numbers h4 {
            color: #023349;
            font-size: 32px;
            font-weight: 600 !important; }
          .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .stats__numbers p {
            color: #17BCBC;
            font-size: 14px;
            font-weight: 600 !important; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .stats__box .add__agents {
          align-self: center;
          cursor: pointer; }
      .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .winning_outcomes_wrapper {
        display: flex; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .winning_outcomes_wrapper div {
          width: 100%; }
        .mock-call-stats-wrapper .stats-section .bar-graph .mock_stats__wrapper .winning_outcomes_wrapper p {
          margin-bottom: 1%;
          padding: 0; }
  .mock-call-stats-wrapper .stats-section .coach_stats {
    width: 100%; }
    .mock-call-stats-wrapper .stats-section .coach_stats .MTableToolbar-root-5 {
      display: none; }
    .mock-call-stats-wrapper .stats-section .coach_stats .coach_stats_header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 1%; }
      .mock-call-stats-wrapper .stats-section .coach_stats .coach_stats_header h4 {
        color: #023349;
        font-size: 18px;
        vertical-align: middle;
        font-weight: 600 !important; }
      .mock-call-stats-wrapper .stats-section .coach_stats .coach_stats_header .dropdown-menu {
        min-width: 85px; }

.coach_stats_project {
  padding-top: 2%; }
  .coach_stats_project .MTableToolbar-root-5 {
    display: none; }

.mock-call-status-header {
  display: flex;
  gap: 1em; }
  .mock-call-status-header img {
    height: 16px; }
    .mock-call-status-header img:hover {
      cursor: pointer !important; }

.hiring-section {
  padding: 9% 2% 2% 2%; }
  .hiring-section .button-wrapper {
    display: flex;
    align-items: center;
    gap: 7px; }
    .hiring-section .button-wrapper .coach-stats-img:hover {
      cursor: pointer; }

.selected-items {
  border: 1px solid #ccc;
  padding: 0px 12px;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-height: 50px; }
  .selected-items:focus {
    outline: 0; }

.selected-items .selected-item-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05); }

.selected-items .selected-item-wrapper span:first-child {
  padding-right: 5px; }

.selected-items .selected-item-wrapper span:first-child:hover {
  color: #FFAB00; }

.selected-items .selected-item-wrapper span:nth-child(2) {
  padding-left: 5px; }

.selected-items .item {
  font-weight: bolder;
  display: inline-block;
  padding: 5px 20px; }

.trigger-btn {
  float: right;
  margin-top: -37px;
  margin-right: 10px; }

.item-list {
  border: 1px solid #ccc;
  position: absolute;
  background-color: white;
  margin-top: 0;
  box-shadow: none;
  width: 100%;
  border-radius: 5px;
  padding: 10px 20px;
  display: none;
  z-index: 1000;
  max-height: 250px;
  overflow-y: scroll;
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1); }

.item-list.shown {
  display: block; }

.item-list .item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: black; }

.item-list .category.item {
  background-color: rgba(0, 0, 0, 0.05); }

.category-switch-trigger {
  color: #4f84a4;
  cursor: pointer; }

.category-header {
  font-size: 16px; }
  .category-header hr {
    margin-top: 10px;
    margin-bottom: 10px; }
  .category-header span {
    float: right;
    cursor: pointer; }

.category-trail span {
  color: #4f84a4; }

.category-switch-trigger:hover {
  text-decoration: underline; }

.switch-wrapper {
  display: inline-block;
  height: 16px;
  /* Rounded sliders */ }
  .switch-wrapper .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px;
    margin-bottom: 0px !important; }
  .switch-wrapper .switch input {
    display: none; }
  .switch-wrapper .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5E6C84;
    -webkit-transition: .4s;
    transition: .4s; }
  .switch-wrapper .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s; }
  .switch-wrapper input:checked + .slider {
    background-color: #406AF8; }
  .switch-wrapper input:focus + .slider {
    box-shadow: 0 0 1px #406AF8; }
  .switch-wrapper input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px); }
  .switch-wrapper .slider.round {
    border-radius: 34px; }
  .switch-wrapper .slider.round:before {
    border-radius: 50%; }

.add-text {
  color: #13cece;
  float: right;
  cursor: pointer;
  font-size: 13px;
  padding: 2%; }

#option {
  color: #023349;
  font-size: 12px; }

.option-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

#quiz label {
  display: block;
  text-align: left;
  margin-bottom: 0px !important; }
  #quiz label input {
    height: auto !important; }

#next {
  background-color: #023349; }

.buttons-update {
  display: flex;
  justify-content: space-evenly; }

.update-quiz-wrapper {
  border-left: 16px solid #13cece;
  border-radius: 5px; }
  .update-quiz-wrapper .question-quiz {
    display: flex;
    align-items: center;
    width: 100%; }
    .update-quiz-wrapper .question-quiz .input-group-addon {
      border: none;
      vertical-align: auto; }

#option-formfield {
  width: 50%;
  border-left: 4px solid #023349;
  border-radius: 5px;
  margin-left: 2%; }

#qs-text {
  width: 50%; }
  #qs-text label {
    color: black;
    font-weight: 600;
    font-size: 19px; }

#options {
  max-height: 260px;
  overflow-y: scroll; }
  #options h5 {
    font-size: 14px;
    font-weight: 900 !important;
    width: 100%;
    padding: 2%;
    background-color: aliceblue; }
  #options .label {
    padding-bottom: 3%; }

#critical-check .checkbox label {
  display: flex;
  align-items: center;
  min-height: auto; }

.form-group label {
  font-weight: 600;
  padding-bottom: 3%;
  display: flex;
  font-size: 11px;
  color: black;
  align-items: center; }

.form-group .checkbox {
  align-items: center; }

#critical {
  width: 100%;
  align-items: center;
  height: 43px;
  display: flex; }
  #critical h5 {
    color: #e26464;
    font-weight: 600; }

#critical-1 {
  width: 100%;
  background-color: aliceblue;
  align-items: center;
  min-height: 48px;
  display: flex; }

#singlemulti {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 40%;
  padding-top: 5%;
  padding-left: 2%; }
  #singlemulti .radio {
    width: 100%; }
    #singlemulti .radio input[type=radio] {
      height: auto !important; }

#quiz-qs #quiz-delete {
  display: block;
  text-align: right;
  /* padding-right: 11rem; */
  margin-right: 71%;
  margin-bottom: 0px !important;
  font-size: 1.4rem; }
  #quiz-qs #quiz-delete img {
    width: 20px; }

#quiz-qs label {
  display: block;
  text-align: left;
  margin-bottom: 0px !important;
  font-size: 11px;
  margin-left: 1rem; }
  #quiz-qs label input {
    height: auto !important; }

.inputs-wrapper label {
  display: block;
  text-align: right;
  margin-bottom: 0px !important; }

.inputs-wrapper .input-wrapper {
  margin-bottom: 20px; }

.inputs-wrapper .input-group {
  border-radius: 4px !important;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.inputs-wrapper .form-control {
  box-shadow: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.inputs-wrapper .input-group-addon {
  background-color: #13cece;
  padding: 0px 0px;
  border: none; }
  .inputs-wrapper .input-group-addon .no-border-radius {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }

.inputs-wrapper button.add {
  color: red;
  background-color: transparent;
  font-weight: bolder;
  text-transform: capitalize;
  letter-spacing: 0px; }
  .inputs-wrapper button.add:hover {
    box-shadow: none; }
  .inputs-wrapper button.add:focus {
    background-color: transparent; }
  .inputs-wrapper button.add > span {
    display: flex;
    align-items: center; }

.inputs-wrapper .delete {
  cursor: pointer; }
  .inputs-wrapper .delete img {
    width: 20px; }

.inputs-wrapper .plus {
  font-size: 25px;
  font-weight: bolder;
  vertical-align: middle;
  margin-right: 10px;
  color: red; }

@keyframes wiggle {
  33% {
    transform: rotateZ(1deg); }
  to {
    transform: rotateZ(-1deg); } }

.wiggle {
  will-change: transform;
  animation-name: wiggle;
  animation-duration: 150ms;
  animation-iteration-count: 4; }

.loading {
  display: flex;
  justify-content: center; }
  .loading div {
    width: 2rem;
    height: 2rem;
    margin: 2rem 0.3rem;
    background: #979fd0;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate; }
    .loading div:nth-child(2) {
      animation-delay: 0.3s; }
    .loading div:nth-child(3) {
      animation-delay: 0.6s; }

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0); } }

.rc-time-picker-panel-combobox .rc-time-picker-panel-select ul li .rc-time-picker-panel-select-option-selected {
  background-color: #edeffe; }

.rc-time-picker-panel-combobox .rc-time-picker-panel-select::-webkit-scrollbar {
  width: 0;
  height: 0; }

.custom-modal .modal-sm {
  width: 500px; }

.custom-modal.modal-xs .modal-sm {
  width: 350px; }

.custom-modal .DateInput_input {
  font-size: 14px; }

.custom-modal .heading {
  font-size: 17px;
  color: #01293f;
  font-weight: 900;
  margin-bottom: 3%; }

.custom-modal .label {
  padding: 0;
  font-size: inherit; }

.custom-modal .form-group label {
  padding-bottom: 0; }

.custom-modal .rc-time-picker .rc-time-picker-panel {
  position: fixed !important; }

.custom-modal .rc-time-picker .rc-time-picker-panel-inner {
  position: fixed !important; }

.custom-modal .rc-time-picker-panel-inner {
  position: fixed !important; }

.reset_form {
  color: #13cece;
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px; }
  .reset_form:hover {
    cursor: pointer; }

.modal-btn-footer {
  display: flex;
  justify-content: flex-end; }

.error-result {
  color: #DD5858;
  font-weight: 600;
  padding: 2% 0; }

.success-result {
  color: #11d17e;
  font-weight: 600;
  padding: 2% 0; }

.success-banner {
  background-color: #11d17e;
  border-radius: 5px;
  text-align: center;
  padding: 2% 0;
  margin: 4% 0;
  color: #ffff;
  font-weight: 600; }

.failure-banner {
  color: #dd5858;
  padding: 2% 0;
  margin: 4% 0;
  font-weight: 600; }

.modal-large-600 .modal-lg {
  width: 600px; }

.modal-large-1480 .modal-dialog {
  height: 85%; }

.modal-large-1480 .modal-lg {
  width: 1450px; }

.modal-large-1480 .modal-content {
  height: 100%; }

.modal-large-1480 .modal-body {
  height: 100%;
  overflow-y: auto; }

.modal-large-1480 .notif-popup {
  height: 100%; }

.modal-large-1480 .tele-task-wrapper {
  height: 100%; }

.modal-large-1480 .frapp-modal {
  padding: 10px 0; }

.test-sms .message-preview {
  width: 100%;
  border: 1px solid #0000;
  background-color: #efefef;
  padding: 2%;
  border-radius: 5px;
  margin-bottom: 5%;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden; }
  .test-sms .message-preview::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; }
  .test-sms .message-preview::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5; }
  .test-sms .message-preview::-webkit-scrollbar-thumb {
    background-color: #8e8b8b;
    border: 2px solid #b6b4b4;
    border-radius: 6px; }

.auto-complete-input {
  outline: none;
  padding: 0 0.5rem;
  width: 100%;
  height: 40px;
  border-radius: 5px; }

.no-suggestions {
  color: #999;
  padding: 0.4rem;
  font-size: 12px;
  position: absolute; }

.suggestions {
  border: 1px solid #17bcbc;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 300px;
  position: absolute;
  background-color: #FFFF; }

.suggestions::-webkit-scrollbar {
  width: 0.2rem;
  height: 0rem;
  border-radius: 20px; }

.suggestions::-webkit-scrollbar-thumb {
  background-color: #17bcbc; }

.suggestions li {
  padding: 0.5rem;
  font-size: 12px;
  font-weight: 500; }

.suggestion-active,
.suggestions li:hover {
  background-color: #17bcbc;
  color: #fff;
  cursor: pointer;
  font-weight: 700; }

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999; }

.auto-complete-menu {
  background-color: #f3f3f3;
  position: absolute;
  width: 55%;
  max-height: 120px;
  overflow-y: auto;
  z-index: 10000; }

.menu-item {
  cursor: default;
  padding: 0.3rem; }

.menu-item.selected {
  background-color: #17bcbc;
  color: #FFFFFF; }

.menu-item:hover:not(.selected) {
  background-color: #fafafa; }

.chat-window {
  height: 80vh;
  max-width: 450px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 8px 19px -2px black; }
  .chat-window h3 {
    font-size: 16px;
    margin: 0; }
  .chat-window .messages {
    overflow-y: auto;
    flex: 1 1;
    min-height: 100%; }
    .chat-window .messages .input-box {
      left: 0;
      width: 100%; }
      .chat-window .messages .input-box .enter {
        width: 46px;
        height: 44px;
        height: 46px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        background-color: #023349;
        /* background-color: blue; */
        /* padding-left: 10px; */
        -webkit-box-shadow: 0 0 4px 0 #a5adba !important; }
        .chat-window .messages .input-box .enter .img-enter {
          color: white;
          font-size: 24px;
          margin-left: -28px;
          margin-top: 2px; }
        .chat-window .messages .input-box .enter img {
          width: 29px; }
        .chat-window .messages .input-box .enter button {
          font-weight: 100 !important; }
        .chat-window .messages .input-box .enter button:disabled {
          opacity: 0.3;
          cursor: not-allowed; }
          .chat-window .messages .input-box .enter button:disabled:hover {
            background-color: transparent !important;
            border: none; }
        .chat-window .messages .input-box .enter button {
          background-color: transparent;
          border: none; }
        .chat-window .messages .input-box .enter .btn-default {
          color: none !important; }
      .chat-window .messages .input-box .chat-enter {
        display: flex;
        padding: 14px;
        align-items: center;
        padding-top: 0px;
        position: absolute;
        bottom: 6.5rem;
        width: 450px;
        background-color: #FFFF; }
      .chat-window .messages .input-box #submit {
        height: 100%; }
      .chat-window .messages .input-box textarea {
        width: 100%;
        border-radius: 35px !important;
        padding: 15px;
        max-height: 50px; }

.chat-window-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem; }
  .chat-window-wrapper .chat-wrapper {
    display: flex;
    flex-flow: column;
    padding: 20px;
    padding-top: 5%;
    padding-bottom: 17%; }
    .chat-window-wrapper .chat-wrapper .banner-wrap {
      color: #888080;
      width: 303px;
      display: flex;
      flex-flow: column;
      align-items: center;
      font-size: 10px; }
    .chat-window-wrapper .chat-wrapper .show-more {
      position: relative;
      top: 0px;
      left: 34%;
      border: 2px solid;
      background-color: #2d51b6;
      color: white;
      border-radius: 40px;
      height: 46px;
      width: 28%; }
    .chat-window-wrapper .chat-wrapper .support-banner {
      display: flex;
      flex-flow: column;
      align-items: center;
      border-bottom: 1px solid #888080;
      margin-bottom: 14%; }
    .chat-window-wrapper .chat-wrapper .detail-right {
      float: right;
      padding: 15px; }
    .chat-window-wrapper .chat-wrapper .detail-left {
      max-width: fit-content; }
    .chat-window-wrapper .chat-wrapper .details {
      display: flex;
      align-items: center; }
      .chat-window-wrapper .chat-wrapper .details img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 8px; }
    .chat-window-wrapper .chat-wrapper .time {
      color: grey;
      padding-left: 9px;
      font-size: 11px;
      margin-right: 11px;
      float: right;
      padding-top: 8px; }
    @media (max-width: 769px) and (min-width: 320px) {
      .chat-window-wrapper .chat-wrapper .wrap-chat {
        margin-right: -13%; } }
    .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox {
      border: 1px solid #c8c2c25c;
      padding: 3px;
      width: 35%;
      min-height: fit-content;
      min-width: fit-content;
      border-radius: 10px;
      word-break: break-all;
      background-color: #eeeeeead;
      box-shadow: 1px 2px 2px 0px #cfcdcf05; }
      @media (min-width: 320px) and (max-width: 769px) {
        .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox {
          min-width: 90%;
          word-break: break-all; } }
      .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox .msg {
        padding: 6px;
        padding-left: 10px;
        padding-right: 10px; }
    .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox-grey {
      border: 1px solid #c8c2c25c;
      padding: 3px;
      width: 35%;
      min-height: fit-content;
      min-width: fit-content;
      border-radius: 10px;
      word-break: break-all;
      background-color: #eeeeeead;
      box-shadow: 1px 2px 2px 0px #cfcdcf05; }
      @media (min-width: 320px) and (max-width: 769px) {
        .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox-grey {
          min-width: 90%;
          word-break: break-all; } }
      .chat-window-wrapper .chat-wrapper .wrap-chat .chatbox-grey .msg {
        padding: 6px;
        padding-left: 10px;
        padding-right: 10px; }

:root {
  --color-white: #FFFFFF;
  --color-eerie-black: #1E1E1E;
  --color-topaz: #17BCBC;
  --color-prussion-blue: #023349;
  --color-dark-grey: #454647;
  --color-grey-80: #CCCCCC;
  --color-green: #11D17E;
  --color-dark-green: #0BA451;
  --color-red: #DD5858;
  --color-lavendar-blue: #838DEA;
  --color-sky-blue: #DCFDFF;
  --color-voilet: #EFE3FF;
  --color-peach: #FFEBD8;
  --color-aqua-green: #BEFFE3;
  --color-yellow: #FFF8E0;
  --color-american-orange: #FF8A00;
  --color-royal-blue: #5160E5; }

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf");
  font-weight: 900; }

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/Nunito_Sans/NunitoSans-Light.ttf");
  font-weight: 100; }

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/Nunito_Sans/NunitoSans-Bold.ttf");
  font-weight: 600; }

@font-face {
  font-family: "cs";
  src: url("../fonts/Nunito_Sans/NunitoSans-Regular.ttf");
  font-weight: 300; }

body {
  font-family: "cs";
  font-display: 'Sans-Serif';
  font-weight: 100;
  -webkit-font-smoothing: antialiased; }

html, body, #root, #app {
  height: 100%;
  width: 100%; }

textarea {
  resize: none; }

table {
  background-color: white;
  padding: 15px;
  border-radius: 8px; }

a {
  color: #406AF8; }
  a:hover {
    color: #406AF8; }

button {
  font-weight: 100 !important; }

h1 {
  font-size: 36px; }
  @media (max-width: 768px) {
    h1 {
      font-size: 24px; } }

@media (max-width: 768px) {
  h2 {
    font-size: 20px; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300 !important;
  margin-bottom: 10px; }

.black-colour {
  color: black; }

.capitalize {
  text-transform: capitalize;
  margin-top: 6%; }

.m-r-10 {
  margin-right: 10px; }

.m-t-10 {
  margin-top: 10px; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.tips-wrapper {
  background-color: #11d17e;
  color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 30px; }
  .tips-wrapper h4 {
    margin-top: 0; }

.clickable {
  cursor: pointer; }

.long-dash {
  display: inline;
  margin: 0 10px; }
  .long-dash span {
    margin-left: -2px; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 300ms; }

.flexible-list {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .flexible-list::before, .flexible-list::after {
      display: none; } }
  @media (max-width: 768px) {
    .flexible-list {
      margin: 0; }
      .flexible-list > .col-xs-12 {
        padding: 0; } }

date-input-polyfill {
  z-index: 1070; }

.bg-yellow {
  background-color: yellow; }

.json-view .item-wrapper {
  margin-bottom: 30px; }
  .json-view .item-wrapper .field {
    font-size: 15px;
    color: #080f1d;
    font-weight: bold; }
  .json-view .item-wrapper .value {
    word-break: break-word;
    max-width: 90%;
    font-size: 16px;
    color: #0b0986; }
    .json-view .item-wrapper .value img {
      width: 100px;
      border-radius: 100%; }

.login {
  padding: 50px;
  background-color: #023349;
  min-height: 100vh; }
  .login .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .login .container > img:first-child {
      width: 700px; }
    .login .container img.logo {
      width: 300px; }
    .login .container .forgot-password {
      float: inline-end;
      font-size: 18px;
      color: #DD5858; }
      .login .container .forgot-password:hover {
        text-decoration: none; }
    .login .container .errMsg {
      color: #FFFF;
      font-weight: 600; }
  .login input {
    display: block;
    margin: 10px;
    margin-bottom: 0;
    padding: 5px; }
  .login form {
    margin: 50px auto;
    width: 20%; }
    .login form input {
      margin-left: 0;
      margin-right: 0;
      width: 100%; }
    .login form button {
      width: 100%;
      background-color: #36B37E;
      border: none;
      color: white;
      margin-top: 10px; }
      .login form button:hover {
        background-color: #36B37E;
        color: white; }

.forgot-password,
.change-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10% 0% 0% 30%; }
  .forgot-password > img:first-child,
  .change-password > img:first-child {
    width: 700px;
    margin: 0% 5% 5% 0%; }
  .forgot-password img.logo,
  .change-password img.logo {
    width: 300px; }
  .forgot-password h3,
  .change-password h3 {
    font-size: 20px;
    color: #898989; }
  .forgot-password form,
  .change-password form {
    margin-top: 60px; }
  .forgot-password form .form-group label,
  .change-password form .form-group label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px; }
  .forgot-password input,
  .change-password input {
    width: 200%; }
  .forgot-password .valid,
  .change-password .valid {
    color: green; }
  .forgot-password .invalid,
  .change-password .invalid {
    color: red; }
  .forgot-password .cta-wrapper,
  .change-password .cta-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start; }
    .forgot-password .cta-wrapper a,
    .change-password .cta-wrapper a {
      font-size: 18px;
      font-weight: bolder;
      color: #11d17e;
      margin-left: 20px; }

/* SLIDER CSS */
.slider-wrapper {
  text-align: center;
  margin-top: 20px; }
  .slider-wrapper .slider {
    display: inline-block !important;
    -webkit-appearance: none;
    width: 60% !important;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 5px;
    height: 10px;
    border-radius: 5px; }
  .slider-wrapper .slider:hover {
    opacity: 1; }
  .slider-wrapper .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* appearance: none; */
    width: 20px;
    height: 20px;
    background: #FFAB00;
    cursor: pointer;
    border-radius: 10px; }
  .slider-wrapper .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #FFAB00;
    cursor: pointer; }

.welcome-page {
  padding-top: 12%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center; }
  .welcome-page .welcome-message {
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    color: #555555; }
